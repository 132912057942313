import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { Controller, useForm, useWatch } from 'react-hook-form';

const CreditCardAuthorizeForm = ({ onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const cardNumberWatcher = useWatch({
    control,
    name: 'cardNumber',
  });

  const brands = ['visa', 'mastercard', 'americanexpress'];
  const [brand, setBrand] = useState('');

  useEffect(() => {
    handleCardNumberChange(cardNumberWatcher);
  }, [cardNumberWatcher]);

  const handleCardNumberChange = (val) => {
    if (val) {
      const b = parseInt(val?.substring(0, 2));

      if (b === 3 || (b >= 30 && b <= 39)) {
        return setBrand(brands[2]);
      }

      if (b === 4 || (b >= 40 && b <= 49)) {
        return setBrand(brands[0]);
      }

      if (b >= 51 && b <= 55) {
        return setBrand(brands[1]);
      }
    }
    return setBrand('');
  };

  return (
    <>
      <Form
        noValidate
        id="authorize-credit-card-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="credit-card-form">
          <Row>
            <Col xs={12}>
              {brands.length > 0 &&
                brands.map((e, k) => (
                  <Image
                    key={`BrandImage_${k}`}
                    className={`payment-item-image ${
                      brand === e ? 'active' : ''
                    }`}
                    src={`assets/icons/${e}.svg`}
                  />
                ))}
            </Col>
          </Row>
        </div>
        <Row>
          {/* Card Number */}
          <Col xs={12}>
            <Controller
              name="cardNumber"
              control={control}
              //defaultValue="4242424242424242"
              rules={{ required: true, minLength: 13, maxLength: 16 }}
              render={({ field }) => (
                <div className="select-element">
                  <Form.Group controlId="cardNumber">
                    <Form.Label>Card Number</Form.Label>
                    <Form.Control
                      {...field}
                      minLength={13}
                      maxLength={16}
                      isInvalid={!!errors.cardNumber}
                      placeholder="1234 1234 1234 1234"
                      //onChange={(val) => handleCardNumberChange(val)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {!!errors.cardNumber &&
                        errors.cardNumber.type === 'required' &&
                        'Card Number is required'}
                      {!!errors.cardNumber &&
                        errors.cardNumber.type === 'minLength' &&
                        'Card Number is invalid'}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              )}
            />
          </Col>

          {/* Exp Month */}
          <Col xs={4}>
            <Controller
              name="expMonth"
              control={control}
              //defaultValue="12"
              rules={{ required: true, maxLength: 2 }}
              render={({ field }) => (
                <div className="select-element">
                  <Form.Group controlId="expMonth">
                    <Form.Label>Expiration Month</Form.Label>
                    <Form.Control
                      {...field}
                      maxLength={2}
                      isInvalid={!!errors.expMonth}
                      placeholder="MM"
                    />
                    <Form.Control.Feedback type="invalid">
                      {!!errors.expMonth &&
                        errors.expMonth.type === 'required' &&
                        'Expiration Month is required'}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              )}
            />
          </Col>

          {/* Exp Year */}
          <Col xs={4}>
            <Controller
              name="expYear"
              control={control}
              //defaultValue="22"
              rules={{ required: true, maxLength: 2 }}
              render={({ field }) => (
                <div className="select-element">
                  <Form.Group controlId="expYear">
                    <Form.Label>Expiration Year</Form.Label>
                    <Form.Control
                      {...field}
                      maxLength={2}
                      isInvalid={!!errors.expYear}
                      placeholder="YY"
                    />
                    <Form.Control.Feedback type="invalid">
                      {!!errors.expYear &&
                        errors.expYear.type === 'required' &&
                        'Expiration Year is required'}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              )}
            />
          </Col>

          {/* Card Code */}
          <Col xs={4}>
            <Controller
              name="cardCode"
              control={control}
              //defaultValue="123"
              rules={{ required: true, minLength: 3, maxLength: 4 }}
              render={({ field }) => (
                <div className="select-element">
                  <Form.Group controlId="cardCode">
                    <Form.Label>Card Code (CVC)</Form.Label>
                    <Form.Control
                      {...field}
                      minLength={3}
                      maxLength={4}
                      isInvalid={!!errors.cardCode}
                      placeholder="CVC"
                    />
                    <Form.Control.Feedback type="invalid">
                      {!!errors.cardCode &&
                        errors.cardCode.type === 'required' &&
                        'Card Code is required'}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              )}
            />
          </Col>

          {/* Full Name */}
          <Col xs={12}>
            <Controller
              name="fullName"
              control={control}
              rules={{ required: true, minLength: 1, maxLength: 100 }}
              render={({ field }) => (
                <div className="select-element">
                  <Form.Group controlId="fullName">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      {...field}
                      minLength={1}
                      maxLength={100}
                      isInvalid={!!errors.fullName}
                      placeholder="John Doe"
                    />
                    <Form.Control.Feedback type="invalid">
                      {!!errors.fullName &&
                        errors.fullName.type === 'required' &&
                        'Full Name is required'}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              )}
            />
          </Col>

          {/* Hidden */}
          <input type="hidden" name="dataValue" id="dataValue" />
          <input type="hidden" name="dataDescriptor" id="dataDescriptor" />
        </Row>
      </Form>
    </>
  );
};

CreditCardAuthorizeForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default CreditCardAuthorizeForm;
