import { AUTHORIZE } from '../config';

const sendPayment = (data, success, error) => {
  const { cardNumber, expMonth, expYear, cardCode, fullName } = data;

  const authData = {
    clientKey: AUTHORIZE.PUBLIC_KEY,
    apiLoginID: AUTHORIZE.API_LOGIN_KEY,
  };

  var cardData = {
    cardNumber,
    month: expMonth,
    year: expYear,
    cardCode,
    fullName,
  };

  var secureData = {
    authData,
    cardData,
  };

  window.Accept.dispatchData(secureData, (res) => {
    if (res?.messages?.resultCode === 'Ok') {
      return success(res, secureData);
    }

    if (res?.messages?.resultCode === 'Error') {
      // return error(res?.messages?.message);
      return error('error');
    }
  });
};

export default {
  sendPayment,
};
