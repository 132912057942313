/* eslint-disable no-undef */
export const API_URL =
  process?.env?.REACT_APP_API_URL || 'http://localhost:3001';

export const ENVIRONMENT = process?.env?.REACT_APP_ENV || 'development';

export const STRIPE_KEY = process?.env?.REACT_APP_STRIPE_KEY || 'ERROR';

export const AUTHORIZE = {
  PUBLIC_KEY: process?.env?.REACT_APP_AUTHORIZE_PUBLIC_KEY,
  API_LOGIN_KEY: process?.env?.REACT_APP_AUTHORIZE_API_LOGIN_KEY,
};

export const PAYMENT_GATEWAY = (
  process?.env?.REACT_APP_PAYMENT_GATEWAY || 'AUTHORIZE'
).toUpperCase();
