import { apiCall } from './base';

export const getAll = async (params) => {
  const result = await apiCall(`/category`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
    params,
  });
  return result.data;
};

export const getOne = async (code) => {
  const result = await apiCall(`/category/${code}`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
  });
  return result.data;
};

export const create = async (data) => {
  const result = await apiCall(`/category`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
    data,
  });
  return result;
};

export const update = async (data, code) => {
  const result = await apiCall(`/category/${code}`, {
    method: 'PUT',
    withCredentials: true,
    immediately: true,
    data,
  });
  return result;
};
