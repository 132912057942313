import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

const CategoryFilter = (props) => {
  const {
    list,
    selected,
    onSelected,
    showAll,
    showAllText = 'All Items',
  } = props;

  return (
    <>
      <div className="category-filter">
        <div className="scrolling-wrapper row flex-row flex-nowrap">
          <ToggleButtonGroup
            type="radio"
            name="options"
            value={selected}
            onChange={(val) => onSelected(val)}
          >
            {/* All Items */}
            {showAll && (
              <ToggleButton
                value={'all'}
                className="btn-round btn-tags"
                variant="outline-secondary"
              >
                {/* All Items */}
                {showAllText}
              </ToggleButton>
            )}

            {/* Rest of the Values */}
            {list.length > 0 &&
              list.map((e, k) => (
                <ToggleButton
                  key={k}
                  value={e.code}
                  className="btn-round btn-tags"
                  variant="outline-secondary"
                >
                  {e.name}
                </ToggleButton>
              ))}
          </ToggleButtonGroup>
        </div>
      </div>
    </>
  );
};

CategoryFilter.propTypes = {
  list: PropTypes.array,
  selected: PropTypes.string,
  onSelected: PropTypes.func,
  showAll: PropTypes.bool,
  showAllText: PropTypes.string,
};

CategoryFilter.propDefault = {
  showAll: false,
};

export default CategoryFilter;
