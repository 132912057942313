import { apiCall } from './base';

export const getAll = async (params) => {
  const result = await apiCall(`/ingredient`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
    params,
  });
  return result.data;
};
