import { apiCall } from './base';

export const getAll = async (params) => {
  const result = await apiCall(`/product`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
    params,
  });
  return result.data;
};

export const getAllLanding = async (params) => {
  const result = await apiCall(`/product/landing`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
    params,
  });
  return result.data;
};

export const getTopPicks = async (params) => {
  const result = await apiCall(`/product/top-picks`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
    params,
  });
  return result.data;
};

export const getOne = async (hash) => {
  const result = await apiCall(`/product/${hash}`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
  });
  return result;
};

export const create = async (data) => {
  const result = await apiCall(`/product`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
    data,
  });
  return result;
};

export const setImage = async (hash, data) => {
  const result = await apiCall(`/product/${hash}/image`, {
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    withCredentials: true,
    immediately: true,
    data,
  });
  return result;
};

export const update = async (data, hash) => {
  const result = await apiCall(`/product/${hash}`, {
    method: 'PUT',
    withCredentials: true,
    immediately: true,
    data,
  });
  return result;
};

export const remove = async (hash) => {
  const result = await apiCall(`/product/${hash}`, {
    method: 'DELETE',
    withCredentials: true,
    immediately: true,
  });
  return result;
};
