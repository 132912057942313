import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardDeck } from 'react-bootstrap';
import { exportToTop } from '../../helpers/utils';
// import ProductCardSkeleton from '../../components/productCardSkeleton';
import ProductCard from '../../components/productCard';
import ProductCardDetail from '../../components/productCardDetail';
import { useCart } from '../../hooks';

const OurFavoritesLanding = ({ list }) => {
  const { productModal, hideProductModal, productDetail } = useCart();

  useEffect(() => {
    exportToTop();
  }, []);

  return (
    <>
      {Array.isArray(list) && list?.length > 0 && (
        <div className="our-favorites-landing">
          <div className="our-favorites-landing-container">
            <h3>OUR FAVORITES</h3>
            <h2>Top picks from our menus</h2>

            <div className={`page-container menu-page-container`}>
              {/* Product Detail Modal */}
              {productDetail?.product && productDetail?.quantity && (
                <ProductCardDetail
                  key={productDetail.product.hash}
                  show={productModal}
                  product={productDetail.product}
                  quantity={productDetail.quantity}
                  onHide={hideProductModal}
                />
              )}

              <div className="menu">
                <div className="card-filter-bar">
                  <CardDeck>
                    {list.map((e, k) => (
                      <ProductCard
                        product={{ ...e }}
                        key={k}
                        showFavorite={false}
                        isGridView={true}
                      />
                    ))}
                  </CardDeck>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

OurFavoritesLanding.propTypes = {
  list: PropTypes.array,
};

export default OurFavoritesLanding;
