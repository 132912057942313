import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Skeleton from 'react-loading-skeleton';

const ProductCardSkeleton = () => {
  return (
    <>
      <Card className="product-card skeleton">
        <div className="skeleton-image">
          <Skeleton />
        </div>

        <Card.Body>
          <Card.Title>
            <Row className="no-gutters">
              <Col xs={8} md={8}>
                <Skeleton height={22} />
              </Col>
            </Row>
          </Card.Title>

          <Card.Text>
            <Skeleton count={2} />
          </Card.Text>
        </Card.Body>

        <Card.Footer className="text-muted">
          <Container fluid>
            <Row className="no-gutters">
              <Col xs={12}>
                <Skeleton height={30} />
              </Col>
            </Row>
          </Container>
        </Card.Footer>
      </Card>
    </>
  );
};

export default ProductCardSkeleton;
