import React, { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  useUser,
  useToggle,
  useCart,
  // useNotification
} from '../../hooks';

const SignInPage = () => {
  const { isUserLoggedIn, role, login } = useUser();
  const { addItem } = useCart();
  const { state } = useLocation();
  const history = useHistory();
  // const { error } = useNotification();

  const [showPassword, setShowPassword] = useToggle(true);
  const [loading, setLoading] = useState();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    await login(data);

    // if (!result.success) {
    // return error({ message: `${result.error.message}` });
    // }

    setLoading(false);
  };

  useEffect(() => {
    if (isUserLoggedIn && role) {
      const defaultPage = {
        admin: 'admin/order',
        customer: 'menu',
        kitchen: 'admin/order',
        delivery: 'delivery',
      };

      // user is not logged in and has been redirected from landing's "add to cart"
      // so we are asking the user to log in and if login is successfull, will add item to cart
      if (state) {
        const { product, selectedQuantity, selectedIngredients } = state;
        const hideToast = true;

        if (product && selectedQuantity && selectedIngredients) {
          addItem({
            product,
            selectedQuantity,
            selectedIngredients,
            hideToast,
          });
        }
      }

      history.push(
        state && state.from ? state.from : `/${defaultPage[role.code]}`
      );
    }
  }, [isUserLoggedIn, role, history, state]);

  return (
    <>
      <div className="login">
        <div className="login-container">
          <div className="header-login">
            <Link to="/">
              <Image src="assets/images/logo.svg" />
            </Link>
            <p className="logo-claim">Travel happy, not hungry</p>
          </div>

          {/* Sign Up */}
          <div className="form">
            <Form noValidate id="signin-form" onSubmit={handleSubmit(onSubmit)}>
              {/* Email */}
              <Controller
                name="email"
                control={control}
                defaultValue={''}
                rules={{
                  required: true,
                  pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        {...field}
                        isInvalid={!!errors.email}
                        type="email"
                        placeholder="Enter your Email"
                      />
                      <Form.Control.Feedback type="invalid">
                        {!!errors.email &&
                          errors.email.type === 'required' &&
                          'Email is required'}
                        {!!errors.email &&
                          errors.email.type === 'pattern' &&
                          'Email is invalid'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />

              {/* Password */}
              <Controller
                name="password"
                control={control}
                defaultValue={''}
                rules={{ required: true, minLength: 4, maxLength: 20 }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        {...field}
                        isInvalid={!!errors.password}
                        type={showPassword ? 'password' : 'text'}
                        placeholder="Enter your Password"
                      />
                      <Link
                        className="btn btn-link btn-icon password-view"
                        to="#"
                        replace
                        onClick={() => setShowPassword()}
                      >
                        {showPassword ? (
                          <i className="fas fa-eye-slash"></i>
                        ) : (
                          <i className="fas fa-eye"></i>
                        )}
                      </Link>
                      <Form.Control.Feedback type="invalid">
                        {errors?.password?.type === 'required' &&
                          'Password is required'}
                        {errors?.password?.type === 'minLength' &&
                          'Password should have more than 4 characters'}
                        {errors?.password?.type === 'maxLength' &&
                          'Password should have less than 20 characters'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />

              {!loading && (
                <Button variant="primary" type="submit">
                  Sign in
                </Button>
              )}

              {loading && (
                <Button variant="primary" type="submit" disabled={true}>
                  <i className="fas fa-circle-notch fa-spin"></i>
                </Button>
              )}
            </Form>
          </div>

          <div className="user-links d-flex justify-content-between">
            <Link
              className="btn btn-link btn-icon primary-color"
              to="/forgot-password"
            >
              Forgot password?
            </Link>
            <Link className="btn btn-link btn-icon primary-color" to="/signup">
              Sign up
            </Link>
          </div>
        </div>

        <div className="clouds">
          <Image src="assets/images/clouds.svg" className="img-fluid" />
        </div>
      </div>
    </>
  );
};

export default SignInPage;
