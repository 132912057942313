import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import ReactHtmlParser from 'react-html-parser';
import { useToggle } from '../../hooks';

const FaqList = () => {
  const faqs = [
    {
      question: 'When do I pay for my order?',
      answer: 'You won’t be charged for your order until the day of delivery.',
    },
    {
      question: 'What if I need to cancel my order?',
      answer:
        "You may cancel your order up to 24 hours before the scheduled delivery time. If you must cancel on shorter notice, please contact us at <a href='mailto:customerservice@theflightkitchen.com'>customerservice@theflightkitchen.com</a>.",
    },
    {
      question: 'What if there is something wrong or missing from my order?',
      answer:
        'You will receive a text or email when your order is delivered with contact information to let us know if your order is incorrect. We will do our best to make it right.',
    },
    {
      question: 'How do I contact customer service?',
      answer:
        "For general questions you can contact us at anytime by email at: <a href='mailto:customerservice@theflightkitchen.com'>customerservice@theflightkitchen.com</a>.",
    },
    {
      question:
        'When will The Flight Kitchen be available at my favorite airport?',
      answer:
        "We are always looking for opportunities to expand and would love to hear suggestions. Send us an email at <a href='mailto:customerservice@theflightkitchen.com'>customerservice@theflightkitchen.com</a>.",
    },
    {
      question: 'How do I reset my password?',
      answer:
        "No problem. On the login page, click the link <a href='/forgot-password'>Forgot Password</a> and we’ll send an email to the address on file. Follow the instructions to reset your password.",
    },
    {
      question: 'How do you keep my information safe?',
      answer:
        "Security of your personal information is a priority. Our efforts to ensure that your personal and payment information remain secure are outlined in our <a href='/privacy-policy'>Privacy Policy</a>.",
    },
  ];

  return (
    <>
      <div className="faq-list">
        <Accordion>
          {faqs.length &&
            faqs.map((faq, k) => (
              <Card key={`FAQ_${k}`} className={`faq-item`}>
                <Card.Header>
                  <CustomToggle eventKey={`${k}`}>{faq.question}</CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={`${k}`}>
                  <Card.Body>{ReactHtmlParser(faq.answer)}</Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
        </Accordion>
      </div>
    </>
  );
};

// eslint-disable-next-line react/prop-types
const CustomToggle = ({ children, eventKey }) => {
  const [show, setShow] = useToggle();

  const decoratedOnClick = useAccordionToggle(eventKey, () => setShow());

  return (
    <button
      type="button"
      className={`faq-item btn btn-link w-100 text-left`}
      onClick={decoratedOnClick}
    >
      {/* Question */}
      <span className={`question`}>{children}</span>

      {/* Icon +/- */}
      <span className={`icon d-inline-block float-right`}>
        <i className={`fas fa-minus ${show ? '' : 'd-none'}`}></i>
        <i className={`fas fa-plus ${show ? 'd-none' : ''}`}></i>
      </span>
    </button>
  );
};

export default FaqList;
