import { apiCall } from './base';

export const signup = async (data) => {
  const result = await apiCall(`/auth/sign-up`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
    data,
  });
  return result;
};

export const signin = async (data) => {
  const result = await apiCall(`/auth/sign-in`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
    data,
  });
  return result;
};

export const forgotPassword = async (data) => {
  const result = await apiCall(`/auth/forgot-password`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
    data,
  });
  return result;
};

export const resetPasswordValidateToken = async (data) => {
  const result = await apiCall(`/auth/reset-password/validate-token`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
    data,
  });
  return result;
};

export const resetPassword = async (data) => {
  const result = await apiCall(`/auth/reset-password`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
    data,
  });
  return result;
};
