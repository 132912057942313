/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import Header from '../../../components/header';
import SideMenu from '../../../components/sideMenu';
import Footer from '../../../components/footer';
import { useUser } from '../../../hooks';
import { Container } from 'react-bootstrap';

const PrivateRoute = ({ component: Component, roleAllowed, ...rest }) => {
  const { isUserLoggedIn, role } = useUser();
  const { pathname } = useLocation();

  const showFooter = () => {
    switch (pathname) {
      case '/checkout':
      case '/order-placed':
        return 'main no-footer';
      default:
        return 'main';
    }
  };

  const isAllowed =
    isUserLoggedIn && roleAllowed.includes(role?.code.toUpperCase());

  return (
    <Route
      {...rest}
      render={(props) =>
        isAllowed ? (
          <>
            <Header />
            <SideMenu />
            <Container fluid className={showFooter()}>
              <Component {...props} />
            </Container>
            <Footer />
          </>
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );
};

export default PrivateRoute;
