import React from 'react';
import Card from 'react-bootstrap/Card';
import Skeleton from 'react-loading-skeleton';
import AdminOrderItemColSkeleton from '../adminOrderItemColSkeleton';

const AdminOrderItemSkeleton = () => {
  return (
    <>
      <Card className="admin-order-item skeleton">
        <Card.Body>
          <div className="d-flex align-items-center">
            <AdminOrderItemColSkeleton />
            <AdminOrderItemColSkeleton />
            <AdminOrderItemColSkeleton />
            <AdminOrderItemColSkeleton />

            <div className="order-item-container ml-auto">
              <Skeleton width={190} height={50} />
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default AdminOrderItemSkeleton;
