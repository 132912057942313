import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { MobileView, BrowserView } from 'react-device-detect';
import Skeleton from 'react-loading-skeleton';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  getOrder,
  approveOrder,
  rejectOrder,
  acceptOrder,
} from '../../api/order';
import {
  capitalize,
  getDateFormatted,
  getTimeFormatted,
  getPhoneFormatted,
} from '../../helpers/utils';
import AdminOrderItemCol from '../../components/adminOrderItemCol';
import PaymentDetails from '../../components/paymentDetails';
import EmptyPage from '../../components/emptyPage';
import AdminOrderItemColSkeleton from '../../components/adminOrderItemColSkeleton';
import PaymentDetailsSkeleton from '../../components/paymentDetailsSkeleton';
import Popup from '../../components/popup';
import PopupSaving from '../../components/popupSaving';
import StatusBadge from '../../components/statusBadge';
import { useNotification } from '../../hooks';
import { statusKey } from '../../constants/status';

const AdminOrderDetail = () => {
  const { hash } = useParams();
  const { success, error } = useNotification();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState();
  const [status, setStatus] = useState();
  const [orderDate, setOrderDate] = useState();
  const [orderTime, setOrderTime] = useState();
  const [deliveryDate, setDeliveryDate] = useState();
  const [deliveryTime, setDeliveryTime] = useState();
  const [airport, setAirport] = useState();
  const [terminal, setTerminal] = useState();
  const [notes, setNotes] = useState();
  const [fullname, setFullname] = useState();
  const [phone, setPhone] = useState();
  const [products, setProducts] = useState();
  const [paymentDetails, setPaymentDetails] = useState();
  const [code, setCode] = useState();
  const [saving, setSaving] = useState();
  const history = useHistory();

  const [popupProps, setPopupProps] = useState();

  const handleOrder = async (approve) => {
    setPopupProps({ show: false });
    setSaving(true);
    const result = approve ? await approveOrder(hash) : await rejectOrder(hash);
    setTimeout(() => {
      setSaving(false);

      if (result.success) {
        success({
          message: `Order ${approve ? `approved` : `rejected`} succesfully`,
        });

        history.push(`/admin/order`);
      } else {
        error({ message: `${result.error.message} Contact support.` });
      }
    }, 4000);
  };

  const handleAccept = async () => {
    setPopupProps({ show: false });
    setSaving(true);
    try {
      const result = await acceptOrder(hash);

      setTimeout(() => {
        setSaving(false);

        if (result.success) {
          success({
            message: `Order accepted succesfully`,
          });

          history.push(`/admin/order`);
        } else {
          error({ message: `${result.error.message} Contact support.` });
        }
      }, 4000);
    } catch (error) {
      error({ message: `${error.message} Contact support.` });
    }
  };

  const secondaryButton = {
    text: `No`,
    onClick: () => setPopupProps({ show: false }),
  };

  const onClickRejectOrder = () => {
    setPopupProps({
      show: true,
      content: `Are you sure you want to <b class="text-danger">REJECT</b> this order?`,
      primaryButton: {
        text: `Yes`,
        onClick: () => handleOrder(false),
      },
      secondaryButton,
    });
  };

  const onClickPrepareOrder = () => {
    setPopupProps({
      show: true,
      content: `Are you sure this order is <b class="text-success">PREPARED</b> ?`,
      primaryButton: {
        text: `Yes`,
        onClick: () => handleOrder(true),
      },
      secondaryButton,
    });
  };

  const onClickAcceptOrder = () => {
    setPopupProps({
      show: true,
      content: `Are you sure this order is <b class="text-success">ACCEPTED</b> ?`,
      primaryButton: {
        text: `Yes`,
        onClick: () => handleAccept(),
      },
      secondaryButton,
    });
  };

  useEffect(() => {
    const fetchOrder = async () => {
      setLoading(true);
      const order = await getOrder(hash);
      setOrder(order);
      setTimeout(() => setLoading(false), 400);
    };

    fetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (order) {
      const status = { ...order.orderStatus };
      const fullname = `
        ${capitalize(order.customer.user.firstName)} 
        ${capitalize(order.customer.user.lastName)}
      `;
      const phone = getPhoneFormatted(order.customer.user.phone);
      const orderDate = getDateFormatted(order.createdAt);
      const orderTime = getTimeFormatted(order.createdAt);
      const deliveryDate = getDateFormatted(order.flightDate);
      const deliveryTime = order.deliveryTime;
      const airport = order.airport.code;
      const terminal = order.airportTerminal.code;
      const notes = order.notes;
      const products = order.cart.products;
      const paymentDetails = {
        subtotal: order.subtotal,
        taxes: order.tax,
        discount: order.discount,
        total: order.total,
        taxRate: order.taxRate,
      };
      const code = order.code;

      setOrderDate(orderDate);
      setOrderTime(orderTime);
      setDeliveryDate(deliveryDate);
      setDeliveryTime(deliveryTime);
      setAirport(airport);
      setTerminal(terminal);
      setNotes(notes);
      setStatus({ ...status });
      setFullname(fullname);
      setPhone(phone);
      setProducts(products);
      setPaymentDetails(paymentDetails);
      setCode(code);
    }
  }, [order]);

  return (
    <>
      {/* Save Loading */}
      {saving && (
        <PopupSaving
          show={saving}
          content={`We're processing everything related to the order...`}
        />
      )}

      {/* Desktop View */}
      <BrowserView>
        <div className="page-container admin-order">
          {/* Title and Buttons */}
          <Row className="d-none d-sm-flex">
            <Col>
              <h1>Order Detail</h1>
            </Col>
          </Row>
          <Row className="row-cols-3 d-none d-sm-flex align-items-center breadcrumb-order-container">
            <Col xs={4}>
              <p className="breadcrumb-order">
                <Link to="/admin/order" replace>
                  Orders
                </Link>
                {` > Order #${code}`}
                {status && (
                  <span className="ml-4">
                    <StatusBadge code={status.code} name={status.name} />
                  </span>
                )}
              </p>
            </Col>

            {status && status?.code === statusKey.SCHEDULED && (
              <Col xs={8} className="buttons-container">
                {!order.isAccepted && status?.code === statusKey.SCHEDULED && (
                  <Link
                    className="btn btn-link btn-accept"
                    to="#"
                    onClick={onClickAcceptOrder}
                  >
                    Accept order
                  </Link>
                )}

                {order.isAccepted && (
                  <Link
                    className="btn btn-link btn-accept disabled"
                    to="#"
                    onClick={onClickRejectOrder}
                  >
                    <i className="fas fa-check mr-2"></i>
                    Accepted
                  </Link>
                )}

                <Link
                  className="btn btn-md btn-outline-primary"
                  to="#"
                  onClick={onClickRejectOrder}
                >
                  Reject order
                </Link>

                <Link
                  className="btn btn-md btn-primary"
                  to="#"
                  onClick={onClickPrepareOrder}
                >
                  Prepare order
                </Link>
              </Col>
            )}
          </Row>

          {/* Order & Customer Info */}
          <div className="d-flex">
            {/* Order Info */}
            <div className="admin-order-list col-left">
              <Card className="admin-order-detail-card-item">
                <Card.Body>
                  <div
                    className="d-flex align-items-center"
                    style={{ flexFlow: 'wrap', rowGap: '25px' }}
                  >
                    {!loading && (
                      <>
                        {/* Order Date */}
                        <AdminOrderItemCol
                          label={'Order Date'}
                          value={orderDate}
                        />

                        {/* Order Time */}
                        <AdminOrderItemCol
                          label={'Order Time'}
                          value={orderTime}
                        />

                        {/* Delivery Date */}
                        <AdminOrderItemCol
                          label={'Delivery Date'}
                          value={deliveryDate}
                        />

                        {/* Delivery Time */}
                        <AdminOrderItemCol
                          label={'Delivery Time'}
                          value={deliveryTime}
                        />

                        {/* Airport */}
                        <AdminOrderItemCol label={'Airport'} value={airport} />

                        {/* Terminal */}
                        <AdminOrderItemCol
                          label={'Terminal'}
                          value={terminal}
                        />

                        {/* Notes */}
                        {notes && (
                          <AdminOrderItemCol label={'Notes'} value={notes} />
                        )}
                      </>
                    )}

                    {loading && (
                      <>
                        <AdminOrderItemColSkeleton />
                        <AdminOrderItemColSkeleton />
                        <AdminOrderItemColSkeleton />
                        <AdminOrderItemColSkeleton />
                      </>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </div>

            {/* Customer Info */}
            <div className="admin-order-list flex-grow-1">
              <Card className="admin-order-detail-card-item">
                <Card.Body>
                  <div className="d-flex align-items-center">
                    {!loading && (
                      <>
                        {/* Name */}
                        <AdminOrderItemCol label={'Name'} value={fullname} />

                        {/* Mobile */}
                        <AdminOrderItemCol
                          label={'Mobile'}
                          value={phone || '--'}
                        />
                      </>
                    )}

                    {loading && (
                      <>
                        <AdminOrderItemColSkeleton />
                        <AdminOrderItemColSkeleton />
                      </>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>

          {/* Items & Payment Details */}
          <div className="d-flex">
            {/* Items */}
            <div className="admin-order-list col-left">
              <Card className="admin-order-detail-card-item">
                <div>
                  <div className="card-body">
                    <h2>Items</h2>

                    {/* Product */}
                    {!loading &&
                      products &&
                      products.map((e, k) => (
                        <ProductItem
                          key={`AdminOrderDetailProductItem_${k}`}
                          {...e}
                        />
                      ))}

                    {loading && (
                      <>
                        <ProductItemSkeleton />
                        <ProductItemSkeleton />
                        <ProductItemSkeleton />
                      </>
                    )}
                  </div>
                </div>
              </Card>
            </div>

            {/* Payment Details */}
            <div className="admin-order-list flex-grow-1">
              {!loading && <PaymentDetails {...paymentDetails} />}
              {loading && <PaymentDetailsSkeleton />}
            </div>
          </div>
        </div>

        {/* Confirmation Popup */}
        {popupProps && <Popup {...popupProps} />}
      </BrowserView>

      {/* Mobile View */}
      <MobileView>
        <EmptyPage
          // image={}
          title="Oops! Admin pages are not allowed from mobile"
          subtitle="Please try to use this page from your desktop computer."
        />
      </MobileView>
    </>
  );
};

const ProductItem = (e) => (
  <>
    <Row className="align-items-center no-gutters admin-order-detail-item">
      <Col>
        <div className="d-flex align-items-center my-2">
          <div className="order-item-image-container">
            <Image
              src={`${
                e.image
                  ? 'data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=='
                  : 'assets/images/logo-plane.svg'
              }`}
              className="order-item-image"
              style={{
                backgroundImage: `url(${e.image})`,
              }}
            />
          </div>

          <span className="admin-order-detail-item-name">
            <span className="name">{e.name}</span>
            {e.ingredients.length && (
              <span className="ingredients">
                {e.ingredients
                  .filter((e) => e.isIncluded)
                  .map((e) => e.name)
                  .join(', ')}
              </span>
            )}
          </span>
        </div>
      </Col>
      <Col className="col-min-width">
        <span>{e.cartQuantity}</span>
      </Col>
      <Col className="col-min-width">
        <span>{`$${e.cartPrice.toFixed(2)}`}</span>
      </Col>
    </Row>
  </>
);

const ProductItemSkeleton = () => (
  <>
    <Row className="align-items-center no-gutters admin-order-detail-item">
      <Col>
        <div className="d-flex align-items-center my-2">
          <div className="order-item-image-container">
            <Skeleton circle={true} height={50} width={50} />
          </div>

          <span className="admin-order-detail-item-name">
            <span className="name">
              <Skeleton height={18} width={500} />
            </span>
            <span className="ingredients">
              <Skeleton height={14} width={200} />
            </span>
          </span>
        </div>
      </Col>
      <Col className="col-min-width">
        <span>
          <Skeleton height={18} width={30} />
        </span>
      </Col>
      <Col className="col-min-width">
        <span>
          <Skeleton height={18} width={60} />
        </span>
      </Col>
    </Row>
  </>
);

export default AdminOrderDetail;
