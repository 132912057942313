import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { getDateFormatted } from '../../helpers/utils';
import AdminOrderItemCol from '../adminOrderItemCol';
import { statusKey } from '../../constants/status';

function DeliveryCard(props) {
  const {
    hash,
    code,
    orderStatus: statusObject,
    flightDate,
    deliveryTime,
    airport: ariportObject,
    airportTerminal: terminalObject,
    notes,
    onClickAccept,
    onClickReject,
  } = props;

  const { name: airport } = ariportObject;
  const { name: terminal } = terminalObject;

  const deliveryDate = getDateFormatted(flightDate);

  const showButtons =
    (onClickReject || onClickAccept) &&
    statusObject.code === statusKey.PREPARED;

  return (
    <>
      <Card className="delivery-card">
        <Card.Body>
          {/* Order Header */}
          <div className="delivery-header d-flex align-items-center">
            <span className="flex-grow-1">
              Order <b>{code}</b>
            </span>
          </div>

          {/* Order Info */}
          <div className="delivery-info-list">
            <div className="d-flex align-items-center">
              {/* Delivery Date */}
              <AdminOrderItemCol label={'Delivery Date'} value={deliveryDate} />

              {/* Delivery Time */}
              <AdminOrderItemCol label={'Delivery Time'} value={deliveryTime} />
            </div>
          </div>

          {/* Notes */}
          {notes && (
            <div className="delivery-info-list">
              <div className="d-flex align-items-center">
                {/* Delivery Date */}
                <AdminOrderItemCol label={'Notes'} value={notes} />
              </div>
            </div>
          )}

          {/* Airport */}
          <div className="airport">
            <i className="fas fa-plane"></i>
            <span>{airport}</span>
          </div>

          {/* Terminal */}
          <div className="terminal">
            <i className="fab fa-simplybuilt"></i>
            <span>{terminal}</span>
          </div>
        </Card.Body>

        {/* Footer */}
        {showButtons && (
          <Card.Footer className="text-muted">
            {/* Reject Button */}
            {onClickReject && (
              <Link
                to="#"
                replace
                className="btn-icon reject only-icon"
                onClick={() => {
                  const order = {
                    hash,
                    code,
                    flightDate,
                    deliveryTime,
                    airport: ariportObject,
                    airportTerminal: terminalObject,
                  };

                  onClickReject(order);
                }}
              >
                <i className="fas fa-times"></i>
              </Link>
            )}

            {/* Accept Button */}
            {onClickAccept && (
              <Link
                to="#"
                replace
                className="btn-icon accept only-icon"
                onClick={() => onClickAccept(hash)}
              >
                <i className="fas fa-check"></i>
              </Link>
            )}

            {/* <Link
            to="#"
            replace
            className="btn-icon reject"
            onClick={() => onClickReject(hash)}
          >
            Reject
          </Link>

          <Link
            to="#"
            replace
            className="btn-icon accept"
            onClick={() => onClickAccept(hash)}
          >
            Accept
          </Link> */}
          </Card.Footer>
        )}
      </Card>
    </>
  );
}

DeliveryCard.propTypes = {
  hash: PropTypes.string,
  code: PropTypes.string,
  orderStatus: PropTypes.object,
  flightDate: PropTypes.string,
  deliveryTime: PropTypes.string,
  notes: PropTypes.string,
  airport: PropTypes.object,
  airportTerminal: PropTypes.object,
  onClickAccept: PropTypes.func,
  onClickReject: PropTypes.func,
};

export default DeliveryCard;
