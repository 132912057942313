import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { exportToTop } from '../../helpers/utils';
import { isBrowser, isTablet, isMobile } from 'react-device-detect';
import EmptyPage from '../../components/emptyPage';
import DefaultAirportImage from '../../assets/images/default-airport.svg';
import { HeaderContainer } from '../../components/headerContainer';
import { FooterContainer } from '../../components/footerContainer';
import { useUser } from '../../hooks';

const WhereWeOperatePage = () => {
  const { user } = useUser();

  useEffect(() => {
    exportToTop();
  }, []);

  return (
    <>
      <HeaderContainer />
      <div
        className={
          user ? `main container container-fluid py-1` : `main container`
        }
      >
        <div className="page-container where-we-operate my-5 p-2">
          {(isBrowser || !user) && (
            <h1 className="page-title mb-4">Where We Operate</h1>
          )}

          <Row>
            {/* Left Panel */}
            <Col xs={12} md={6}>
              <div className="bullets">
                <ul className="no-bullets">
                  <li>
                    <i className="fas fa-plane"></i>
                    <span className="d-inline-block">
                      Charlotte-Douglas (<b>CLT</b>)
                    </span>
                    <span className="terminal small ">Wilson Air FBO</span>
                  </li>
                  <li>
                    <i className="fas fa-plane"></i>
                    <span className="d-inline-block">
                      Concord-Padgett (<b>JQF</b>)
                    </span>
                    <span className="terminal small ">GA FBO</span>
                  </li>
                  {/* <li>
                    <i className="fas fa-plane"></i>
                    <span className="d-inline-block">
                      Charlotte-Monroe Executive (<b>EQY</b>)
                    </span>
                    <span className="terminal small ">GA FBO</span>
                  </li> */}
                  {/* <li>
                    <i className="fas fa-plane"></i>
                    <span className="d-inline-block">
                      Rock Hill (<b>UZA</b>)
                    </span>
                    <span className="terminal small ">SkyTech FBO</span>
                  </li>
                  <li>
                    <i className="fas fa-plane"></i>
                    <span className="d-inline-block">
                      Lincolnton-Lincoln County (<b>IPJ</b>)
                    </span>
                    <span className="terminal small ">GA FBO</span>
                  </li>
                  <li>
                    <i className="fas fa-plane"></i>
                    <span className="d-inline-block">
                      Gastonia Municipal (<b>AKH</b>)
                    </span>
                    <span className="terminal small ">
                      Academy of Aviation FBO
                    </span>
                  </li>
                  <li>
                    <i className="fas fa-plane"></i>
                    <span className="d-inline-block">
                      Stanly County (<b>VUJ</b>)
                    </span>
                    <span className="terminal small ">GA FBO</span>
                  </li> */}
                </ul>
              </div>
            </Col>

            {/* Right Panel */}
            {(isBrowser || isTablet || isMobile) && (
              <Col md={6}>
                <EmptyPage image={DefaultAirportImage} />
              </Col>
            )}
          </Row>
        </div>
      </div>
      <FooterContainer />
    </>
  );
};

export default WhereWeOperatePage;
