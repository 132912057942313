import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import { useToggle, useCart } from '../../hooks';
import RatingStars from '../rating';
import QuantitySelector from '../quantitySelector';
import { addFavorite, deleteFavorite } from '../../api/customer';

const ProductCard = (props) => {
  const { product, isGridView, showFavorite, showFooter } = props;
  const { showProductModal, setProductDetail } = useCart(); //addItem
  const { hash, name, description, price, rating, image, isFavorite } = product;

  const [favorite, setFavorite] = useToggle(isFavorite);
  const [animation, setAnimation] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const favoriteClassMobile = classNames({
    'add-favorite': true,
    mobile: true,
    btn: true,
    'btn-light': true,
    'd-sm-none': true,
    'is-grid': !isGridView,
    active: favorite,
    animation: favorite && animation,
    'd-none': !showFavorite,
  });

  const addItemClassMobile = classNames({
    'add-btn': true,
    mobile: true,
    btn: true,
    'btn-light': true,
    'd-sm-none': true,
    'is-grid': !isGridView,
  });

  const favoriteClassDesktop = classNames({
    'add-favorite': true,
    desktop: true,
    btn: true,
    active: favorite,
    animation: favorite && animation,
    'd-none': !showFavorite,
  });

  const handleFavorite = () => {
    favorite ? deleteFavorite(hash) : addFavorite(hash);
    setFavorite();
    setAnimation(true);
  };

  const handleQuantity = (qty) => {
    setQuantity(qty);
  };

  const handleAddToCart = () => {
    // addItem({ product, quantity });
    handleViewDetail();
  };

  const handleViewDetail = () => {
    setProductDetail({ product, quantity });
    showProductModal();
  };

  const footerClass = classNames({
    'd-none': !showFooter,
  });

  return (
    <>
      <Card className="product-card">
        <Card.Img
          variant="top"
          src="data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
          className={`card-image ${isGridView ? '' : 'is-grid'}`}
          style={{ backgroundImage: `url(${image})` }}
          onClick={handleViewDetail}
        />

        <Card.Body>
          {/* Float buttons */}
          <Link
            to="#"
            replace
            className={favoriteClassMobile}
            onClick={handleFavorite}
          >
            <i className="fas fa-heart"></i>
          </Link>

          <Link
            to="#"
            replace
            className={addItemClassMobile}
            onClick={handleAddToCart}
          >
            <i className="fas fa-plus"></i>
          </Link>

          <Card.Title>
            <Row className="no-gutters">
              <Col xs={8} md={8}>
                <span onClick={handleAddToCart}>{name}</span>
              </Col>
              <Col xs={4} md={4} className="text-right d-none d-sm-block">
                <Link
                  to="#"
                  className={favoriteClassDesktop}
                  onClick={handleFavorite}
                >
                  <i className="fas fa-heart"></i>
                </Link>
              </Col>
              <Col xs={4} md={4} className="d-block d-sm-none">
                <p className="item-price">${price.toFixed(2)}</p>
              </Col>
            </Row>
          </Card.Title>

          <Card.Text>{description}</Card.Text>
        </Card.Body>

        <Card.Footer className="text-muted">
          <Container fluid>
            <Row className="no-gutters">
              <Col xs="auto" md={6}>
                {false && <RatingStars value={rating} readOnly={true} />}
              </Col>

              <Col xs={4} md={6} className="d-none d-sm-block">
                <p className="item-price">${price.toFixed(2)}</p>
              </Col>

              <div className={footerClass}>
                <Col className="text-right text-md-left">
                  <QuantitySelector
                    value={quantity}
                    onChange={handleQuantity}
                  />
                </Col>

                <Col
                  xs={6}
                  md={6}
                  size="sm"
                  className="text-right d-none d-sm-block"
                >
                  <Button
                    variant="btn"
                    className="btn-primary btn-sm"
                    onClick={handleAddToCart}
                  >
                    Add to cart
                  </Button>
                </Col>
              </div>
            </Row>
          </Container>
        </Card.Footer>
      </Card>
    </>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object,
  isGridView: PropTypes.bool,
  showFooter: PropTypes.bool,
  showFavorite: PropTypes.bool,
};

ProductCard.propDefault = {
  isFavorite: false,
  isGridView: false,
  showFooter: true,
  showFavorite: true,
};

export default ProductCard;
