import 'react-bootstrap-typeahead/css/Typeahead.css';
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Typeahead } from 'react-bootstrap-typeahead';
import { getAll as getAllIngredients } from '../../api/ingredient';

const AdminProductForm = ({
  hash,
  name,
  price,
  description,
  category,
  categories,
  image,
  calories,
  // grams,
  ingredients,
  onSubmit,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const [fileUploaded, setFileUploaded] = useState();
  const [ingredientsSelected, setIngredientsSelected] = useState(ingredients);
  const [ingredientsList, setIngredientsList] = useState(ingredients);
  const hiddenFileInput = useRef();
  const [now, setNow] = useState(Date.now());

  const handleImageClick = () => hiddenFileInput.current.click();

  const handleImageChange = (e) => setFileUploaded(e.target.files[0]);

  const handleIngredientsChange = (i) => {
    setIngredientsSelected(i);
    setValue('ingredients', i);
  };

  useEffect(() => {
    const getData = async () => {
      const result = await getAllIngredients();
      setIngredientsList(result);
    };

    setNow(Date.now());

    getData();
  }, []);

  useEffect(() => {
    setValue('hash', hash);
  }, [hash]);

  useEffect(() => {
    setValue('ingredients', ingredients);
  }, [ingredients]);

  useEffect(() => {
    setValue('image', fileUploaded);
  }, [fileUploaded]);

  return (
    <>
      <div className="create-code-form mt-4">
        <Form noValidate id="product-form" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            {/* Name */}
            <Col xs={12}>
              <Controller
                name="name"
                control={control}
                defaultValue={name}
                rules={{ required: true }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="name">
                      <Form.Label>Name</Form.Label>
                      <Form.Control {...field} isInvalid={!!errors.name} />
                      <Form.Control.Feedback type="invalid">
                        {!!errors.name &&
                          errors.name.type === 'required' &&
                          'Name is required'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />
            </Col>
          </Row>

          <Row>
            {/* Description */}
            <Col xs={12}>
              <Controller
                name="description"
                control={control}
                defaultValue={description}
                rules={{ required: true }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="description">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        {...field}
                        isInvalid={!!errors.description}
                      />
                      <Form.Control.Feedback type="invalid">
                        {!!errors.description &&
                          errors.description.type === 'required' &&
                          'Description is required'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />
            </Col>
          </Row>

          <Row>
            {/* Price */}
            <Col xs={6}>
              <Controller
                name="price"
                control={control}
                defaultValue={price}
                rules={{ required: true, min: 0.01, max: 100 }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="price">
                      <Form.Label>Price</Form.Label>
                      <Form.Control
                        {...field}
                        isInvalid={!!errors.price}
                        type="number"
                        step=".01"
                        min="0.01"
                        max="100"
                      />
                      <Form.Control.Feedback type="invalid">
                        {!!errors.price && 'Price is invalid'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />
            </Col>
            {/* </Row>

          <Row> */}
            {/* Grams */}
            {/* <Col xs={6}>
              <Controller
                name="grams"
                control={control}
                defaultValue={grams}
                rules={{ required: false, min: 1, max: 1000 }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="price">
                      <Form.Label>Grams</Form.Label>
                      <Form.Control
                        {...field}
                        isInvalid={!!errors.grams}
                        type="number"
                        min="1"
                        max="1000"
                      />
                      <Form.Control.Feedback type="invalid">
                        {!!errors.grams && 'Grams is invalid'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />
            </Col> */}

            {/* Calories */}
            <Col xs={6}>
              <Controller
                name="calories"
                control={control}
                defaultValue={calories}
                rules={{ required: true, min: 1, max: 1000 }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="price">
                      <Form.Label>Calories</Form.Label>
                      <Form.Control
                        {...field}
                        isInvalid={!!errors.calories}
                        type="number"
                        min="1"
                        max="1000"
                      />
                      <Form.Control.Feedback type="invalid">
                        {!!errors.calories && 'Calories is invalid'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />
            </Col>
          </Row>

          <Row>
            {/* Category */}
            <Col xs={12}>
              <Controller
                name="category"
                control={control}
                defaultValue={category.code || ''}
                rules={{ required: true }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="category">
                      <Form.Label>Category</Form.Label>
                      <Form.Control
                        as="select"
                        {...field}
                        isInvalid={!!errors.category}
                      >
                        <option value={''} disabled>
                          Choose...
                        </option>

                        {categories &&
                          categories.map((e) => (
                            <option
                              key={`AdminProductForm_Categories_${e.code}`}
                              value={e.code}
                            >
                              {e.name}
                            </option>
                          ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {!!errors.category &&
                          errors.category.type === 'required' &&
                          'Category is required'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />
            </Col>
          </Row>

          <Row>
            {/* Image */}
            <Col xs={12}>
              <Controller
                id="image"
                name="image"
                control={control}
                defaultValue={image}
                //rules={{ required: true }}
                render={() => (
                  <div className="select-element">
                    <Form.Group controlId="image">
                      <Form.Label className={`d-block mb-3`}>Image</Form.Label>
                      <Form.File
                        accept="image/*"
                        isInvalid={!!errors.image}
                        className={`d-none`}
                        ref={hiddenFileInput}
                        onChange={handleImageChange}
                      />
                      <Link
                        className="btn btn-primary btn-sm"
                        to="#"
                        replace
                        onClick={handleImageClick}
                      >
                        Choose Image
                      </Link>
                      {fileUploaded && (
                        <div className={`mt-4`}>
                          <img
                            className={`img-thumbnail`}
                            src={URL.createObjectURL(fileUploaded)}
                          />
                        </div>
                      )}

                      {image && !fileUploaded && (
                        <div className={`mt-4`}>
                          <img
                            className={`img-thumbnail`}
                            src={`${image}?${now}`}
                          />
                        </div>
                      )}

                      {/* {!!errors.image && (
                        <Form.Control.Feedback
                          type="invalid"
                          className={`d-block`}
                        >
                          {'Image is required'}
                        </Form.Control.Feedback>
                      )} */}
                    </Form.Group>
                  </div>
                )}
              />
            </Col>
          </Row>

          <Row>
            {/* Ingredients */}
            <Col>
              <span className="d-block small m-0 mt-2">Ingredients</span>
              <div className="select-element input-search mb-0">
                <Typeahead
                  id="ingredient-selector"
                  labelKey="name"
                  options={ingredientsList}
                  multiple
                  placeholder="Add ingredients"
                  onChange={handleIngredientsChange}
                  selected={ingredientsSelected}
                />
                {/* <i className="fas fa-search"></i> */}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

AdminProductForm.propTypes = {};

AdminProductForm.propTypes = {
  hash: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.number,
  description: PropTypes.string,
  category: PropTypes.object,
  categories: PropTypes.array,
  image: PropTypes.string,
  calories: PropTypes.number,
  grams: PropTypes.number,
  ingredients: PropTypes.array,
  onSubmit: PropTypes.func,
};

AdminProductForm.defaultProps = {
  name: '',
  price: 0,
  description: '',
  category: {},
  categories: [],
  image: '',
  calories: 0,
  grams: 0,
  ingredients: [],
};

export default AdminProductForm;
