import React, { useEffect, useState, useMemo } from 'react';
import { addResponseInterceptor, ejectResponseInterceptor } from '../api/base';
import { getUser, getUserRole } from '../api/user';
import { signin } from '../api/auth';
import storage from '../helpers/storage';
import { storageKey } from '../constants/storage';
import { useNotification } from '../hooks';

export const UserContext = React.createContext();

export const UserProvider = (props) => {
  const [user, setUser] = useState(storage.get(storageKey.USER));
  const [role, setRole] = useState(storage.get(storageKey.ROLE));
  const [error, setError] = useState(null);

  const { error: errorMessage } = useNotification();

  // To intercept when jwt has expired
  useEffect(() => {
    const interceptor = addResponseInterceptor(
      (res) => {
        if (res.status == 401) setError(res.data);
        return res;
      },
      (err) => err
    );
    return () => ejectResponseInterceptor(interceptor);
  }, []);

  /**
   * To check if the user stored in the localstorage is a valid user.
   * If someone access by url and the user stored into the localstorage
   * is not null. We must validate the user and whether it has a valid cookie
   */
  useEffect(() => {
    if (user) {
      getUser(user.hash).then((res) => {
        if (!res.success) setUser(null);
      });

      if (role) {
        getUserRole(user.hash).then((res) => {
          if (!res.success) setRole(null);
        });
      }
    } else {
      setUser(null);
      setRole(null);
    }
  }, [user, role]);

  // If an error occurs then we clear the user
  useEffect(() => {
    if (error) setUser(null);
  }, [error]);

  // If the user is updated then we update the localstorage
  useEffect(() => {
    storage.set(storageKey.USER, user || null);
  }, [user]);

  // If the user role is updated then we update the localstorage
  useEffect(() => {
    storage.set(storageKey.ROLE, role);
  }, [role]);

  function login(credentials) {
    setError(null);
    signin(credentials).then((res) => {
      if (res.success) {
        setUser(res.data);

        if (!res.data) {
          return setError('Error in Login');
        }

        const { hash } = res.data;
        getUserRole(hash).then((res) => {
          if (res.success) setRole(res.data.role);
          else setError(res.error);
        });
      } else {
        errorMessage({ message: `${res.error.message}` });
        setError(res.error);
      }
    });
  }

  const logout = () => {
    storage.clear();
    setUser(null);
  };

  const setOnboarding = () => {
    setUser({ ...user, isOnboardingDone: true });
  };

  const options = useMemo(
    () => ({
      isUserLoggedIn: user !== null,
      user,
      role,
      error,
      login,
      logout,
      setOnboarding,
      setUser,
    }),
    [user, role, error]
  );

  return (
    <UserContext.Provider
      value={options}
      {...props}
      displayName="UserContext"
    />
  );
};
