import { apiCall } from './base';

export const getAll = async (params) => {
  const result = await apiCall(`/role`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
    params,
  });
  return result.data;
};

export const getOne = async (code) => {
  const result = await apiCall(`/role/${code}`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
  });
  return result;
};
