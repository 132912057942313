import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { exportToTop } from '../../helpers/utils';
import { isBrowser, isTablet, isMobile } from 'react-device-detect';
import { HeaderContainer } from '../../components/headerContainer';
import { FooterContainer } from '../../components/footerContainer';
import { useUser } from '../../hooks';
import EmptyPage from '../../components/emptyPage';
import LostImage from '../../assets/images/my-flights.svg';

function NotFoundPage() {
  const { user } = useUser();

  useEffect(() => {
    exportToTop();
  }, []);

  return (
    <>
      <HeaderContainer />
      <div
        className={
          user ? `main container container-fluid py-1` : `main container`
        }
      >
        <div className="page-container my-5 p-2 text-center">
          {(isBrowser || !user) && (
            <>
              <h1 className="page-title mb-4">
                <b>404</b>
              </h1>
              <h2>It seems this is not the page you are looking for.</h2>
              <div style={{ marginBottom: '100px' }} />
            </>
          )}

          <Row>
            {(isBrowser || isTablet || isMobile) && (
              <Col md={12}>
                <EmptyPage image={LostImage} />
              </Col>
            )}
          </Row>
        </div>
      </div>
      <FooterContainer />
    </>
  );
}

export default NotFoundPage;
