import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserView, MobileView } from 'react-device-detect';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

const IngredientSelector = (props) => {
  const { value, selected, onChange, isVertical, isSmall } = props;
  // const [selectAll, setSelectAll] = useState(false);
  const [ingredients] = useState(value);
  const [ingredientsSelected, setIngredientsSelected] = useState(selected);

  const handleChange = (val) => {
    setIngredientsSelected(val);
  };

  // const handleSelectAll = () => {
  //   let allIngredients = [];
  //   if (selectAll) {
  //     allIngredients = ingredients.map((e) => e.code);
  //   }
  //   handleChange(allIngredients);
  // };

  useEffect(() => {
    // if (!ingredientsSelected.length) {
    //   const list = ingredients.map((i) => i.code);
    //   setIngredientsSelected(list);
    // }
    if (ingredients.length === 1 && ingredients[0].code === 'generic') {
      setIngredientsSelected(ingredients[0].code);
    }
  }, []);

  useEffect(() => {
    // ingredientsSelected.length ? setSelectAll(false) : setSelectAll(true);
    onChange(ingredientsSelected);
  }, [ingredientsSelected, onChange]);

  return (
    <>
      {/* Desktop View */}
      <BrowserView>
        <div className="ingredient-selector desktop">
          {/* All Items */}
          {/* <ToggleButtonGroup
            type="radio"
            name="all-ingredients"
            value={selectAll}
            onChange={handleSelectAll}
          >
            <ToggleButton
              checked={selectAll}
              className=""
              value={true}
              variant="outline-secondary"
            >
              {selectAll ? 'Select all' : 'Unselect all'}
            </ToggleButton>
          </ToggleButtonGroup> */}

          {/* Rest of the Ingredients */}
          <ToggleButtonGroup
            type="radio"
            name="ingredients"
            value={ingredientsSelected}
            onChange={handleChange}
          >
            {ingredients &&
              ingredients
                .sort((a, b) => {
                  if (
                    a?.productIngredients?.order &&
                    b?.productIngredients?.order
                  ) {
                    return (
                      a?.productIngredients?.order -
                      b?.productIngredients?.order
                    );
                  } else {
                    if (a.name > b.name) {
                      return 1;
                    }
                    if (b.name > a.name) {
                      return -1;
                    }
                    return 0;
                  }
                })
                .map((e, k) => (
                  <ToggleButton
                    key={k}
                    value={e.code}
                    className="ml-2"
                    variant="outline-secondary"
                  >
                    {e.name}
                  </ToggleButton>
                ))}
          </ToggleButtonGroup>
        </div>
      </BrowserView>

      {/* Mobile View */}
      <MobileView>
        <div className={`ingredient-selector mobile mt-4`}>
          {/* All Items */}
          {/* <ToggleButtonGroup
            vertical
            type="radio"
            name="all-ingredients"
            className="w-100"
            value={selectAll}
            onChange={handleSelectAll}
          >
            <ToggleButton
              checked={selectAll}
              value={true}
              className="font-weight-bold"
            >
              {selectAll ? 'Select all' : 'Unselect all'}
              <i className="far fa-square unselected"></i>
              <i className="fas fa-check-square selected"></i>
            </ToggleButton>
          </ToggleButtonGroup> */}

          {/* Rest of the Ingredients */}
          <ToggleButtonGroup
            vertical={isVertical}
            size={isSmall ? 'sm' : 'lg'}
            type="radio"
            name="ingredients"
            className="w-100"
            value={ingredientsSelected}
            onChange={handleChange}
          >
            {ingredients &&
              ingredients
                .sort(
                  (a, b) =>
                    a?.productIngredients?.order - b?.productIngredients?.order
                )
                .map((e, k) => (
                  <ToggleButton key={k} value={e.code}>
                    {e.name}
                    <i className="far fa-square unselected"></i>
                    <i className="fas fa-check-square selected"></i>
                  </ToggleButton>
                ))}
          </ToggleButtonGroup>
        </div>
      </MobileView>
    </>
  );
};

IngredientSelector.propTypes = {
  value: PropTypes.array,
  selected: PropTypes.array,
  onChange: PropTypes.func,
  isVertical: PropTypes.bool,
  isSmall: PropTypes.bool,
};

IngredientSelector.defaultProps = {
  value: [],
  selected: [],
  // eslint-disable-next-line prettier/prettier
  onChange: () => {},
  isVertical: true,
  isSmall: false,
};

export default IngredientSelector;
