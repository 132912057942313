/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { STRIPE_KEY } from '../../config';
import { addCreditCard } from '../../api/customer';
import FormInput from '../formInput';

const CheckoutForm = (props) => {
  const { stripe, elements, onHide } = props;
  const [name, setName] = useState(props.name);
  const [brand, setBrand] = useState('');
  const [errorMessage, setErrorMessage] = useState(props.errorMessage);
  const brands = ['visa', 'mastercard'];

  const ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: '20px',
        color: '#495057',
        padding: '0.375rem 0.75rem',
        fontWeight: 400,
        lineHeight: 1.5,
        backgroundColor: '#fff',
        backgroundClip: 'padding-box',
        border: '1px solid #ced4da',
        borderRadius: '.25rem',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardNumberElement);

    if (card == null) {
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card,
      billing_details: {
        name,
      },
    });

    if (payload.error) {
      setErrorMessage(payload.error.message);
    } else {
      setErrorMessage(null);

      // Call to Backend to Store the new Credit Card
      const token = await addCreditCard(payload.paymentMethod);
      if (token) {
        onHide();
      }
    }
  };

  return (
    <>
      <div className="credit-card-form">
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              {brands.length > 0 &&
                brands.map((e, k) => (
                  <Image
                    key={`BrandImage_${k}`}
                    className={`payment-item-image ${
                      brand === e ? 'active' : ''
                    }`}
                    src={`assets/icons/${e}.svg`}
                  />
                ))}
            </Col>
          </Row>
          <Row>
            {/* Credit Card Number */}
            <Col xs={12} md={12}>
              <div className="select-element">
                <div className="form-group">
                  <label htmlFor="cardNumber">Card Number</label>
                  <CardNumberElement
                    id="cardNumber"
                    className="form-control"
                    options={ELEMENT_OPTIONS}
                    onChange={(val) => setBrand(val.brand)}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            {/* Credit Card Expiration Date */}
            <Col xs={6} md={6}>
              <div className="select-element">
                <div className="form-group">
                  <label htmlFor="expiry">Card Expiration</label>
                  <CardExpiryElement
                    id="expiry"
                    className="form-control"
                    options={ELEMENT_OPTIONS}
                  />
                </div>
              </div>
            </Col>

            {/* Credit Card CVC */}
            <Col xs={6} md={6}>
              <div className="select-element">
                <div className="form-group">
                  <label htmlFor="cvc">CVC</label>
                  <CardCvcElement
                    id="cvc"
                    className="form-control"
                    options={ELEMENT_OPTIONS}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            {/* Credit Card Owner */}
            <Col xs={12} md={12}>
              <FormInput
                label="Full Name"
                controlId={'CreditCard_FullName'}
                placeholder="eg. John Doe"
                onChange={(evt) => setName(evt.target.value)}
              />
            </Col>
          </Row>

          {/* Success/Error Messages */}
          {errorMessage && <div className="error">{errorMessage}</div>}

          {/* {paymentMethod && (
            <div className="result">Got PaymentMethod: {paymentMethod.id}</div>
          )} */}

          {/* Form Submit Button */}
          <Row>
            <Col xs={12} className="text-center positioning">
              <button
                className="btn btn-primary mt-5"
                type="submit"
                disabled={!stripe}
              >
                Add Card
              </button>
            </Col>
          </Row>
        </form>
      </div>
    </>
  );
};

const InjectedCheckoutForm = (props) => {
  const { onHide } = props;

  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} onHide={onHide} />
      )}
    </ElementsConsumer>
  );
};
const CreditCardForm = (props) => {
  const stripePromise = loadStripe(STRIPE_KEY);
  const { onHide } = props;

  return (
    <>
      <Elements stripe={stripePromise}>
        <InjectedCheckoutForm onHide={onHide} />
      </Elements>
    </>
  );
};

export default CreditCardForm;
