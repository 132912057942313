import React from 'react';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Skeleton from 'react-loading-skeleton';

const CategorySkeleton = () => {
  return (
    <>
      <div className="category-filter skeleton">
        <div className="scrolling-wrapper row flex-row flex-nowrap">
          <ToggleButtonGroup type="radio" name="options">
            {[0, 1, 2, 3, 4].map((_, k) => (
              <ToggleButton
                key={`CategorySkeleton_${k}`}
                className="btn-round btn-tags"
                variant="outline-secondary"
              >
                <Skeleton />
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      </div>
    </>
  );
};

export default CategorySkeleton;
