import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const PageTitle = (props) => {
  const {
    title,
    subtitle,
    isBig,
    primaryButtonText,
    primaryButtonClick,
    primaryButtonLoading,
    secondaryButtonText,
    secondaryButtonClick,
  } = props;

  return (
    <>
      <div className="page-title">
        <div className="header-text">
          <div className="d-flex justify-content-between align-items-center">
            {/* Title */}
            {title && (
              <h1 className={`${isBig ? 'big' : 'normal'}`}>{title}</h1>
            )}

            <div>
              {/* Buttons */}
              {/* Secondary Button */}
              {secondaryButtonText && secondaryButtonClick && (
                <Link
                  className="btn btn-md btn-outline-primary"
                  to="#"
                  replace
                  onClick={secondaryButtonClick}
                >
                  {secondaryButtonText}
                </Link>
              )}

              {/* Primary Button */}
              {primaryButtonText && primaryButtonClick && (
                <Link
                  className="btn btn-md btn-primary"
                  to="#"
                  replace
                  onClick={primaryButtonClick}
                  disabled={primaryButtonLoading}
                >
                  {primaryButtonLoading ? `Saving` : primaryButtonText}
                </Link>
              )}
            </div>
          </div>

          {/* Subtitle */}
          <div>{subtitle && <h2>{subtitle}</h2>}</div>
        </div>
      </div>
    </>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  primaryButtonText: PropTypes.string,
  primaryButtonClick: PropTypes.func,
  primaryButtonEnabled: PropTypes.bool,
  primaryButtonLoading: PropTypes.bool,
  secondaryButtonText: PropTypes.string,
  secondaryButtonClick: PropTypes.func,
  secondaryButtonEnabled: PropTypes.bool,
  isBig: PropTypes.bool,
};

PageTitle.propDefault = {
  isBig: false,
  primaryButtonLoading: false,
};

export default PageTitle;
