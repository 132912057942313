import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import AdminUserForm from '../adminUserForm';

const AdminUserModal = ({
  firstName,
  lastName,
  email,
  role,
  roles,
  show,
  hideModal,
  actions: { create, edit },
}) => {
  const handleUserForm = (user) => {
    const isEdit = !!email;
    isEdit ? edit(user) : create(user);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={hideModal}
        dialogClassName="admin-modals"
        centered
      >
        {/* Header */}
        <Modal.Header>
          <Modal.Title>
            <span className="close-modal" onClick={hideModal}>
              <i className="fas fa-times"></i>
            </span>
          </Modal.Title>
        </Modal.Header>

        {/* Body */}
        <Modal.Body>
          <Container>
            <p className="title">Add new user</p>

            {/* Admin User Form */}
            <AdminUserForm
              firstName={firstName}
              lastName={lastName}
              email={email}
              role={role}
              roles={roles}
              onSubmit={handleUserForm}
            />
          </Container>
        </Modal.Body>

        {/* Footer */}
        <Modal.Footer>
          <Button variant="outline-primary" size="md" onClick={hideModal}>
            Close
          </Button>
          <Button
            form="admin-user-form"
            type="submit"
            variant="primary"
            size="md"
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

AdminUserModal.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  role: PropTypes.object,
  roles: PropTypes.array,
  show: PropTypes.bool,
  hideModal: PropTypes.func,
  actions: PropTypes.object,
};

export default AdminUserModal;
