import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const QuantitySelector = (props) => {
  const { value, onChange, className } = props;
  const [quantity, setQuantity] = useState(value);

  const handlePlus = () => {
    if (quantity < 9) {
      setQuantity(quantity + 1);
      onChange(quantity + 1);
    }
  };

  const handleMinus = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      onChange(quantity - 1);
    }
  };

  return (
    <>
      <div className={`quantity-selector ${className}`}>
        {/* Minus */}
        <Link
          to="#"
          className={`btn-icon primary-color ${
            quantity === 1 ? 'disabled' : ''
          }`}
          onClick={handleMinus}
          replace
        >
          <i className="fas fa-minus"></i>
        </Link>

        {/* Value */}
        <span className="quantity-value font-weight-bold mx-3">{quantity}</span>

        {/* Plus */}
        <Link
          to="#"
          className={`btn-icon primary-color ${
            quantity === 9 ? 'disabled' : ''
          }`}
          onClick={handlePlus}
          replace
        >
          <i className="fas fa-plus"></i>
        </Link>
      </div>
    </>
  );
};

QuantitySelector.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  className: PropTypes.any,
};

QuantitySelector.defaultProps = {
  value: 1,
  onChange: () => {},
};

export default QuantitySelector;
