import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { isMobileOnly } from 'react-device-detect';
import DefaultAirportImage from '../../assets/images/default-airport.svg';
import EmptyPage from '../../components/emptyPage';
import PageTitle from '../../components/pageTitle';
import { getAirports } from '../../api/airport';
import { getDefaultAirport, setDefaultAirport } from '../../api/customer';
import FormSelect from '../../components/formSelect';
import NoIngredientSelectionAlert from '../../components/noIngredientSelectionAlert';
import { useNotification } from '../../hooks';
import Form from 'react-bootstrap/Form';

const DefaultAirportPage = () => {
  const [airports, setAirports] = useState();
  const [airportProps, setAirportProps] = useState();
  const [terminalProps, setTerminalProps] = useState();
  const [airport, setAirport] = useState('');
  const [terminal, setTerminal] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const { error, success } = useNotification();

  const saveDefaultAirport = async () => {
    const result = await setDefaultAirport({ airport, terminal });
    if (result && result.success) {
      success({ message: 'Default values are successfully updated' });
    } else {
      error({ message: result.error.message });
    }
  };

  const pageTitleProps = {
    title: 'Default Airport',
    subtitle: 'Select the airport you are going to be using more frequently.',
    primaryButtonText: 'Save',
    primaryButtonClick: saveDefaultAirport,
    primaryButtonDisabled: true,
  };

  const emptyPageProps = {
    image: DefaultAirportImage,
  };

  const updateAirportSelect = () => {
    const airportProps = {
      label: 'Airport',
      options: airports?.map((e) => ({ id: e.id, text: e.name })),
    };
    setAirportProps(airportProps);
  };

  const updateTerminalSelect = () => {
    const a = airports?.filter((a) => a.id === +airport)[0];
    const options = a?.terminals.map((t) => ({ id: t.id, text: t.name }));

    const terminalProps = {
      label: 'Terminal',
      options,
    };
    setTerminalProps(terminalProps);
  };

  useEffect(() => {
    updateTerminalSelect();
    // setTerminal('');
  }, [airport]);

  useEffect(() => {
    updateAirportSelect();
    updateTerminalSelect();
  }, [airports]);

  useEffect(() => {
    if (airports?.length > 0 && airport && terminal) {
      const a = airports.find((a) => a.id === +airport);
      const t = a.terminals.find((t) => t.id === +terminal);
      !t || !t.allowsProductIngredients
        ? setShowWarning(true)
        : setShowWarning(false);
    } else {
      setShowWarning(false);
    }
  }, [airport, terminal]);

  useEffect(() => {
    const init = async () => {
      const data = await getAirports();
      setAirports(data);

      const {
        airport: defaultAirport,
        airportTerminal: defaultAirportTerminal,
      } = await getDefaultAirport();

      setAirport(defaultAirport);
      setTerminal(defaultAirportTerminal);
    };
    init();
  }, []);

  return (
    <>
      {!isMobileOnly && <PageTitle {...pageTitleProps} />}

      {isMobileOnly && (
        <h2 className="personal-information-subtitle">
          Select the airport you are going to be using more frequently.
        </h2>
      )}

      <Form noValidate id="default-airport-form" onSubmit={saveDefaultAirport}>
        <Row>
          {/* Airport */}
          <Col xs={12} sm={6}>
            <FormSelect
              {...airportProps}
              value={airport}
              onChange={(val) => {
                setAirport(val);
                setTerminal('');
              }}
            />
          </Col>

          {/* Terminal */}
          <Col xs={12} sm={6}>
            <FormSelect
              {...terminalProps}
              value={terminal}
              onChange={(val) => setTerminal(val)}
            />
          </Col>
        </Row>
      </Form>

      {/* No Ingredient Selection Alert */}
      {showWarning && (
        <Row>
          <Col xs={12}>
            <NoIngredientSelectionAlert />
          </Col>
        </Row>
      )}

      <EmptyPage {...emptyPageProps} />
    </>
  );
};

export default DefaultAirportPage;
