import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Image, Button, Nav } from 'react-bootstrap';
import { useCart } from '../../hooks';

const Footer = () => {
  const { pathname } = useLocation();
  const activeKey = pathname.substring(1);
  const { cartItems } = useCart();

  const showFooter = () => {
    switch (pathname) {
      case '/checkout':
      case '/order-placed':
      case '/delivery':
        return 'd-none';
      default:
        return 'footer mobile';
    }
  };

  return (
    <>
      {/* Footer Desktop Dark */}
      <footer className="footer desktop">
        <div className="upper-part">
          <div className="logo float-left">
            <Image className="" src="assets/images/logo.svg" />
          </div>

          <div className="links">
            <ul>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/where-we-operate">Where we operate</Link>
              </li>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </div>

          <div className="social-media float-right">
            {/* Facebook */}
            <Button
              variant="link"
              href="https://www.facebook.com/The-Flight-Kitchen-106511295109359"
            >
              <i className="fab fa-facebook"></i>
            </Button>

            {/* Twitter */}
            {/* <Button variant="link" href="#/cart">
              <i className="fab fa-twitter"></i>
            </Button> */}

            {/* Instagram */}
            {/* <Button variant="link" href="#/cart">
              <i className="fab fa-instagram"></i>
            </Button> */}
          </div>
        </div>

        <div className="lower-part">
          <div className="copyright d-inline">
            <span>Copyright {new Date().getFullYear()}</span>
          </div>
          <div className="privacy-policy d-inline">
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
          <div className="terms-and-conditions d-inline">
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
          </div>
        </div>
      </footer>

      {/* <footer className="footer d-block d-sm-none mobile">
        <Nav variant="pills" activeKey="0">
          <Nav.Item>
            <Nav.Link eventKey="1" href="#/menu">
              <i className="fas fa-home"></i>
              <span className="tab-name">Home</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="2" href="#/order">
              <i className="fas fa-list"></i>
              <span className="tab-name">My Orders</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="3" href="#/fav">
              <i className="fas fa-heart"></i>
              <span className="tab-name">My Favorites</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="3" href="#/cart">
              <i className="fas fa-shopping-cart"></i>
              <span className="tab-name">My Cart</span>
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </footer> */}
      {/* Footer Desktop Light */}

      {/* Footer Mobile */}
      <footer className={showFooter()}>
        <Nav variant="pills" activeKey={activeKey}>
          <Nav.Item>
            <Nav.Link eventKey="menu" href="#/menu">
              <i className="fas fa-home"></i>
              <span className="tab-name">Menu</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="order" href="#/order">
              <i className="fas fa-list"></i>
              <span className="tab-name">My Orders</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="fav" href="#/fav">
              <i className="fas fa-heart"></i>
              <span className="tab-name">My Favorites</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className="cart-notification"
              eventKey="cart"
              href="#/cart"
            >
              <i className="fas fa-shopping-cart"></i>
              <span className="tab-name">My Cart</span>
              <div
                className={`badge-notification ${
                  cartItems ? 'd-block' : 'd-none'
                }`}
              >
                <span>{cartItems}</span>
              </div>
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </footer>
    </>
  );
};

export default Footer;
