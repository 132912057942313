import React, { useState, useEffect } from 'react';
import { MobileView, BrowserView } from 'react-device-detect';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdminDiscountCodeModal from '../../components/adminDiscountCodeModal';
import AdminTableRowSkeleton from '../../components/adminTableRowSkeleton';
import DiscountCodeItem from '../../components/discountCodeItem';
import EmptyPage from '../../components/emptyPage';
import { update, getAll, remove, create } from '../../api/discount';
import { useNotification } from '../../hooks';

const AdminDiscountCode = () => {
  const [discounts, setDiscounts] = useState();
  const [loading, setLoading] = useState(false);
  const [discountDetail, setDiscountDetail] = useState({});
  const [showModal, setShowModal] = useState(false);
  const { error, success } = useNotification();

  const emptyProps = {
    title: 'No Discount Codes.',
    subtitle: "You haven't added discount code so far.",
  };

  const showErrorNotification = (result) => {
    const {
      error: { message },
    } = result;

    error({ message });
  };

  const handleCreate = async (discount) => {
    const { code } = discount;
    const result = await create({ ...discount, isActive: true });
    if (result && result.success) {
      success({ message: `Discount Code ${code} created` });
      setShowModal(false);
      await fetchDiscounts();
    } else {
      showErrorNotification(result);
    }
  };

  const handleEdit = async (discount) => {
    const { code } = discount;
    const result = await update({ ...discount }, code);
    if (result && result.success) {
      setShowModal(false);
      await fetchDiscounts();
    } else {
      showErrorNotification(result);
    }
  };

  const handleUpdateStatus = async (discount) => {
    const { isActive, code } = discount;
    const result = await update({ isActive: !isActive }, code);
    if (result && result.success) {
      success({ message: `Discount Code ${code} updated` });
      await fetchDiscounts();
    } else {
      showErrorNotification(result);
    }
  };

  const handleRemove = async (code) => {
    const result = await remove(code);
    if (result && result.success) {
      success({ message: `Discount Code ${code} deleted` });
      await fetchDiscounts();
    } else {
      showErrorNotification(result);
    }
  };

  const handleShowForm = (params) => {
    setDiscountDetail({ ...params });
    setShowModal(true);
  };

  const actions = {
    create: handleCreate,
    edit: handleEdit,
    updateStatus: handleUpdateStatus,
    remove: handleRemove,
    showForm: handleShowForm,
  };

  const fetchDiscounts = async () => {
    setLoading(true);
    const data = await getAll();
    setDiscounts(data);
    setTimeout(() => setLoading(false), 500);
  };

  useEffect(() => {
    fetchDiscounts();
  }, []);

  return (
    <>
      {/* Desktop View */}
      <BrowserView>
        <div className="page-container admin-discount-code">
          {/* Title and Buttons */}
          <Row>
            <Col>
              <h1>Discount Codes</h1>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col sm={{ span: 3, offset: 9 }} className="text-right">
              <Link
                className="btn btn-md btn-primary"
                to="#"
                replace
                onClick={() => handleShowForm({})}
              >
                Add New Code
              </Link>
            </Col>
          </Row>

          {/* Add Form */}
          {discountDetail && (
            <AdminDiscountCodeModal
              {...discountDetail}
              show={showModal}
              actions={actions}
              hideModal={() => setShowModal(false)}
            />
          )}

          {/* List */}
          <div className="admin-discount-code-list">
            {/* Loading */}
            {loading && (
              <>
                <AdminDiscountCodeListHeader />
                <AdminTableRowSkeleton cols={5} />
              </>
            )}

            {/* Items */}
            {!loading && discounts && discounts.length > 0 && (
              <AdminDiscountCodeListHeader />
            )}

            {!loading &&
              discounts &&
              discounts.length > 0 &&
              discounts.map((e) => (
                <DiscountCodeItem
                  key={`AdminDiscountCode_DiscountCode_${e.code}`}
                  actions={actions}
                  {...e}
                  onClick={() => {
                    setDiscountDetail({ ...e });
                  }}
                />
              ))}
          </div>

          {/* Emtpy */}
          {!loading && discounts && !discounts.length && (
            <EmptyPage {...emptyProps} />
          )}
        </div>
      </BrowserView>

      {/* Mobile View */}
      <MobileView>
        <EmptyPage
          // image={}
          title="Oops! Admin pages are not allowed from mobile"
          subtitle="Please try to use this page from your desktop computer."
        />
      </MobileView>
    </>
  );
};

const AdminDiscountCodeListHeader = () => (
  <div className="d-flex list-title">
    <span>Code Name</span>
    <span>Discount</span>
    <span>Assign to</span>
    <span>End Date</span>
    <span className="actions">Edit</span>
    <span className="actions">On/Off</span>
    <span className="actions">Delete</span>
  </div>
);

export default AdminDiscountCode;
