import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { signin, signup } from '../../api/auth';
import { useToggle, useNotification } from '../../hooks';

function SignUpPage() {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { error } = useNotification();
  const history = useHistory();

  const [showPassword, setShowPassword] = useToggle(true);

  const [loading, setLoading] = useState();

  const onSubmit = async (data) => {
    setLoading(true);
    const signupResult = await signup({
      ...data,
      confirmPassword: data.password,
    });

    if (!signupResult.success) {
      setLoading(false);
      return error({ message: `${signupResult.error.message}` });
    }

    const signinResult = await signin({
      email: data.email,
      password: data.password,
    });

    if (!signinResult.success) {
      error({ message: `${signinResult.error.message}` });
      history.push(`/signin`);
      return;
    }

    history.push(`/menu`);
    setLoading(false);
  };

  return (
    <>
      <div className="login">
        <div className="login-container signup">
          <div className="header-login">
            <Link to="/">
              <Image src="assets/images/logo.svg" />
            </Link>
            <p className="logo-claim">Travel happy, not hungry</p>
          </div>

          {/* Sign Up */}
          <div className="form">
            <Form noValidate id="signup-form" onSubmit={handleSubmit(onSubmit)}>
              {/* First Name */}
              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="firstName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        {...field}
                        isInvalid={!!errors.firstName}
                        placeholder="Enter your Firstname"
                      />
                      <Form.Control.Feedback type="invalid">
                        {!!errors.firstName &&
                          errors.firstName.type === 'required' &&
                          'First Name is required'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />

              {/* Last Name */}
              <Controller
                name="lastName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="lastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        {...field}
                        isInvalid={!!errors.lastName}
                        placeholder="Enter your Lastname"
                      />
                      <Form.Control.Feedback type="invalid">
                        {!!errors.lastName &&
                          errors.lastName.type === 'required' &&
                          'Last Name is required'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />

              {/* Email */}
              <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                  pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        {...field}
                        isInvalid={!!errors.email}
                        type="email"
                        placeholder="Enter your Email"
                      />
                      <Form.Control.Feedback type="invalid">
                        {!!errors.email &&
                          errors.email.type === 'required' &&
                          'Email is required'}
                        {!!errors.email &&
                          errors.email.type === 'pattern' &&
                          'Email is invalid'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />

              {/* Password */}
              <Controller
                name="password"
                control={control}
                rules={{ required: true, minLength: 4, maxLength: 20 }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        {...field}
                        isInvalid={!!errors.password}
                        type={showPassword ? 'password' : 'text'}
                        placeholder="Enter your Password"
                      />
                      <Link
                        className="btn btn-link btn-icon password-view"
                        to="#"
                        replace
                        onClick={() => setShowPassword()}
                      >
                        {showPassword ? (
                          <i className="fas fa-eye-slash"></i>
                        ) : (
                          <i className="fas fa-eye"></i>
                        )}
                      </Link>
                      <Form.Control.Feedback type="invalid">
                        {errors?.password?.type === 'required' &&
                          'Password is required'}
                        {errors?.password?.type === 'minLength' &&
                          'Password should have more than 4 characters'}
                        {errors?.password?.type === 'maxLength' &&
                          'Password should have less than 20 characters'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />

              {!loading && (
                <Button variant="primary" type="submit">
                  Sign up
                </Button>
              )}

              {loading && (
                <Button variant="primary" type="submit" disabled={true}>
                  <i className="fas fa-circle-notch fa-spin"></i>
                </Button>
              )}
            </Form>
          </div>

          <div className="user-links d-flex justify-content-between">
            <Link
              className="btn btn-link btn-icon primary-color"
              to="/forgot-password"
            >
              Forgot password?
            </Link>
            <Link className="btn btn-link btn-icon primary-color" to="/signin">
              Sign in
            </Link>
          </div>
        </div>

        <div className="clouds">
          <Image src="assets/images/clouds.svg" className="img-fluid" />
        </div>
      </div>
    </>
  );
}

export default SignUpPage;
