import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';

const Popup = (props) => {
  const [show, setShow] = useState(props.show);
  const [content, setContent] = useState(props.content);
  const [primaryButton, setPrimaryButton] = useState(props.primaryButton);
  const [secondaryButton, setSecondaryButton] = useState(props.secondaryButton);

  useEffect(() => {
    const { show, content, primaryButton, secondaryButton } = props;

    setShow(show);
    if (content) setContent(content);
    if (primaryButton) setPrimaryButton({ ...primaryButton });
    if (secondaryButton) setSecondaryButton({ ...secondaryButton });
  }, [props]);

  return (
    <>
      <div className="popup">
        <Modal
          show={show}
          onHide={secondaryButton.onClick}
          backdropClassName="popup-backdrop"
          dialogClassName="popup"
          centered
        >
          {/* Header */}
          <Modal.Header>
            <Modal.Title>
              <span className="close-modal" onClick={secondaryButton.onClick}>
                <i className="fas fa-times"></i>
              </span>
            </Modal.Title>
          </Modal.Header>

          {/* Body */}
          <Modal.Body>
            <Container>
              <p>{ReactHtmlParser(content)}</p>
            </Container>
          </Modal.Body>

          {/* Footer */}
          <Modal.Footer>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={secondaryButton.onClick}
            >
              {secondaryButton.text}
            </Button>
            <Button variant="primary" size="sm" onClick={primaryButton.onClick}>
              {primaryButton.text}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

Popup.propTypes = {
  show: PropTypes.bool,
  content: PropTypes.string,
  primaryButton: PropTypes.object,
  secondaryButton: PropTypes.object,
};

Popup.propDefault = {
  show: false,
};

export default Popup;
