import { apiCall } from './base';

export const getUser = (hash) =>
  apiCall(`/user/${hash}`, {
    withCredentials: true,
    immediately: true,
  });

export const getUserRole = (hash) =>
  apiCall(`/user/${hash}/role`, {
    withCredentials: true,
    immediately: true,
  });

export const getAll = async (params) => {
  const result = await apiCall(`/user`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
    params,
  });
  return result.data;
};

export const getOne = async (code) => {
  const result = await apiCall(`/user/${code}`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
  });
  return result;
};

export const create = async (data) => {
  const result = await apiCall(`/user`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
    data,
  });
  return result;
};

export const update = async (data, code) => {
  const result = await apiCall(`/user/${code}`, {
    method: 'PUT',
    withCredentials: true,
    immediately: true,
    data,
  });
  return result;
};

export const remove = async (code) => {
  const result = await apiCall(`/user/${code}`, {
    method: 'DELETE',
    withCredentials: true,
    immediately: true,
  });
  return result;
};

export const onboardingDone = async (hash) => {
  const result = await apiCall(`/user/${hash}/onboarding/done`, {
    method: 'PUT',
    withCredentials: true,
    immediately: true,
  });
  return result;
};
