import React from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import Card from 'react-bootstrap/Card';
import AdminOrderItemColSkeleton from '../../components/adminOrderItemColSkeleton';

const DeliveryCardSkeleton = () => {
  return (
    <>
      <Card className="order-card">
        <Card.Body>
          {/* Order Header */}
          <div className="order-header d-flex align-items-center">
            <div className="">
              <Skeleton width={120} />
            </div>
          </div>

          {/* Order Info */}
          <div className="order-info-list">
            <div className="d-flex align-items-center">
              {/* Delivery Date */}
              <AdminOrderItemColSkeleton width={70} />

              {/* Delivery Time */}
              <AdminOrderItemColSkeleton width={70} />
            </div>
          </div>

          {/* Airport */}
          <div className="airport">
            {/* <i className="fas fa-plane"></i> */}
            <span>
              <Skeleton />
            </span>
          </div>

          {/* Terminal */}
          <div className="terminal">
            {/* <i className="fab fa-simplybuilt"></i> */}
            <span>
              <Skeleton />
            </span>
          </div>
        </Card.Body>

        {/* Footer */}
        <Card.Footer className="text-muted">
          <Link to="#" replace className="btn-icon primary-color">
            <Skeleton width={70} />
          </Link>
          <Link to="#" replace className="btn-icon primary-color">
            <Skeleton width={70} />
          </Link>
        </Card.Footer>
      </Card>
    </>
  );
};

export default DeliveryCardSkeleton;
