import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { exportToTop } from '../../helpers/utils';
import { isBrowser } from 'react-device-detect';
// import EmptyPage from '../../components/emptyPage';
// import DefaultAirportImage from '../../assets/images/my-flights.svg';
import ContactForm from '../../components/contactForm';
import { HeaderContainer } from '../../components/headerContainer';
import { FooterContainer } from '../../components/footerContainer';
import { useUser } from '../../hooks';

const ContactUsPage = () => {
  const { user } = useUser();

  const handleFormSubmit = (form) => {
    const { name, email, message } = form;
    const tfkEmail = 'customerservice@theflightkitchen.com';
    window.open(
      `mailto:${tfkEmail}?subject=Contact from ${name} ${email}&body=${message}`
    );
  };

  useEffect(() => {
    exportToTop();
  }, []);

  return (
    <>
      <HeaderContainer />
      <div
        className={
          user ? `main container container-fluid py-1` : `main container`
        }
      >
        <div className="page-container contact-us my-5 p-2">
          {(isBrowser || !user) && (
            <h1 className="page-title mb-5">Contact Us</h1>
          )}

          <Row>
            {/* Left Panel */}
            <Col xs={12} md={6}>
              <div>
                <h2>
                  <b>{`We'd love to hear from you`}</b>
                </h2>
                <p>
                  Whether you have a question about our menu items, deliveries,
                  pricing, or anything else, our team is ready to answer all
                  your questions
                </p>

                {/* Form */}
                <ContactForm onSubmit={handleFormSubmit} />
              </div>
            </Col>

            {/* Right Panel */}
            {isBrowser && (
              <Col xs={12} md={6}>
                {/* <EmptyPage image={DefaultAirportImage} /> */}
                {/* <h2>
                  <b>Support Contact</b>
                </h2>
                <p>You can call us at this number:</p>
                <h2>+1 980 242-1600</h2> */}
              </Col>
            )}
          </Row>
        </div>
      </div>
      <FooterContainer />
    </>
  );
};

export default ContactUsPage;
