import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const newLocal = false;
  const isLogged = () => newLocal;

  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={(props) =>
        isLogged() && restricted ? (
          <Redirect to="/signin" />
        ) : (
          <>
            <Component {...props} />
          </>
        )
      }
    />
  );
};

export default PublicRoute;
