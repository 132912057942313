import { apiCall } from './base';

export const getAll = async (params) => {
  const result = await apiCall(`/discount`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
    params,
  });
  return result.data;
};

export const getOne = async (code) => {
  const result = await apiCall(`/discount/${code}`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
  });
  return result;
};

export const create = async (data) => {
  const result = await apiCall(`/discount`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
    data,
  });
  return result;
};

export const update = async (data, code) => {
  const result = await apiCall(`/discount/${code}`, {
    method: 'PUT',
    withCredentials: true,
    immediately: true,
    data,
  });
  return result;
};

export const remove = async (code) => {
  const result = await apiCall(`/discount/${code}`, {
    method: 'DELETE',
    withCredentials: true,
    immediately: true,
  });
  return result;
};

export const validate = async (code) => {
  const result = await apiCall(`/discount/${code}/validate`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
  });
  return result;
};
