import React, { useEffect } from 'react';
import { exportToTop } from '../../helpers/utils';
import ProductDeck from '../../components/productDeck';
import { HeaderContainer } from '../../components/headerContainer';
import { FooterContainer } from '../../components/footerContainer';

const LandingMenuPage = () => {
  useEffect(() => {
    exportToTop();
  }, []);

  return (
    <>
      <HeaderContainer />
      <div className={`main container container-fluid my-0 landing-menu`}>
        <div className="page-container about-us my-5 p-0">
          <ProductDeck
            onlyFavorites={false}
            displayOnLanding={true}
            excludeCatering={true}
          />
        </div>
      </div>
      <FooterContainer />
    </>
  );
};

export default LandingMenuPage;
