import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

function FormSelect(props) {
  const { label, options, value, onChange } = props;

  return (
    <>
      <div className="select-element">
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => onChange(e.target.value)}
            value={value ? value : undefined}
          >
            <option
              value={''}
              key={`FormSelect_${0}`}
            >{`- Choose ${label} -`}</option>

            {options?.length &&
              options?.map((e) => {
                return (
                  <option key={`FormSelect_${e.id}`} value={e.id}>
                    {e.text}
                  </option>
                );
              })}
          </Form.Control>
        </Form.Group>
      </div>
    </>
  );
}

FormSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

export default FormSelect;
