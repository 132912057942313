import React, { useState, useMemo } from 'react';
import {
  getAll as getCategories,
  getOne as getOneCategory,
} from '../api/category';

export const FilterContext = React.createContext();

export const FilterProvider = (props) => {
  const [categories, setCategories] = useState([]);
  const [categorySelected, setCategorySelected] = useState('all');
  const [searchText, setSearchText] = useState('');
  const [searchHistory, setSearchHistory] = useState([]);
  const [showSearchModal, setShowSearchModal] = useState(false);

  const options = useMemo(() => {
    const onSeachTextSubmit = (text) => {
      setSearchText(text);
      setSearchHistory([...searchHistory, text]);
      // filterProducts()
    };

    const onSeachHistorySelected = (text) => {
      setSearchText(text);
      // filterProducts();
    };

    const onCategorySelected = (category) => {
      setCategorySelected(category);
      setSearchText('');
      // filterProducts();
    };

    const fetchCategories = async (params = []) => {
      try {
        let exclude;
        if (!params) exclude = [];
        if (params && Array.isArray(params)) {
          exclude = [...params];
        }
        if (params && !Array.isArray(params)) {
          exclude = [params];
        }

        const res = await getCategories({ exclude });
        const onlyActives = res.filter((e) => !!e.isActive);
        setCategories(onlyActives);
      } catch (error) {
        console.log(`error`, error);
      }
    };

    const fetchOneCategory = async (code) => {
      const response = await getOneCategory(code);
      return response;
    };

    const filterProducts = (params) => {
      const {
        productsCache,
        categorySelected,
        searchText,
        onlyFavorites,
        excludeCatering,
      } = params;
      let filtered = productsCache;

      if (categorySelected) {
        if (categorySelected !== 'all') {
          filtered = productsCache.filter(
            (e) => e.category.code === categorySelected
          );
        }
      }

      if (searchText) {
        filtered = filtered.filter((e) => {
          const lowSearchText = searchText.toLowerCase();
          const lowName = e.name.toLowerCase();
          const lowDesc = e.description.toLowerCase();

          if (
            lowName.includes(lowSearchText) ||
            lowDesc.includes(lowSearchText)
          ) {
            return e;
          }
        });
      }

      if (onlyFavorites) {
        filtered = filtered.filter((e) => e.isFavorite);
      }

      if (excludeCatering) {
        filtered = filtered.filter((e) => !e.category.parentId);
      }

      return filtered;
    };

    const isCategorySelectedCatering = () => categorySelected === 'catering';

    return {
      // Category Filter
      categories,
      categorySelected,
      onCategorySelected,
      setCategorySelected,
      isCategorySelectedCatering,

      // Search Filter
      searchHistory,
      searchText,
      setSearchText,
      onSeachTextSubmit,
      onSeachHistorySelected,
      showSearchModal,
      setShowSearchModal,

      // Filters
      filterProducts,

      //fetchs
      fetchCategories,
      fetchOneCategory,
    };
  }, [
    categories,
    categorySelected,
    searchHistory,
    searchText,
    showSearchModal,
  ]);

  return (
    <FilterContext.Provider
      value={options}
      {...props}
      displayName="FilterContext"
    />
  );
};
