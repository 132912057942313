import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { MobileView, BrowserView } from 'react-device-detect';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const CateringDeckSkeleton = () => {
  return (
    <>
      <div className="catering-deck">
        {/* Desktop View */}
        <BrowserView>
          <div className="page-container catering-deck-container desktop">
            <Row className="row-cols-1">
              {/* SubCategory Title */}
              <div className="catering-category">
                <Col>
                  <h1>
                    <Skeleton key={1} width={200} />
                  </h1>
                  <h2 className="text-black-50">
                    <Skeleton key={1} width={500} />
                  </h2>
                </Col>
              </div>
            </Row>

            {/* Products */}
            {[0, 1, 2, 3, 4, 5].map((e, k) => (
              <Skeleton
                {...e}
                key={`CateringProductSkeleton_${k}`}
                width={750}
                height={25}
                className="mt-3"
              />
            ))}

            <div className="mt-5"></div>

            <Row className="row-cols-1">
              {/* SubCategory Title */}
              <div className="catering-category">
                <Col>
                  <h1>
                    <Skeleton key={1} width={200} />
                  </h1>
                  <h2 className="text-black-50">
                    <Skeleton key={1} width={500} />
                  </h2>
                </Col>
              </div>
            </Row>

            {/* Products */}
            {[0, 1, 2, 3, 4, 5].map((e, k) => (
              <Skeleton
                {...e}
                key={`CateringProductSkeleton_${k}`}
                width={750}
                height={25}
                className="mt-3"
              />
            ))}
          </div>
        </BrowserView>

        {/* Mobile View */}
        <MobileView>
          <div className="">
            <Row className="row-cols-1">
              {/* SubCategory Title */}
              <div className="catering-category">
                <Col>
                  <h1>
                    <Skeleton key={1} width={150} />
                  </h1>
                  <h2 className="text-black-50">
                    <Skeleton key={1} width={250} />
                  </h2>
                </Col>
              </div>
            </Row>

            {/* Products */}
            {[0, 1, 2, 3, 4, 5].map((e, k) => (
              <Skeleton
                {...e}
                key={`CateringProductSkeleton_${k}`}
                width={350}
                height={25}
                className="mt-3"
              />
            ))}

            <div className="mt-5"></div>

            <Row className="row-cols-1">
              {/* SubCategory Title */}
              <div className="catering-category">
                <Col>
                  <h1>
                    <Skeleton key={1} width={150} />
                  </h1>
                  <h2 className="text-black-50">
                    <Skeleton key={1} width={250} />
                  </h2>
                </Col>
              </div>
            </Row>

            {/* Products */}
            {[0, 1, 2, 3, 4, 5].map((e, k) => (
              <Skeleton
                {...e}
                key={`CateringProductSkeleton_${k}`}
                width={350}
                height={25}
                className="mt-3"
              />
            ))}
          </div>
        </MobileView>
      </div>
    </>
  );
};

export default CateringDeckSkeleton;
