import React from 'react';
import { useToasts } from 'react-toast-notifications';

export const NotificationContext = React.createContext();

export const NotificationProvider = (props) => {
  const { addToast } = useToasts();

  const options = {
    error: (options) => addToast(options.message, { appearance: 'error' }),
    info: (options) => addToast(options.message, { appearance: 'info' }),
    success: (options) => addToast(options.message, { appearance: 'success' }),
    warning: (options) => addToast(options.message, { appearance: 'warning' }),
  };

  return (
    <NotificationContext.Provider
      value={options}
      {...props}
      displayName="NotificationContext"
    >
      {
        // eslint-disable-next-line react/prop-types
        props.children
      }
    </NotificationContext.Provider>
  );
};
