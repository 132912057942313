import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { statusKey } from '../../constants/status';

const StatusBadge = (props) => {
  const { code, name } = props;

  const statusBadge = classNames({
    badge: true,
    'text-uppercase': true,
    'badge-blue': code === statusKey.SCHEDULED,
    'badge-red':
      code === statusKey.CANCELLED ||
      code === statusKey.REJECTED ||
      code === statusKey.NOT_DELIVERED,
    'badge-green': code === statusKey.DELIVERED,
    'badge-orange': code === statusKey.PREPARED,
  });

  return <span className={`${statusBadge}`}>{name}</span>;
};

StatusBadge.propTypes = {
  code: PropTypes.string,
  name: PropTypes.string,
};

export default StatusBadge;
