import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function DiscountCodeItem(params) {
  const { code, percentage, assignment, endDate, isActive } = params;
  const { actions: { updateStatus, remove, showForm } = {} } = params;

  const handleEdit = () => {
    showForm({ code, percentage, assignment, endDate, isActive });
    // edit({ code, percentage, assignment, endDate, isActive });
  };

  const handleActiveInactive = () => {
    updateStatus({ code, percentage, assignment, endDate, isActive });
  };

  const handleDelete = () => {
    remove(code);
  };

  return (
    <>
      <div className="d-flex admin-discount-code-items">
        {/* Code */}
        <span>{code}</span>

        {/* Percentage */}
        <span>{`${percentage}%`}</span>

        {/* Assignment */}
        <span>{assignment || 'Everyone'}</span>

        {/* End Date */}
        <span>{endDate}</span>

        {/* Actions - Edit */}
        <span className="actions">
          <Link to="#" replace className="search-clear" onClick={handleEdit}>
            <i className="fas fa-edit"></i>
          </Link>
        </span>

        {/* Actions - Active/Inactive */}
        <span className="actions">
          <Link
            to="#"
            replace
            className="search-clear"
            onClick={handleActiveInactive}
          >
            {isActive ? (
              <i className="fas fa-toggle-on on"></i>
            ) : (
              <i className="fas fa-toggle-off off"></i>
            )}
          </Link>
        </span>

        {/* Actions - Delete */}
        <span className="actions">
          <Link to="#" replace className="search-clear" onClick={handleDelete}>
            <i className="fas fa-trash-alt"></i>
          </Link>
        </span>
      </div>
    </>
  );
}

DiscountCodeItem.propTypes = {
  code: PropTypes.string,
  percentage: PropTypes.number,
  assignment: PropTypes.string,
  endDate: PropTypes.string,
  isActive: PropTypes.bool,
  actions: PropTypes.object,
};

export default DiscountCodeItem;
