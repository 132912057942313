import { createServer } from 'miragejs';
import { API_URL } from '../../config';
import {
  breakfastAllAmerican,
  breakfastFrenchToast,
  breakfastFarmers,
  breakfastGranola,
  breakfastSandwich,
  saladsChickenCaesar,
  saladsGardenDeluxe,
  saladsGreek,
} from './images';

export default function () {
  const baseUrl = API_URL;
  // const uuidRegex = /[0-9a-fA-F-]{36}/;

  return createServer({
    routes() {
      // POST /user/login
      this.post(`${baseUrl}/auth/sign-in`, () => ({
        hash: 'fdee1634-42d2-4032-878d-46632bc468e8',
        firstName: 'customer',
        lastName: 'customer',
        email: 'customer@mail.com',
        birthDate: null,
        phone: null,
        isOnboardingDone: false,
      }));

      // GET /user/:hash
      this.get(`${baseUrl}/user/:hash/role`, () => ({
        role: {
          code: 'customer',
          name: 'Customer',
          menu: [
            {
              code: 'menu',
              name: 'Menu',
              icon: 'fas fa-home',
              orderMobile: 1,
              orderDesktop: 1,
              childrens: [],
            },
            {
              code: 'order',
              name: 'My Orders',
              icon: 'fas fa-list',
              orderMobile: 2,
              orderDesktop: 2,
              childrens: [],
            },
            {
              code: 'flight',
              name: 'My Flights',
              icon: 'fas fa-plane',
              orderMobile: 3,
              orderDesktop: 4,
              childrens: [],
            },
            {
              code: 'cart',
              name: 'My Cart',
              icon: 'fas fa-shopping-cart',
              orderMobile: 4,
              orderDesktop: null,
              childrens: [],
            },
            {
              code: 'fav',
              name: 'My Favorites',
              icon: 'fas fa-heart',
              orderMobile: 5,
              orderDesktop: 3,
              childrens: [],
            },
            {
              code: 'profile',
              name: 'My Profile',
              icon: 'fas fa-user-circle',
              orderMobile: 6,
              orderDesktop: 101,
              childrens: [
                {
                  code: 'personal_info',
                  name: 'Personal Information',
                  icon: null,
                  orderMobile: 1,
                  orderDesktop: 1,
                },
                {
                  code: 'payment_method',
                  name: 'Payment Methods',
                  icon: null,
                  orderMobile: 2,
                  orderDesktop: 2,
                },
                {
                  code: 'default_airport',
                  name: 'Defatul Airport',
                  icon: null,
                  orderMobile: 3,
                  orderDesktop: 3,
                },
              ],
            },
            {
              code: 'faq',
              name: 'FAQs',
              icon: 'fas fa-question-circle',
              orderMobile: 7,
              orderDesktop: 102,
              childrens: [],
            },
          ],
        },
      }));

      // GET /products
      this.get(`${baseUrl}/products`, () => [
        {
          hash: 'f165595e-6ef0-440f-ab63-af40e7e20467',
          name: 'Breakfast Sandwich',
          description: 'Scrambled eggs, sliced ham, tomato and green onion.',
          price: 10.85,
          rating: 3,
          grams: 100,
          calories: 250,
          isFavorite: false,
          image: breakfastSandwich,
          category: { code: 'breakfast', name: 'Breakfast', order: null },
          ingredients: [
            { code: 'olive-oil', name: 'Olive oil' },
            { code: 'vinegar', name: 'Vinegar' },
            { code: 'salt', name: 'Salt' },
            { code: 'pepper', name: 'Pepper' },
          ],
        },
        {
          hash: 'cb4244f5-10c8-49ac-9168-8985f0d79c81',
          name: 'White chocolate Brioche French Toast',
          description: 'Berry Compote, Whipped Mascarpone.',
          price: 11.25,
          rating: 3,
          grams: 200,
          calories: 150,
          isFavorite: true,
          image: breakfastFrenchToast,
          category: { code: 'breakfast', name: 'Breakfast', order: null },
          ingredients: [
            { code: 'olive-oil', name: 'Olive oil' },
            { code: 'vinegar', name: 'Vinegar' },
            { code: 'salt', name: 'Salt' },
            { code: 'pepper', name: 'Pepper' },
          ],
        },
        {
          hash: '9daa412b-2fd6-4769-97e1-2371e2ba4001',
          name: 'All American Breakfast',
          description:
            'One fried egg, one omelet, bacon, short stack and coffee.',
          price: 7.99,
          rating: 1,
          grams: 300,
          calories: 50,
          isFavorite: false,
          image: breakfastAllAmerican,
          category: { code: 'breakfast', name: 'Breakfast', order: null },
          ingredients: [
            { code: 'olive-oil', name: 'Olive oil' },
            { code: 'vinegar', name: 'Vinegar' },
            { code: 'salt', name: 'Salt' },
            { code: 'pepper', name: 'Pepper' },
          ],
        },
        {
          hash: '01383cc8-0660-47ad-8afc-4e51db23ca2d',
          name: 'Farmer’s Favorite Steak & Eggs',
          description: 'Grilled 8oz ny strip, two fried eggs.',
          price: 15.5,
          rating: 4,
          grams: 400,
          calories: 10,
          image: breakfastFarmers,
          category: { code: 'breakfast', name: 'Breakfast', order: null },
          ingredients: [
            { code: 'olive-oil', name: 'Olive oil' },
            { code: 'vinegar', name: 'Vinegar' },
            { code: 'salt', name: 'Salt' },
            { code: 'pepper', name: 'Pepper' },
          ],
        },
        {
          hash: '20fd7523-1153-403f-a1bc-c07c622d6d84',
          name: 'Granola Parfait',
          description: 'Berries and greek yogurt.',
          price: 5.49,
          rating: 2,
          grams: 50,
          calories: 350,
          image: breakfastGranola,
          category: { code: 'breakfast', name: 'Breakfast', order: null },
          ingredients: [
            { code: 'olive-oil', name: 'Olive oil' },
            { code: 'vinegar', name: 'Vinegar' },
            { code: 'salt', name: 'Salt' },
            { code: 'pepper', name: 'Pepper' },
          ],
        },
        {
          hash: '5d8ea98f-8b65-4116-ab30-8684fb188718',
          name: 'Chicken Caesar',
          description:
            'Iceberg, crispy chicken slices, homemade baked croutons, chopped eggs and parmesan cheese.',
          price: 8.99,
          rating: 2,
          grams: 150,
          calories: 550,
          image: saladsChickenCaesar,
          isFavorite: true,
          category: { code: 'salads', name: 'Salads', order: 2 },
          ingredients: [],
        },
        {
          hash: '1f1eee15-67ea-412c-8340-42f18e1411fe',
          name: 'Garden Deluxe',
          description:
            'Iceberg, sundried tomatoes, shredded carrots, cucumbers and sliced mushrooms.',
          price: 6.99,
          rating: 4,
          grams: 170,
          calories: 320,
          image: saladsGardenDeluxe,
          isFavorite: true,
          category: { code: 'salads', name: 'Salads', order: 2 },
          ingredients: [],
        },
        {
          hash: 'b8cd5782-4eb3-4113-baf1-d169bab9826b',
          name: 'Greek',
          description:
            'Iceberg, grape tomatoes, cucumbers, feta cheese and nicoise olives',
          price: 7.99,
          rating: 3,
          grams: 250,
          calories: 150,
          image: saladsGreek,
          isFavorite: false,
          category: { code: 'salads', name: 'Salads', order: 2 },
          ingredients: [],
        },
      ]);

      // GET /products/categories
      this.get(`${baseUrl}/products/categories`, () => [
        {
          code: 'breakfast',
          name: 'Breakfast',
          order: 1,
        },
        {
          code: 'salads',
          name: 'Salads',
          order: 2,
        },
        {
          code: 'sandwiches',
          name: 'Sandwiches',
          order: 3,
        },
        {
          code: 'wraps',
          name: 'Wraps',
          order: 4,
        },
        {
          code: 'snacks',
          name: 'Snacks',
          order: 5,
        },
        {
          code: 'kids',
          name: "Kid's Menu",
          order: 6,
        },
      ]);

      // POST /customer/:hash/favorites/:hash
      this.post(`${baseUrl}/customers/:hash/favorites/:phash`, () => ({}));

      // DELETE /customer/:hash/favorites/:hash
      this.delete(`${baseUrl}/customers/:hash/favorites/:phash`, () => ({}));

      // GET /cart
      this.get(`${baseUrl}/cart`, () => ({
        hash: 'd39ab285-be80-4ee3-87e2-31e562e7f673',
        rev: 7,
        isActive: true,
        createdAt: '2021-07-22T14:44:38.000Z',
        updatedAt: '2021-07-22T14:44:38.000Z',
        discountCodeId: null,
        customerId: 1,
        products: [
          {
            id: 2,
            hash: '560cc3a1-4fff-43b8-89c1-57645923dc51',
            name: 'White chocolate Brioche French Toast',
            description: 'Berry Compote, Whipped Mascarpone.',
            rating: 3,
            grams: 200,
            calories: 150,
            image: 'http://localhost:4000/public/breakfast/french-toast.png',
            cartQuantity: 2,
            cartPrice: 11.25,
            cartProductIngredientHash: 'aa9f8a0e-f69a-41b2-96c3-82184446be6a',
            ingredients: [
              { isIncluded: true, code: 'pepper', name: 'Pepper' },
              { isIncluded: true, code: 'salt', name: 'Salt' },
              {
                isIncluded: true,
                code: 'sliced-mushrooms',
                name: 'Sliced mushrooms',
              },
              {
                isIncluded: false,
                code: 'sundried-tomatoes',
                name: 'Sundried tomatoes',
              },
              { isIncluded: false, code: 'vinegar', name: 'Vinegar' },
            ],
          },
          {
            id: 1,
            hash: '762fd2f7-ac95-497a-8c5e-a93d16af6b81',
            name: 'Breakfast Sandwich',
            description: 'Scrambled eggs, sliced ham, tomato and green onion.',
            rating: 3,
            grams: 100,
            calories: 250,
            image: 'http://localhost:4000/public/breakfast/sandwich.png',
            cartQuantity: 1,
            cartPrice: 10.85,
            cartProductIngredientHash: '0594771a-2ad0-4b8c-8b56-78a7f10f7ad3',
            ingredients: [
              { isIncluded: true, code: 'olive-oil', name: 'Olive oil' },
              { isIncluded: true, code: 'pepper', name: 'Pepper' },
              { isIncluded: true, code: 'salt', name: 'Salt' },
              { isIncluded: false, code: 'vinegar', name: 'Vinegar' },
            ],
          },
          {
            id: 3,
            hash: 'b7679d5d-b38d-4958-bdf6-92b59f3198d2',
            name: 'All American Breakfast',
            description:
              'One fried egg, one omelet, bacon, short stack and coffee.',
            rating: 1,
            grams: 300,
            calories: 50,
            image: 'http://localhost:4000/public/breakfast/all-american.png',
            cartQuantity: 3,
            cartPrice: 7.99,
            cartProductIngredientHash: '7df79b0d-9283-4091-a4aa-d3458dce9ad5',
            ingredients: [
              { isIncluded: true, code: 'cucumbers', name: 'Cucumbers' },
              { isIncluded: true, code: 'olive-oil', name: 'Olive oil' },
              { isIncluded: true, code: 'pepper', name: 'Pepper' },
              { isIncluded: true, code: 'salt', name: 'Salt' },
              {
                isIncluded: true,
                code: 'sliced-mushrooms',
                name: 'Sliced mushrooms',
              },
              {
                isIncluded: true,
                code: 'sundried-tomatoes',
                name: 'Sundried tomatoes',
              },
            ],
          },
        ],
      }));

      // POST /cart/:hash
      this.post(`${baseUrl}/cart/item/:hash`, () => ({
        hash: 'd39ab285-be80-4ee3-87e2-31e562e7f673',
        rev: 7,
        isActive: true,
        createdAt: '2021-07-22T14:44:38.000Z',
        updatedAt: '2021-07-22T14:44:38.000Z',
        discountCodeId: null,
        customerId: 1,
        products: [
          {
            id: 2,
            hash: '560cc3a1-4fff-43b8-89c1-57645923dc51',
            name: 'White chocolate Brioche French Toast',
            description: 'Berry Compote, Whipped Mascarpone.',
            rating: 3,
            grams: 200,
            calories: 150,
            image: 'http://localhost:4000/public/breakfast/french-toast.png',
            cartQuantity: 2,
            cartPrice: 11.25,
            cartProductIngredientHash: 'aa9f8a0e-f69a-41b2-96c3-82184446be6a',
            ingredients: [
              { isIncluded: true, code: 'pepper', name: 'Pepper' },
              { isIncluded: true, code: 'salt', name: 'Salt' },
              {
                isIncluded: true,
                code: 'sliced-mushrooms',
                name: 'Sliced mushrooms',
              },
              {
                isIncluded: false,
                code: 'sundried-tomatoes',
                name: 'Sundried tomatoes',
              },
              { isIncluded: false, code: 'vinegar', name: 'Vinegar' },
            ],
          },
          {
            id: 1,
            hash: '762fd2f7-ac95-497a-8c5e-a93d16af6b81',
            name: 'Breakfast Sandwich',
            description: 'Scrambled eggs, sliced ham, tomato and green onion.',
            rating: 3,
            grams: 100,
            calories: 250,
            image: 'http://localhost:4000/public/breakfast/sandwich.png',
            cartQuantity: 1,
            cartPrice: 10.85,
            cartProductIngredientHash: '0594771a-2ad0-4b8c-8b56-78a7f10f7ad3',
            ingredients: [
              { isIncluded: true, code: 'olive-oil', name: 'Olive oil' },
              { isIncluded: true, code: 'pepper', name: 'Pepper' },
              { isIncluded: true, code: 'salt', name: 'Salt' },
              { isIncluded: false, code: 'vinegar', name: 'Vinegar' },
            ],
          },
          {
            id: 3,
            hash: 'b7679d5d-b38d-4958-bdf6-92b59f3198d2',
            name: 'All American Breakfast',
            description:
              'One fried egg, one omelet, bacon, short stack and coffee.',
            rating: 1,
            grams: 300,
            calories: 50,
            image: 'http://localhost:4000/public/breakfast/all-american.png',
            cartQuantity: 1,
            cartPrice: 7.99,
            cartProductIngredientHash: '8cd6fb36-4e5e-4233-8502-531a9dc7b988',
            ingredients: [
              { isIncluded: true, code: 'cucumbers', name: 'Cucumbers' },
              { isIncluded: true, code: 'olive-oil', name: 'Olive oil' },
              { isIncluded: true, code: 'pepper', name: 'Pepper' },
              { isIncluded: true, code: 'salt', name: 'Salt' },
              {
                isIncluded: true,
                code: 'sliced-mushrooms',
                name: 'Sliced mushrooms',
              },
              {
                isIncluded: true,
                code: 'sundried-tomatoes',
                name: 'Sundried tomatoes',
              },
            ],
          },
        ],
      }));

      // GET /airport
      this.get(`${baseUrl}/airport`, () => [
        {
          id: 1,
          code: 'CLT',
          name: 'Charlotte-Douglas International Airport 11',
          tax: 7,
          order: 1,
          createdAt: '2021-08-06T14:11:11.000Z',
          updatedAt: '2021-08-06T14:11:11.000Z',
          deletedAt: null,
          terminals: [
            {
              id: 1,
              code: 'CTL-WFBO',
              name: 'Wilson Fixed Base Operator',
              allowsProductIngredients: null,
              createdAt: '2021-08-06T14:11:40.000Z',
              updatedAt: '2021-08-06T14:11:40.000Z',
              deletedAt: null,
              airportId: 1,
            },
            {
              id: 2,
              code: 'CTL-COMM',
              name: 'Commercial Terminal',
              allowsProductIngredients: null,
              createdAt: '2021-08-06T14:11:40.000Z',
              updatedAt: '2021-08-06T14:11:40.000Z',
              deletedAt: null,
              airportId: 1,
            },
          ],
        },
        {
          id: 2,
          code: 'USA',
          name: 'Concord-Padgett Regional Aiport 22',
          tax: 7,
          order: 2,
          createdAt: '2021-08-06T14:11:11.000Z',
          updatedAt: '2021-08-06T14:11:11.000Z',
          deletedAt: null,
          terminals: [
            {
              id: 3,
              code: 'USA-FBO',
              name: 'Commercial Terminal',
              allowsProductIngredients: null,
              createdAt: '2021-08-06T14:11:40.000Z',
              updatedAt: '2021-08-06T14:11:40.000Z',
              deletedAt: null,
              airportId: 2,
            },
          ],
        },
      ]);

      // GET /customers/credit-card
      this.get(`${baseUrl}/customers/credit-card`, () => [
        {
          id: 2,
          cardToken: 'pm_1JLEx6HfwN7GQz7iDwk7nY4J',
          brand: 'visa',
          expMonth: 12,
          expYear: 2022,
          funding: 'credit',
          last4: '1111',
          country: 'US',
          createdAt: '2021-08-05T22:22:14.000Z',
          updatedAt: '2021-08-05T22:22:14.000Z',
          deletedAt: null,
          customerId: 1,
        },
        {
          id: 3,
          cardToken: 'pm_1JLFyIHfwN7GQz7iGGse6sfX',
          brand: 'visa',
          expMonth: 11,
          expYear: 2022,
          funding: 'credit',
          last4: '4242',
          country: 'US',
          createdAt: '2021-08-05T23:27:46.000Z',
          updatedAt: '2021-08-05T23:27:46.000Z',
          deletedAt: null,
          customerId: 1,
        },
        {
          id: 4,
          cardToken: 'pm_1JLSfLHfwN7GQz7iYBGvbnBN',
          brand: 'visa',
          expMonth: 11,
          expYear: 2022,
          funding: 'credit',
          last4: '1111',
          country: 'US',
          createdAt: '2021-08-06T13:00:49.000Z',
          updatedAt: '2021-08-06T13:00:49.000Z',
          deletedAt: null,
          customerId: 1,
        },
      ]);

      // GET /order/:id
      this.get(`${baseUrl}/order/:id`, () => ({
        id: 1,
        code: '7L257',
        subtotal: 11.25,
        tax: 0.79,
        discount: 0,
        total: 12.04,
        flightDate: '2021-08-25',
        flightTime: '',
        deliveryTime: '00:10 AM',
        flightCode: '',
        cartHash: '7137267b-96bf-4c6b-b05a-022171f85d40',
        cartRev: 1,
        createdAt: '2021-08-25T15:00:39.000Z',
        airport: {
          id: 1,
          code: 'CLT',
          name: 'Charlotte-Douglas International Airport',
        },
        airportTerminal: {
          id: 2,
          code: 'CTL-COMM',
          name: 'Commercial Terminal',
        },
        orderStatus: { id: 1, code: 'SCHEDULED', name: 'Scheduled' },
        customer: {
          id: 1,
          user: {
            id: 1,
            firstName: 'customer',
            lastName: 'customer',
            email: 'customer@mail.com',
            phone: null,
          },
        },
        cart: {
          hash: '7137267b-96bf-4c6b-b05a-022171f85d40',
          rev: 1,
          isActive: false,
          createdAt: '2021-08-24T23:12:12.000Z',
          updatedAt: '2021-08-25T15:00:27.000Z',
          discountCodeId: null,
          customerId: 1,
          products: [
            {
              id: 2,
              hash: 'e9a3b4a2-78cb-4580-bd57-e67ccab154ce',
              name: 'White chocolate Brioche French Toast',
              description: 'Berry Compote, Whipped Mascarpone.',
              rating: 3,
              grams: 200,
              calories: 150,
              image: 'http://localhost:4000/public/breakfast/french-toast.png',
              cartQuantity: 1,
              cartPrice: 11.25,
              cartProductIngredientHash: '63f6d813-3755-418a-b054-ed185ae574aa',
              ingredients: [
                { isIncluded: true, code: 'pepper', name: 'Pepper' },
                { isIncluded: true, code: 'salt', name: 'Salt' },
                {
                  isIncluded: true,
                  code: 'sliced-mushrooms',
                  name: 'Sliced mushrooms',
                },
                {
                  isIncluded: true,
                  code: 'sundried-tomatoes',
                  name: 'Sundried tomatoes',
                },
                { isIncluded: true, code: 'vinegar', name: 'Vinegar' },
              ],
            },
          ],
        },
      }));

      // GET /order/status
      this.get(`${baseUrl}/order/status`, () => [
        {
          code: 'SCHEDULED',
          name: 'Scheduled',
          order: 1,
        },
        {
          code: 'DELIVERED',
          name: 'delivered',
          order: 3,
        },
        {
          code: 'CANCELLED',
          name: 'Cancelled',
          order: 2,
        },
      ]);

      // GET /order/status/:status
      this.get(
        `${baseUrl}/order/status/:status`,
        () => [
          {
            id: 1,
            code: '7L257',
            subtotal: 11.25,
            tax: 0.79,
            discount: 0,
            total: 12.04,
            flightDate: '2021-08-25',
            flightTime: '',
            deliveryTime: '00:10 AM',
            flightCode: '',
            externalId: 'pi_3JSNaRHfwN7GQz7i1qkK7grV',
            cartHash: '7137267b-96bf-4c6b-b05a-022171f85d40',
            cartRev: 1,
            createdAt: '2021-08-25T15:00:39.000Z',
            updatedAt: '2021-08-25T15:00:39.000Z',
            deletedAt: null,
            customerId: 1,
            customerCardId: 1,
            discountCodeId: null,
            airportId: 1,
            airportTerminalId: 2,
            orderStatusId: 1,
            airport: {
              id: 1,
              code: 'CLT',
              name: 'Charlotte-Douglas International Airport',
              tax: 7,
              order: 1,
              createdAt: '2021-08-24T23:11:44.000Z',
              updatedAt: '2021-08-24T23:11:44.000Z',
              deletedAt: null,
            },
            airportTerminal: {
              id: 2,
              code: 'CTL-COMM',
              name: 'Commercial Terminal',
              allowsProductIngredients: null,
              createdAt: '2021-08-24T23:11:44.000Z',
              updatedAt: '2021-08-24T23:11:44.000Z',
              deletedAt: null,
              airportId: 1,
            },
            orderStatus: {
              id: 1,
              code: 'SCHEDULED',
              name: 'Scheduled',
              order: 1,
              createdAt: '2021-08-24T23:11:44.000Z',
              updatedAt: '2021-08-24T23:11:44.000Z',
              deletedAt: null,
            },
          },
        ],
        [
          {
            id: 1,
            code: '7L257',
            subtotal: 11.25,
            tax: 0.79,
            discount: 0,
            total: 12.04,
            flightDate: '2021-08-25',
            flightTime: '',
            deliveryTime: '00:10 AM',
            flightCode: '',
            externalId: 'pi_3JSNaRHfwN7GQz7i1qkK7grV',
            cartHash: '7137267b-96bf-4c6b-b05a-022171f85d40',
            cartRev: 1,
            createdAt: '2021-08-25T15:00:39.000Z',
            updatedAt: '2021-08-25T15:00:39.000Z',
            deletedAt: null,
            customerId: 1,
            customerCardId: 1,
            discountCodeId: null,
            airportId: 1,
            airportTerminalId: 2,
            orderStatusId: 1,
            airport: {
              id: 1,
              code: 'CLT',
              name: 'Charlotte-Douglas International Airport',
              tax: 7,
              order: 1,
              createdAt: '2021-08-24T23:11:44.000Z',
              updatedAt: '2021-08-24T23:11:44.000Z',
              deletedAt: null,
            },
            airportTerminal: {
              id: 2,
              code: 'CTL-COMM',
              name: 'Commercial Terminal',
              allowsProductIngredients: null,
              createdAt: '2021-08-24T23:11:44.000Z',
              updatedAt: '2021-08-24T23:11:44.000Z',
              deletedAt: null,
              airportId: 1,
            },
            orderStatus: {
              id: 1,
              code: 'SCHEDULED',
              name: 'Scheduled',
              order: 1,
              createdAt: '2021-08-24T23:11:44.000Z',
              updatedAt: '2021-08-24T23:11:44.000Z',
              deletedAt: null,
            },
          },
        ]
      );

      // GET /order/status/:status/full
      this.get(`${baseUrl}/order/status/:status/full`, () => [
        {
          id: 1,
          code: '7L257',
          subtotal: 11.25,
          tax: 0.79,
          discount: 0,
          total: 12.04,
          flightDate: '2021-08-25',
          flightTime: '',
          deliveryTime: '00:10 AM',
          flightCode: '',
          externalId: 'pi_3JSNaRHfwN7GQz7i1qkK7grV',
          cartHash: '7137267b-96bf-4c6b-b05a-022171f85d40',
          cartRev: 1,
          createdAt: '2021-08-25T15:00:39.000Z',
          updatedAt: '2021-08-25T15:00:39.000Z',
          deletedAt: null,
          customerId: 1,
          customerCardId: 1,
          discountCodeId: null,
          airportId: 1,
          airportTerminalId: 2,
          orderStatusId: 1,
          airport: {
            id: 1,
            code: 'CLT',
            name: 'Charlotte-Douglas International Airport',
            tax: 7,
            order: 1,
            createdAt: '2021-08-24T23:11:44.000Z',
            updatedAt: '2021-08-24T23:11:44.000Z',
            deletedAt: null,
          },
          airportTerminal: {
            id: 2,
            code: 'CTL-COMM',
            name: 'Commercial Terminal',
            allowsProductIngredients: null,
            createdAt: '2021-08-24T23:11:44.000Z',
            updatedAt: '2021-08-24T23:11:44.000Z',
            deletedAt: null,
            airportId: 1,
          },
          orderStatus: {
            id: 1,
            code: 'SCHEDULED',
            name: 'Scheduled',
            order: 1,
            createdAt: '2021-08-24T23:11:44.000Z',
            updatedAt: '2021-08-24T23:11:44.000Z',
            deletedAt: null,
          },
          cart: {
            hash: '7137267b-96bf-4c6b-b05a-022171f85d40',
            rev: 1,
            isActive: false,
            createdAt: '2021-08-24T23:12:12.000Z',
            updatedAt: '2021-08-25T15:00:27.000Z',
            discountCodeId: null,
            customerId: 1,
            products: [
              {
                id: 2,
                hash: 'e9a3b4a2-78cb-4580-bd57-e67ccab154ce',
                name: 'White chocolate Brioche French Toast',
                description: 'Berry Compote, Whipped Mascarpone.',
                rating: 3,
                grams: 200,
                calories: 150,
                image:
                  'http://localhost:4000/public/breakfast/french-toast.png',
                cartQuantity: 1,
                cartPrice: 11.25,
                cartProductIngredientHash:
                  '63f6d813-3755-418a-b054-ed185ae574aa',
                ingredients: [
                  { isIncluded: true, code: 'pepper', name: 'Pepper' },
                  { isIncluded: true, code: 'salt', name: 'Salt' },
                  {
                    isIncluded: true,
                    code: 'sliced-mushrooms',
                    name: 'Sliced mushrooms',
                  },
                  {
                    isIncluded: true,
                    code: 'sundried-tomatoes',
                    name: 'Sundried tomatoes',
                  },
                  { isIncluded: true, code: 'vinegar', name: 'Vinegar' },
                ],
              },
            ],
          },
        },
        {
          id: 1,
          code: '7L257',
          subtotal: 11.25,
          tax: 0.79,
          discount: 0,
          total: 12.04,
          flightDate: '2021-08-25',
          flightTime: '',
          deliveryTime: '00:10 AM',
          flightCode: '',
          externalId: 'pi_3JSNaRHfwN7GQz7i1qkK7grV',
          cartHash: '7137267b-96bf-4c6b-b05a-022171f85d40',
          cartRev: 1,
          createdAt: '2021-08-25T15:00:39.000Z',
          updatedAt: '2021-08-25T15:00:39.000Z',
          deletedAt: null,
          customerId: 1,
          customerCardId: 1,
          discountCodeId: null,
          airportId: 1,
          airportTerminalId: 2,
          orderStatusId: 1,
          airport: {
            id: 1,
            code: 'CLT',
            name: 'Charlotte-Douglas International Airport',
            tax: 7,
            order: 1,
            createdAt: '2021-08-24T23:11:44.000Z',
            updatedAt: '2021-08-24T23:11:44.000Z',
            deletedAt: null,
          },
          airportTerminal: {
            id: 2,
            code: 'CTL-COMM',
            name: 'Commercial Terminal',
            allowsProductIngredients: null,
            createdAt: '2021-08-24T23:11:44.000Z',
            updatedAt: '2021-08-24T23:11:44.000Z',
            deletedAt: null,
            airportId: 1,
          },
          orderStatus: {
            id: 1,
            code: 'SCHEDULED',
            name: 'Scheduled',
            order: 1,
            createdAt: '2021-08-24T23:11:44.000Z',
            updatedAt: '2021-08-24T23:11:44.000Z',
          },
          cart: {
            hash: '7137267b-96bf-4c6b-b05a-022171f85d40',
            rev: 1,
            isActive: false,
            createdAt: '2021-08-24T23:12:12.000Z',
            updatedAt: '2021-08-25T15:00:27.000Z',
            discountCodeId: null,
            customerId: 1,
            products: [
              {
                id: 2,
                hash: 'e9a3b4a2-78cb-4580-bd57-e67ccab154ce',
                name: 'White chocolate Brioche French Toast',
                description: 'Berry Compote, Whipped Mascarpone.',
                rating: 3,
                grams: 200,
                calories: 150,
                image:
                  'http://localhost:4000/public/breakfast/french-toast.png',
                cartQuantity: 1,
                cartPrice: 11.25,
                cartProductIngredientHash:
                  '63f6d813-3755-418a-b054-ed185ae574aa',
                ingredients: [
                  { isIncluded: true, code: 'pepper', name: 'Pepper' },
                  { isIncluded: true, code: 'salt', name: 'Salt' },
                  {
                    isIncluded: true,
                    code: 'sliced-mushrooms',
                    name: 'Sliced mushrooms',
                  },
                  {
                    isIncluded: true,
                    code: 'sundried-tomatoes',
                    name: 'Sundried tomatoes',
                  },
                  { isIncluded: true, code: 'vinegar', name: 'Vinegar' },
                ],
              },
            ],
          },
        },
      ]);
    },
  });
}
