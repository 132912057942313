import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Controller, useForm } from 'react-hook-form';

const AdminUserForm = ({
  firstName,
  lastName,
  email,
  role,
  roles,
  onSubmit,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    // setValue,
  } = useForm();

  return (
    <>
      <div className="create-code-form mt-4">
        <Form noValidate id="admin-user-form" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12}>
              {/* First Name */}
              <Controller
                name="firstName"
                control={control}
                defaultValue={firstName}
                rules={{ required: true }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="firstName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control {...field} isInvalid={!!errors.firstName} />
                      <Form.Control.Feedback type="invalid">
                        {!!errors.firstName &&
                          errors.firstName.type === 'required' &&
                          'First Name is required'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />
            </Col>

            <Col xs={12}>
              {/* Last Name */}
              <Controller
                name="lastName"
                control={control}
                defaultValue={lastName}
                rules={{ required: true }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="lastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control {...field} isInvalid={!!errors.lastName} />
                      <Form.Control.Feedback type="invalid">
                        {!!errors.lastName &&
                          errors.lastName.type === 'required' &&
                          'Last Name is required'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />
            </Col>

            {/* Email */}
            <Col xs={12}>
              <Controller
                name="email"
                control={control}
                defaultValue={email}
                rules={{
                  required: true,
                  pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        {...field}
                        isInvalid={!!errors.email}
                        type="email"
                      />
                      <Form.Control.Feedback type="invalid">
                        {!!errors.email &&
                          errors.email.type === 'required' &&
                          'Email is required'}
                        {!!errors.email &&
                          errors.email.type === 'pattern' &&
                          'Email is invalid'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />
            </Col>

            <Col xs={12}>
              {/* Role */}
              <Controller
                name="role"
                control={control}
                defaultValue={role.id || 0}
                rules={{ required: true }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="role">
                      <Form.Label>Role</Form.Label>
                      <Form.Control
                        as="select"
                        {...field}
                        isInvalid={!!errors.role}
                      >
                        <option value={0} disabled>
                          Choose...
                        </option>

                        {roles &&
                          roles.map((e) => (
                            <option
                              key={`AdminUserForm_Roles_${e.id}`}
                              value={e.id}
                            >
                              {e.name}
                            </option>
                          ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {!!errors.role &&
                          errors.role.type === 'required' &&
                          'Role is required'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

AdminUserForm.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  role: PropTypes.object,
  roles: PropTypes.array,
  onSubmit: PropTypes.func,
};

AdminUserForm.defaultProps = {
  firstName: '',
  lastName: '',
  email: '',
  role: '',
};

export default AdminUserForm;
