import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  // Image,
  CardDeck,
} from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import {
  getAll as getProducts,
  getAllLanding as getProductsLanding,
} from '../../api/product';
import { useToggle, useFilter, useCart, useUser } from '../../hooks';
import ProductCard from '../../components/productCard';
import ProductCardSkeleton from '../../components/productCardSkeleton';
import CategoryFilter from '../../components/categoryFilter';
import SearchFilter from '../../components/searchFilter';
import ProductCardDetail from '../../components/productCardDetail';
import EmptyPage from '../../components/emptyPage';
import EmptyCartImage from '../../assets/images/cart.svg';
import { useHistory } from 'react-router-dom';
import CategorySkeleton from '../categorySkeleton';
import { isDesktop, isMobile } from 'react-device-detect';
import CateringDeck from '../cateringDeck/CateringDeck';
import CateringDeckSkeleton from '../cateringDeck/CateringDeckSkeleton';

const ProductDeck = (props) => {
  const { onlyFavorites, excludeCatering, displayOnLanding } = props;
  const { isUserLoggedIn } = useUser();
  const {
    productModal,
    hideProductModal,
    productDetail,
    // showItemAdded
  } = useCart();
  const {
    categories,
    categorySelected,
    onCategorySelected,
    isCategorySelectedCatering,
    searchText,
    filterProducts,
    fetchCategories,
  } = useFilter();
  const [products, setProducts] = useState([]);
  const [isGridView, setIsGridView] = useToggle(true);
  const [isLoading, setIsLoading] = useToggle(false);
  const [productsCache, setProductsCache] = useState([]);
  const history = useHistory();
  const { pathname } = useLocation();

  const isCatering = useMemo(
    () => isCategorySelectedCatering(),
    [categorySelected]
  );

  const onClickViewMenu = () => {
    pathname === '/fav' ? history.push('/menu') : onCategorySelected('all');
  };

  const filterClass = classNames({
    'd-flex': true,
    'align-items-center': true,
    'justify-content-between': true,
    'filter-bar': !displayOnLanding || isMobile,
  });

  const cardDeckClass = classNames({
    'page-container': true,
    'menu-page-container': true,
    'px-0': displayOnLanding && isDesktop,
  });

  useEffect(() => {
    onCategorySelected('all');
  }, [pathname]);

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading();
      const result = isUserLoggedIn
        ? await getProducts()
        : await getProductsLanding();
      setTimeout(() => {
        setProductsCache(result);
        setIsLoading();
      }, 500);
    };

    Promise.all([fetchCategories(), fetchProducts()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setProducts(productsCache);
  }, [productsCache]);

  useEffect(() => {
    const filtered = filterProducts({
      productsCache,
      categorySelected,
      searchText,
      onlyFavorites,
      excludeCatering,
    });

    if (productsCache?.length) {
      setIsLoading();
      setTimeout(() => {
        setProducts(filtered);
        setIsLoading();
      }, 500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySelected, productsCache, searchText]);

  return (
    <>
      {/* Product Detail Modal */}
      {productDetail?.product && productDetail?.quantity && (
        <ProductCardDetail
          key={productDetail.product.hash}
          show={productModal}
          product={productDetail.product}
          quantity={productDetail.quantity}
          onHide={hideProductModal}
        />
      )}

      <div className="menu-page">
        {/* Filters */}
        <div className={filterClass}>
          {isLoading && !categories.length ? (
            <CategorySkeleton />
          ) : (
            <CategoryFilter
              list={
                onlyFavorites
                  ? categories.filter((e) => e.code !== 'catering')
                  : categories
              }
              selected={categorySelected}
              onSelected={onCategorySelected}
              showAll={true}
            />
          )}
          {!displayOnLanding && !isCatering && <SearchFilter />}
        </div>

        {/* Card Deck */}
        <div className={cardDeckClass}>
          <div className="menu">
            <div className="text-right card-filter-bar d-block d-sm-none">
              <Link to="#" replace onClick={setIsGridView} className="d-none">
                <i
                  className={`fas ${isGridView ? 'fa-th-large' : 'fa-list'}`}
                ></i>
              </Link>
            </div>
            {!isCatering && (
              <CardDeck>
                {/* Skeleton */}
                {isLoading &&
                  [0, 1, 2, 3, 4, 5].map((e, k) => (
                    <ProductCardSkeleton
                      {...e}
                      key={`ProductCardSkeleton_${k}`}
                    />
                  ))}

                {/* Products */}
                {!isLoading &&
                  products?.map((e, k) => (
                    <ProductCard
                      product={{ ...e }}
                      isGridView={isGridView}
                      showFavorite={!displayOnLanding}
                      key={k}
                    />
                  ))}
              </CardDeck>
            )}
            {isCatering && isLoading && <CateringDeckSkeleton />}

            {isCatering && <CateringDeck products={productsCache} />}
          </div>

          {/* Empty Deck */}
          {!isLoading && !isCatering && products && products.length === 0 && (
            <EmptyPage
              image={EmptyCartImage}
              title={
                pathname === '/fav'
                  ? 'You haven’t selected any favorites yet!'
                  : 'No products found'
              }
              subtitle={
                pathname === '/fav'
                  ? 'Browse our menus and save your favorites here for quick access.'
                  : 'Browse our menus and select your favorites'
              }
              primaryButtonText="View Menu"
              primaryButtonOnClick={onClickViewMenu}
            />
          )}
        </div>
      </div>

      {/* Add Cart Component */}
      {/* <div className={`add-cart ${showItemAdded ? "show" : "hide"}`}>
        <h2>Added to cart</h2>
        <p>{productDetail?.product?.name}</p>
        <div className="image-container">
          <Image
            src="data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
            className="image"
            style={{
              backgroundImage: `url(${productDetail?.product?.image})`,
            }}
          />
          <div className="check d-flex justify-content-center align-items-center">
            <i className="fas fa-check"></i>
          </div>
        </div>
      </div> */}
    </>
  );
};

ProductDeck.propTypes = {
  onlyFavorites: PropTypes.bool,
  excludeCatering: PropTypes.bool,
  displayOnLanding: PropTypes.bool,
};

ProductDeck.defaultProps = {
  onlyFavorites: false,
  excludeCatering: false,
  displayOnLanding: false,
};

export default ProductDeck;
