import { useContext } from 'react';
import { MenuContext } from '../contexts/menu';

const useMenu = () => {
  const context = useContext(MenuContext);
  if (!context)
    throw new Error('useMenu hook must be used under the user context');

  return context;
};

export default useMenu;
