import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';

const CateringCategory = ({ name, description }) => {
  return (
    <>
      <div className="catering-category">
        <Col>
          <h1>{name}</h1>
          <h2 className="text-black-50">{description}</h2>
        </Col>
      </div>
    </>
  );
};

CateringCategory.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
};

export default CateringCategory;
