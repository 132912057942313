/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { isMobileOnly } from 'react-device-detect';
import CreditCardList from '../../components/creditCardList';
import PageTitle from '../../components/pageTitle';
import EmptyPage from '../../components/emptyPage';
import CreditCardImage from '../../assets/images/credit-card.svg';
import CreditCardDetail from '../../components/creditCardDetail';
import Popup from '../../components/popup';
import { getCreditCards, delCreditCard } from '../../api/customer';

const PaymentMethodsPage = ({ addCardShow, addCardOnHide, addCardOnShow }) => {
  const [creditCards, setCreditCards] = useState([]);
  const [creditCardSelected, setCreditCardSelected] = useState();
  const [popupProps, setPopupProps] = useState();

  const pageTitleProps = {
    title: 'Payment Methods',
    primaryButtonText: 'Add Card',
    primaryButtonClick: () => addCardOnShow(),
    primaryButtonDisabled: true,
    secondaryButtonText: 'Delete Card',
    secondaryButtonClick: () => handleDeleteCard(creditCardSelected),
  };

  const handleDeleteCard = async (id) => {
    setPopupProps({
      show: true,
      content: `Are you sure you want to <b class="text-danger">DELETE</b> the credit card?`,
      primaryButton: {
        text: `Yes`,
        onClick: async () => {
          await delCreditCard(id);
          await fetchCreditCards();
          setPopupProps({ show: false });
        },
      },
      secondaryButton: {
        text: `No`,
        onClick: () => setPopupProps({ show: false }),
      },
    });
  };

  const onCreditCardSelected = async (card) => {
    setCreditCardSelected(card.id);
  };

  const fetchCreditCards = async () => {
    const list = await getCreditCards();
    setCreditCards(list);
  };

  const onHide = () => {
    fetchCreditCards();
    addCardOnHide();
  };

  useEffect(() => {
    fetchCreditCards();
  }, []);

  return (
    <>
      <div className="payment-method-page">
        {!isMobileOnly && <PageTitle {...pageTitleProps} />}

        {/* Emtpy Page */}
        {Array.isArray(creditCards) && creditCards.lenght > 0 && (
          <EmptyPage
            image={CreditCardImage}
            title="You don't have any payment method yet"
            subtitle="Add your credit card to pay your orders."
          />
        )}

        {/* Credit Card List */}
        <Row>
          <Col xs={12} md={6}>
            <CreditCardList
              creditCards={creditCards}
              onSelect={onCreditCardSelected}
            />
          </Col>
        </Row>
      </div>

      {/* Add Card Modal */}
      <CreditCardDetail show={addCardShow} onHide={onHide} />

      {/* Confirmation Popup */}
      {popupProps && <Popup {...popupProps} />}
    </>
  );
};

export default PaymentMethodsPage;
