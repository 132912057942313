/* eslint-disable react/prop-types */
import React from 'react';

const CateringPanServesTitle = ({ customClassNames }) => (
  <h6 className={`text-black-50 ${customClassNames}`}>
    EACH PAN SERVES 6-8 PEOPLE
  </h6>
);

export default CateringPanServesTitle;
