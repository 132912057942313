import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const NoIngredientSelectionAlert = () => {
  const [show, setShow] = useState(true);

  return (
    <>
      {show && (
        <div className="no-ingredient-selection-alert">
          <Alert>
            <Row noGutters>
              <Col className="text-left d-block d-md-none">
                <i className="fas fa-exclamation-triangle"></i>
              </Col>
              <Col xs={10} md={11} className="px-2">
                <span className="d-none d-sm-inline-block mr-2">
                  <i className="fas fa-exclamation-triangle"></i>
                </span>
                <span>
                  Because of regulations from the TSA, in this Terminal you’re
                  not allowed to customize your ingredients. We’ll deliver the
                  item as is in the menu.
                </span>
              </Col>
              <Col xs={1} className="text-right">
                <i className="fas fa-times" onClick={() => setShow(false)}></i>
              </Col>
            </Row>
          </Alert>
        </div>
      )}
    </>
  );
};

export default NoIngredientSelectionAlert;
