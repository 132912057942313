import React from 'react';
import { Image } from 'react-bootstrap';

const WhatWheDo = () => {
  return (
    <>
      <div className="what-whe-do">
        <div className="what-whe-do-container">
          <h3>WHAT WE DO</h3>
          <h2>Why choose The Flight Kitchen?</h2>
          <div className="item-list d-flex flex-wrap justify-content-between">
            <div className="item">
              <div className="item-image-container">
                <Image src="assets/images/food-dish.svg" />
              </div>
              <p>Fresh, delicious food choices</p>
            </div>
            <div className="item">
              {/* <i className="fas fa-truck"></i> */}
              <div className="item-image-container">
                <Image src="assets/images/truck.svg" />
              </div>
              <p>Convenient delivery to terminal or FBO</p>
            </div>
            <div className="item">
              {/* <i className="fas fa-plane-departure"></i> */}
              <div className="item-image-container">
                <Image src="assets/images/airplane.svg" />
              </div>
              <p>Happy travel!</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatWheDo;
