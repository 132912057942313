/* eslint-disable react/prop-types */
import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import { Link, useLocation } from 'react-router-dom';
import { MobileView, BrowserView } from 'react-device-detect';

const HeaderLanding = ({ isHome = false, isUserLoggedIn, user }) => {
  const { pathname } = useLocation();

  return (
    <>
      <header className="header-landing">
        {/* Mobile view */}
        <MobileView>
          <div className="menu d-flex align-items-center justify-content-end">
            {isHome && <div style={{ margin: '25px' }}></div>}
          </div>

          <div className="subheader-landing">
            <div className="subheader-logo-container">
              <Link to="/">
                <Image src="assets/images/logo.svg" />
              </Link>
              <Image className="claim" src="assets/images/claim.svg" />
            </div>
          </div>

          {isHome && !isUserLoggedIn && (
            <>
              <div className="buttons-container d-flex justify-content-center">
                <Link className="btn btn-md btn-outline-primary" to="/signup">
                  Sign up
                </Link>

                <Link className="btn btn-md btn-primary ml-2" to="/signin">
                  Sign in
                </Link>
              </div>

              <div className="arrow-down d-flex justify-content-center">
                <i className="fas fa-arrow-down"></i>
              </div>
            </>
          )}

          {isUserLoggedIn && (
            <>
              <div className="buttons-container d-flex justify-content-center">
                <Link className="btn btn-md btn-outline-primary" to="/menu">
                  {`${user.firstName} ${user.lastName}`}
                </Link>
              </div>

              <div className="arrow-down d-flex justify-content-center">
                <i className="fas fa-arrow-down"></i>
              </div>
            </>
          )}

          <div className="cloud-botom">
            <Image src="assets/images/cloud-left.svg" />
          </div>
        </MobileView>

        {/* Browser view */}
        <BrowserView>
          <div className="menu d-flex align-items-center justify-content-between">
            <Nav activeKey={pathname} as="ul">
              <Nav.Item as="li">
                <Nav.Link href="#/" eventKey="/">
                  The Flight Kitchen
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link href="#/about-us" eventKey="/about-us">
                  Services
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link href="#/public-menu" eventKey="/public-menu">
                  Menu
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link href="#/contact-us" eventKey="/contact-us">
                  Contact
                </Nav.Link>
              </Nav.Item>
            </Nav>

            {isUserLoggedIn && (
              <div className="buttons-container">
                <Link className="btn btn-md btn-outline-primary" to="/menu">
                  {`${user.firstName} ${user.lastName}`}
                </Link>
              </div>
            )}

            {!isUserLoggedIn && (
              <div className="buttons-container">
                <Link className="btn btn-md btn-outline-primary" to="/signup">
                  Sign up
                </Link>

                <Link className="btn btn-md btn-primary ml-2" to="/signin">
                  Sign in
                </Link>
              </div>
            )}
          </div>
          <div className="subheader-landing">
            <div className="subheader-logo-container">
              <Image
                className="d-none d-lg-block"
                src="assets/images/logo.svg"
              />
              <Image
                className="d-none d-lg-block claim"
                src="assets/images/claim.svg"
              />
            </div>
          </div>
        </BrowserView>
      </header>
    </>
  );
};

export default HeaderLanding;
