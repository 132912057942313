import { useContext } from 'react';
import { CartContext } from '../contexts/cart';

const useCart = () => {
  const context = useContext(CartContext);
  if (!context)
    throw new Error('useCart hook must be used under the user context');

  return context;
};

export default useCart;
