import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

const EmptyPage = (props) => {
  const {
    image,
    title,
    subtitle,
    primaryButtonText,
    primaryButtonOnClick,
    secondaryButtonText,
    secondaryButtonOnClick,
  } = props;

  return (
    <>
      <div className="empty-page">
        {/* Image */}
        <div className="image-container">
          <img src={image} />
        </div>

        {/* Title */}
        {title && <h1 className="title">{title}</h1>}

        {/* Subtitle */}
        {subtitle && <h2 className="subtitle">{subtitle}</h2>}

        {/* Buttons */}
        {(primaryButtonText || secondaryButtonText) && (
          <div className="buttons">
            {/* Outline */}
            {secondaryButtonText && secondaryButtonOnClick && (
              <Button
                variant="outline-primary"
                className=""
                onClick={secondaryButtonOnClick}
              >
                {secondaryButtonText}
              </Button>
            )}

            {/* Primary */}
            {primaryButtonText && primaryButtonOnClick && (
              <Button
                variant="primary"
                className=""
                onClick={primaryButtonOnClick}
              >
                {primaryButtonText}
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

EmptyPage.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  primaryButtonText: PropTypes.string,
  primaryButtonOnClick: PropTypes.func,
  secondaryButtonText: PropTypes.string,
  secondaryButtonOnClick: PropTypes.func,
};

export default EmptyPage;
