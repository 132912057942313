import axios from 'axios';
import { API_URL, ENVIRONMENT } from '../config';
import mockServer from './mock/server';
import storage from '../helpers/storage';
import { storageKey } from '../constants/storage';

axios.defaults.baseURL = API_URL;
axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.validateStatus = () => true;
axios.defaults.withCredentials = true;

if (ENVIRONMENT === 'mock') mockServer();

const makeCall = (url, options) => {
  const user = storage.get(storageKey.USER);

  return axios(url, {
    ...options,
    withCredentials: true,
    headers: { ...options.headers, Authorization: 'Bearer ' + user?.token },
  })
    .then((res) => {
      if (res.status === 401) storage.clear();

      const result =
        res.status >= 300
          ? { success: false, error: res.data }
          : { success: true, data: res.data };
      return result;
    })
    .catch((err) => {
      if (!axios.isCancel(err)) throw err;
    });
};

export const apiCall = (url, options = {}) => {
  if (options.immediately) return makeCall(url, options);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  return {
    abort: () => source.cancel('Operation canceled by the user'),
    ready: () => makeCall(url, { ...options, cancelToken: source.token }),
  };
};

export const addResponseInterceptor = (fn, errFn) =>
  axios.interceptors.response.use(fn, errFn);

export const ejectResponseInterceptor = (interceptor) =>
  axios.interceptors.response.eject(interceptor);
