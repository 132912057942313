import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import OrderPlacedImage from '../../assets/images/checkout-ok.svg';
import { cancelOrder } from '../../api/order';
import EmptyPage from '../../components/emptyPage';
import Popup from '../../components/popup';
import PopupSaving from '../../components/popupSaving';
import { useNotification } from '../../hooks';

const OrderPlacedPage = () => {
  const history = useHistory();
  const { hash } = useParams();
  const { success, error } = useNotification();

  const [saving, setSaving] = useState();
  const [popup, setPopup] = useState();

  const goToHome = () => history.push('/menu');

  const handleCancelOrder = async () => {
    setPopup({ show: false });
    setSaving(true);
    const result = await cancelOrder(hash);
    setTimeout(() => {
      setSaving(false);
      result.success
        ? success({ message: `Order cancelled succesfully` })
        : error({ message: `${result.error.message} Contact support.` });
      setSaving(false);
      goToHome();
    }, 4000);
  };

  // Empty Page
  const onClickBuyMore = () => goToHome();

  const onClickCancelOrder = async () => {
    setPopup({
      show: true,
      content: `Are you sure you want to <b class="text-danger">CANCEL</b> this order?`,
      primaryButton: {
        text: `Yes`,
        onClick: () => handleCancelOrder(),
      },
      secondaryButton: {
        text: `No`,
        onClick: () => setPopup({ show: false }),
      },
    });
  };

  const emptyProps = {
    image: OrderPlacedImage,
    title: 'Your order has been scheduled.',
    subtitle:
      'You may cancel your order without charge until 24 hours before delivery.',
    primaryButtonText: 'Buy another item',
    primaryButtonOnClick: onClickBuyMore,
    secondaryButtonText: 'Cancel my order',
    secondaryButtonOnClick: onClickCancelOrder,
  };

  return (
    <>
      {/* Popup */}
      {popup && <Popup {...popup} />}

      {/* Save Loading */}
      {saving && (
        <PopupSaving
          show={saving}
          content={`We're preparing everything for your purchase...`}
        />
      )}

      <div className="page-container order-placed">
        <EmptyPage {...emptyProps} />
      </div>
    </>
  );
};

export default OrderPlacedPage;
