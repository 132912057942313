import React from 'react';
import { useUser } from '../../hooks';
import Header from '../header';
import HeaderLanding from '../headerLanding';
import SideMenu from '../sideMenu';

export const HeaderContainer = () => {
  const { isUserLoggedIn } = useUser();

  return isUserLoggedIn ? (
    <>
      <Header /> <SideMenu />
    </>
  ) : (
    <HeaderLanding />
  );
};
