import { apiCall } from './base';

export const placeOrder = async (data) => {
  const result = await apiCall(`/order/place`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
    data,
  });
  return result;
};

export const cancelOrder = async (hash) => {
  const result = await apiCall(`/order/${hash}/cancel`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
  });
  return result;
};

export const approveOrder = async (hash) => {
  const result = await apiCall(`/order/${hash}/approve`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
  });
  return result;
};

export const acceptOrder = async (hash) => {
  const result = await apiCall(`/order/${hash}/accept`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
  });
  return result;
};

export const rejectOrder = async (hash) => {
  const result = await apiCall(`/order/${hash}/reject`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
  });
  return result;
};

export const deliverOrder = async (hash) => {
  const result = await apiCall(`/order/${hash}/deliver`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
  });
  return result;
};

export const notDeliverOrder = async (hash) => {
  const result = await apiCall(`/order/${hash}/not-deliver`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
  });
  return result;
};

export const getOrders = async (statusId, filter = '', order = '') => {
  const result = await apiCall(`/order/status/${statusId}`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
    params: {
      filter: filter,
      order: order,
    },
  });
  return result.data;
};

export const getOrdersFull = async (statusId) => {
  const result = await apiCall(`/order/status/${statusId}/full`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
  });
  return result.data;
};

export const getOrder = async (id) => {
  const result = await apiCall(`/order/${id}`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
    // params,
  });
  return result.data;
};

export const getStatus = async (params) => {
  const result = await apiCall(`/order/status`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
    params,
  });
  return result.data;
};
