import { apiCall } from './base';

export const getCart = async (params) => {
  const result = await apiCall(`/cart`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
    params,
  });
  return result.data;
};

export const setItem = async (productHash, data) => {
  const result = await apiCall(`/cart/item/${productHash}`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
    data,
  });
  return result.data;
};

export const removeItem = async (productHash) => {
  const result = await apiCall(`/cart/item/${productHash}`, {
    method: 'DELETE',
    withCredentials: true,
    immediately: true,
  });
  return result.data;
};
