import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import AdminDiscountCodeForm from '../adminDiscountCodeForm';

const AdminDiscountCodeModal = ({
  code,
  percentage,
  assignment,
  endDate,
  show,
  hideModal,
  actions: { create, edit },
}) => {
  const handleDiscountForm = (discount) => {
    const isEdit = !!code;
    isEdit ? edit(discount) : create(discount);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={hideModal}
        backdropClassName="popup-backdrop"
        dialogClassName="popup popup-xl"
        centered
      >
        {/* Header */}
        <Modal.Header>
          <Modal.Title>
            <span className="close-modal" onClick={hideModal}>
              <i className="fas fa-times"></i>
            </span>
          </Modal.Title>
        </Modal.Header>

        {/* Body */}
        <Modal.Body>
          <Container>
            <p>Add new code</p>

            {/* Discount Form */}
            <AdminDiscountCodeForm
              code={code}
              percentage={percentage}
              assignment={assignment}
              endDate={endDate}
              onSubmit={handleDiscountForm}
            />
          </Container>
        </Modal.Body>

        {/* Footer */}
        <Modal.Footer>
          <Button variant="outline-primary" size="md" onClick={hideModal}>
            Close
          </Button>
          <Button
            form="discount-code-form"
            type="submit"
            variant="primary"
            size="md"
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

AdminDiscountCodeModal.propTypes = {
  code: PropTypes.string,
  percentage: PropTypes.number,
  assignment: PropTypes.string,
  endDate: PropTypes.string,
  show: PropTypes.bool,
  hideModal: PropTypes.func,
  actions: PropTypes.object,
};

export default AdminDiscountCodeModal;
