import React from 'react';
import { HashRouter, Switch } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { isMobile } from 'react-device-detect';

import { UserProvider } from './contexts/user';
import { MenuProvider } from './contexts/menu';
import { FilterProvider } from './contexts/filter';
import { CartProvider } from './contexts/cart';
import { NotificationProvider } from './contexts/notification';
import CustomToast from './components/customToast';
import { roleKey } from './constants/role';

import LandingPage from './pages/landing';
import LandingSoon from './pages/landingSoon';
import SignInPage from './pages/signin';
import SignUpPage from './pages/signup';
import ForgotPasswordPage from './pages/forgot-password';
import ResetPasswordPage from './pages/reset-password';

import NotFoundPage from './pages/notFound';
import MenuPage from './pages/menu';
import LandingMenuPage from './pages/landingMenu';
import OrderPage from './pages/order';
import FavoritePage from './pages/favorite';
import FlightPage from './pages/flight';
import CartPage from './pages/cart';
import ProfilePage from './pages/profile';
import FaqPage from './pages/faq';
import AboutUsPage from './pages/aboutUs';
import ContactUsPage from './pages/contactUs';
import WhereWeOperatePage from './pages/whereWeOperate';
import TermsAndConditionsPage from './pages/termsAndConditions';
import PrivacyPolicyPage from './pages/privacyPolicy';
import CheckoutPage from './pages/checkout';
import OrderPlacedPage from './pages/orderPlaced';

// Admin Pages
import AdminOrderPage from './pages/adminOrder';
import AdminOrderDetailPage from './pages/adminOrderDetail';
import AdminDiscountCode from './pages/adminDiscountCode';
import AdminProduct from './pages/adminProduct';
import AdminUser from './pages/adminUser';

// Delivery Page
import DeliveryPage from './pages/delivery';

import PublicRoute from './pages/base/public';
import PrivateRoute from './pages/base/private';
import './App.scss';

const App = () => {
  return (
    <ToastProvider
      components={{ Toast: CustomToast }}
      autoDismiss
      autoDismissTimeout={4000}
      placement={isMobile ? 'bottom-center' : 'top-center'}
    >
      <NotificationProvider>
        <UserProvider>
          <CartProvider>
            <FilterProvider>
              <MenuProvider>
                <HashRouter>
                  <Switch>
                    <PrivateRoute
                      component={MenuPage}
                      roleAllowed={[roleKey.CUSTOMER]}
                      path="/menu"
                      exact
                    />

                    <PrivateRoute
                      component={OrderPage}
                      roleAllowed={[roleKey.CUSTOMER]}
                      path="/order"
                      exact
                    />

                    <PrivateRoute
                      component={FlightPage}
                      roleAllowed={[roleKey.CUSTOMER]}
                      path="/flight"
                      exact
                    />

                    <PrivateRoute
                      component={CartPage}
                      roleAllowed={[roleKey.CUSTOMER]}
                      path="/cart"
                      exact
                    />

                    <PrivateRoute
                      component={FavoritePage}
                      roleAllowed={[roleKey.CUSTOMER]}
                      path="/fav"
                      exact
                    />

                    <PrivateRoute
                      component={CheckoutPage}
                      roleAllowed={[roleKey.CUSTOMER]}
                      path="/checkout"
                      exact
                    />
                    <PrivateRoute
                      component={OrderPlacedPage}
                      roleAllowed={[roleKey.CUSTOMER]}
                      path="/order/placed/:hash"
                      exact
                    />
                    <PrivateRoute
                      component={ProfilePage}
                      roleAllowed={[roleKey.CUSTOMER]}
                      path="/profile"
                      exact
                    />

                    {/* Admin Pages */}
                    <PrivateRoute
                      component={AdminOrderPage}
                      roleAllowed={[roleKey.ADMIN, roleKey.KITCHEN]}
                      path="/admin/order"
                      exact
                    />

                    <PrivateRoute
                      component={AdminOrderDetailPage}
                      roleAllowed={[roleKey.ADMIN, roleKey.KITCHEN]}
                      path="/admin/order/:hash"
                    />

                    <PrivateRoute
                      component={AdminDiscountCode}
                      roleAllowed={[roleKey.ADMIN]}
                      path="/admin/discount"
                      exact
                    />

                    <PrivateRoute
                      component={AdminProduct}
                      roleAllowed={[roleKey.ADMIN]}
                      path="/admin/product"
                      exact
                    />

                    <PrivateRoute
                      component={AdminUser}
                      roleAllowed={[roleKey.ADMIN]}
                      path="/admin/user"
                      exact
                    />

                    <PrivateRoute
                      component={DeliveryPage}
                      roleAllowed={roleKey.DELIVERY}
                      path="/delivery"
                      exact
                    />

                    {/* Public Routes */}
                    <PublicRoute
                      restricted={true}
                      component={SignInPage}
                      path="/login"
                      exact
                    />

                    <PublicRoute
                      restricted={true}
                      component={SignUpPage}
                      path="/signup"
                      exact
                    />

                    <PublicRoute
                      restricted={true}
                      component={SignInPage}
                      path="/signin"
                      exact
                    />

                    <PublicRoute
                      restricted={true}
                      component={ForgotPasswordPage}
                      path="/forgot-password"
                      exact
                    />

                    <PublicRoute
                      restricted={true}
                      component={ResetPasswordPage}
                      path="/reset-password/:token"
                      exact
                    />

                    <PublicRoute
                      restricted={false}
                      component={FaqPage}
                      path="/faq"
                      exact
                    />

                    <PublicRoute
                      restricted={false}
                      component={AboutUsPage}
                      path="/about-us"
                      exact
                    />

                    <PublicRoute
                      restricted={false}
                      component={ContactUsPage}
                      path="/contact-us"
                      exact
                    />

                    <PublicRoute
                      restricted={false}
                      component={WhereWeOperatePage}
                      path="/where-we-operate"
                      exact
                    />

                    <PublicRoute
                      restricted={false}
                      component={PrivacyPolicyPage}
                      path="/privacy-policy"
                      exact
                    />

                    <PublicRoute
                      restricted={false}
                      component={TermsAndConditionsPage}
                      path="/terms-and-conditions"
                      exact
                    />

                    <PublicRoute
                      component={LandingMenuPage}
                      path="/public-menu"
                      exact
                    />

                    <PublicRoute
                      restricted={false}
                      component={LandingPage}
                      path="/"
                      exact
                    />

                    {false && (
                      <PublicRoute
                        restricted={false}
                        component={LandingSoon}
                        path="/"
                        exact
                      />
                    )}

                    <PublicRoute restricted={false} component={NotFoundPage} />
                  </Switch>
                </HashRouter>
              </MenuProvider>
            </FilterProvider>
          </CartProvider>
        </UserProvider>
      </NotificationProvider>
    </ToastProvider>
  );
};

export default App;
