import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

const ContactForm = ({ onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  return (
    <div className="create-code-form mt-4">
      <Form
        noValidate
        id="discount-code-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row>
          <Col xs={12}>
            {/* Name */}
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <div className="select-element">
                  <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      {...field}
                      isInvalid={!!errors.name}
                      placeholder="First and Last Name"
                    />
                    <Form.Control.Feedback type="invalid">
                      {!!errors.name &&
                        errors.name.type === 'required' &&
                        'Name is required'}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              )}
            />
          </Col>

          {/* Email */}
          <Col xs={12}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
                pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
              }}
              render={({ field }) => (
                <div className="select-element">
                  <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      {...field}
                      isInvalid={!!errors.email}
                      type="email"
                      placeholder="name@company.com"
                    />
                    <Form.Control.Feedback type="invalid">
                      {!!errors.email &&
                        errors.email.type === 'required' &&
                        'Email is required'}
                      {!!errors.email &&
                        errors.email.type === 'pattern' &&
                        'Email is invalid'}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              )}
            />
          </Col>

          {/* Message */}
          <Col xs={12}>
            <Controller
              name="message"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <div className="select-element">
                  <Form.Group controlId="message">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      {...field}
                      isInvalid={!!errors.email}
                      as="textarea"
                      rows={1}
                      placeholder=""
                    />
                    <Form.Control.Feedback type="invalid">
                      {!!errors.message &&
                        errors.message.type === 'required' &&
                        'Message is required'}
                      {!!errors.message &&
                        errors.message.type === 'pattern' &&
                        'Message is invalid'}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              )}
            />
          </Col>
        </Row>

        {/* Button */}
        <Row>
          <Col>
            <Button type="submit" variant="btn" className="btn-primary">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

ContactForm.propTypes = {
  code: PropTypes.string,
  percentage: PropTypes.string,
  endDate: PropTypes.string,
  assignment: PropTypes.string,
  onSubmit: PropTypes.func,
};

// ContactForm.defaultProps = {
//   code: "",
//   percentage: "",
//   endDate: "",
//   assignment: "",
// };

export default ContactForm;
