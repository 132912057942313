import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import { MobileView, BrowserView } from 'react-device-detect';
import { useCart } from '../../hooks';
import CartItem from '../../components/cartItem';
import EmptyPage from '../../components/emptyPage';
import EmptyCartImage from '../../assets/images/cart.svg';
import { exportToTop } from '../../helpers/utils';

const CartPage = () => {
  const { cart, cartItems, getPaymentDetails } = useCart();
  const history = useHistory();
  const [paymentDetails, setPaymentDetails] = useState({});

  const onClickViewMenu = () => {
    history.push('/menu');
  };

  useEffect(() => {
    exportToTop();
  }, []);

  useEffect(() => {
    const details = getPaymentDetails();
    setPaymentDetails(details);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  return (
    <>
      <div className="page-container cart">
        {/* Desktop view */}
        <BrowserView>
          <div className={`${cartItems ? 'd-block' : 'd-none'}`}>
            {/* Title and Buttons */}
            <Row className="row-cols-2">
              <Col>
                <h1>My Cart</h1>
                <h2>You currently have {cartItems} item(s) in your cart.</h2>
              </Col>
              <Col className="buttons-container">
                <Link className="btn btn-md btn-outline-primary" to="menu">
                  Buy more items
                </Link>

                <Link className="btn btn-md btn-primary" to="checkout">
                  Proceed To Checkout
                </Link>
              </Col>
            </Row>

            {/* Cart List */}
            <Card className="cart-card-list">
              <div className="cart-list">
                {/* Cart Item Header */}
                <div className="cart-list-header">
                  <Row className="align-items-center no-gutters">
                    <Col className="col-auto mr-auto">
                      <span className="name">Item</span>
                    </Col>
                    <Col className="col-min-width">
                      <span>Quantity</span>
                    </Col>
                    <Col className="col-min-width">
                      <span>Unit Price</span>
                    </Col>
                    <Col className="col-min-width">
                      <span>Total</span>
                    </Col>
                    <Col className="col-min-width">
                      <span>Remove</span>
                    </Col>
                  </Row>
                </div>

                {/* Cart Item */}
                {cart &&
                  cart.products &&
                  cart.products.length > 0 &&
                  cart.products.map((e, k) => (
                    <CartItem key={`CartItem_${k}`} product={e}></CartItem>
                  ))}
              </div>

              {/* Subtotal */}
              <Row className="align-items-center subtotal">
                <Col></Col>
                <Col className="col-min-width"></Col>
                <Col className="col-min-width label">Subtotal</Col>
                <Col className="col-min-width value">
                  $
                  {paymentDetails &&
                    paymentDetails.subtotal &&
                    paymentDetails.subtotal.toFixed(2)}
                </Col>
                <Col className="col-min-width"></Col>
              </Row>
            </Card>
          </div>
        </BrowserView>

        {/* Mobile view */}
        <MobileView>
          <div className={`${cartItems ? 'd-block' : 'd-none'}`}>
            <div className="payment-details car-list-wrapper">
              <div className="cart-list">
                {cart?.products?.length &&
                  cart?.products?.map((e, k) => (
                    <CartItem key={k} product={e}></CartItem>
                  ))}
              </div>
            </div>

            <div className="payment-detail-footer">
              <div className="payment-details-mobile">
                <ListGroup variant="flush">
                  <ListGroup.Item className="payment-total">
                    <span>Total</span>
                    <span className="float-right">
                      ${paymentDetails?.subtotal?.toFixed(2)}
                    </span>
                  </ListGroup.Item>
                </ListGroup>
              </div>
              <div className="buttom-mobile-box">
                <Link className="btn btn-primary" to="checkout">
                  Proceed To Checkout
                </Link>

                <Link className="btn btn-outline-primary" to="menu">
                  Add another item
                </Link>
              </div>
            </div>
          </div>
        </MobileView>

        {/* Empty Cart */}
        <div className={`${cartItems ? 'd-none' : 'd-block'}`}>
          <EmptyPage
            image={EmptyCartImage}
            title="Your cart is empty!"
            subtitle="Browse our menus and make your selections."
            primaryButtonText="View Menu"
            primaryButtonOnClick={onClickViewMenu}
          />
        </div>
      </div>
    </>
  );
};

export default CartPage;
