import React from 'react';
import PropTypes from 'prop-types';

const FormCheck = ({ label, isActive, onClick }) => {
  return (
    <>
      <div className={`form-check`}>
        <label onClick={onClick}>
          {label}
          {isActive ? (
            <i className="fas fa-check-square selected"></i>
          ) : (
            <i className="far fa-square unselected"></i>
          )}
        </label>
      </div>
    </>
  );
};

FormCheck.propTypes = {
  label: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

export default FormCheck;
