import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

const CheckoutItem = (props) => {
  const { product } = props;

  const {
    name,
    cartQuantity: quantity,
    cartPrice: price,
    ingredients,
  } = product || {};

  return (
    <div className="d-none d-sm-block">
      <Row className="align-items-center no-gutters checkout-item">
        {/* Product Name */}
        <Col>
          <div className="d-flex">
            <span className="checkout-item-name">
              <span className="name">{name}</span>
              <span className="ingredients">
                {ingredients
                  .filter((e) => e.isIncluded)
                  .map((e) => e.name)
                  .join(', ')}
              </span>
            </span>
          </div>
        </Col>
        <Col className="col-min-width">
          <span>{quantity}</span>
        </Col>
        <Col className="col-min-width">
          <span>${(quantity * price).toFixed(2)}</span>
        </Col>
      </Row>
    </div>
  );
};

CheckoutItem.propTypes = {
  product: PropTypes.object,
};

export default CheckoutItem;
