import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

const AdminDiscountCodeForm = ({
  code,
  percentage,
  endDate,
  assignment,
  onSubmit,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const radioValue = assignment === 'Everyone' || !assignment ? 1 : 2;
  const [radio, setRadio] = useState(radioValue);

  const handleToogleChange = (val) => {
    if (val === 1) setValue('assignment', '');
    setRadio(val);
  };

  return (
    <>
      <div className="create-code-form mt-4">
        <Form
          noValidate
          id="discount-code-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col xs={12}>
              {/* Code Name */}
              <Controller
                name="code"
                control={control}
                defaultValue={code}
                rules={{ required: true }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="code">
                      <Form.Label>Code Name</Form.Label>
                      <Form.Control {...field} isInvalid={!!errors.code} />
                      <Form.Control.Feedback type="invalid">
                        {!!errors.code &&
                          errors.code.type === 'required' &&
                          'Code is required'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />
            </Col>
          </Row>

          <Row>
            {/* Percentage */}
            <Col xs={6}>
              <Controller
                name="percentage"
                control={control}
                defaultValue={percentage}
                rules={{ required: true, min: 1, max: 100 }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="percentage">
                      <Form.Label>Discount (%)</Form.Label>
                      <Form.Control
                        {...field}
                        isInvalid={!!errors.percentage}
                        type="number"
                        min="1"
                        max="100"
                      />
                      <Form.Control.Feedback type="invalid">
                        {!!errors.percentage && 'Discount % is invalid'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />
            </Col>

            {/* End Date */}
            <Col xs={6}>
              <Controller
                name="endDate"
                control={control}
                defaultValue={endDate}
                rules={{ required: true }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="endDate">
                      <Form.Label>End Date</Form.Label>
                      <Form.Control
                        {...field}
                        isInvalid={!!errors.endDate}
                        type="date"
                        min={new Date().toISOString().slice(0, 10)}
                        max={new Date(
                          new Date().setFullYear(new Date().getFullYear() + 1)
                        )
                          .toISOString()
                          .slice(0, 10)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {!!errors.endDate && 'End Date is required'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />
            </Col>
          </Row>

          <Row>
            {/* Assign To - Radio */}
            <Col xs={12}>
              <div className="select-element">
                <label className="form-label mb-3">Assign to</label>
              </div>

              <ToggleButtonGroup
                type="radio"
                name="assignTo"
                value={radio}
                onChange={handleToogleChange}
                className="mb-3"
              >
                <ToggleButton
                  value={1}
                  variant="link"
                  className="toggle-button"
                >
                  <i
                    className={
                      radio === 1 ? `fas fa-dot-circle` : `far fa-circle`
                    }
                  ></i>
                  <span>Everyone</span>
                </ToggleButton>

                <ToggleButton
                  value={2}
                  variant="link"
                  className="toggle-button"
                >
                  <div className="flex">
                    <i
                      className={
                        radio === 2 ? `fas fa-dot-circle` : `far fa-circle`
                      }
                    ></i>
                    <span>Specific user</span>
                  </div>
                </ToggleButton>
              </ToggleButtonGroup>
            </Col>

            {/* Assign To - Email */}
            {/* Email Optional */}
            {radio === 2 && (
              <Col xs={12}>
                <Controller
                  name="assignment"
                  control={control}
                  defaultValue={assignment}
                  rules={{
                    required: radio === 2,
                    pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                  }}
                  render={({ field }) => (
                    <div className="select-element">
                      <Form.Group controlId="assignment">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          {...field}
                          isInvalid={!!errors.assignment}
                          type="email"
                          placeholder="Enter user's email"
                        />
                        <Form.Control.Feedback type="invalid">
                          {!!errors.assignment &&
                            errors.assignment.type === 'required' &&
                            'Assignment is required'}
                          {!!errors.assignment &&
                            errors.assignment.type === 'pattern' &&
                            'Assignment is invalid'}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  )}
                />
              </Col>
            )}
          </Row>
        </Form>
      </div>
    </>
  );
};

AdminDiscountCodeForm.propTypes = {
  code: PropTypes.string,
  percentage: PropTypes.string,
  endDate: PropTypes.string,
  assignment: PropTypes.string,
  onSubmit: PropTypes.func,
};

AdminDiscountCodeForm.defaultProps = {
  code: '',
  percentage: '',
  endDate: '',
  assignment: '',
};

export default AdminDiscountCodeForm;
