import React, { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useToggle, useNotification } from '../../hooks';
import { resetPassword, resetPasswordValidateToken } from '../../api/auth';

function ResetPasswordPage() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useToggle(true);
  const [showConfirmPassword, setShowConfirmPassword] = useToggle(true);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm();

  const { token } = useParams();
  const history = useHistory();
  const { error, success } = useNotification();

  const onSubmit = async (data) => {
    setLoading(true);

    const { password, confirmPassword } = data;

    if (password !== confirmPassword) {
      setError('confirmPassword', {
        type: 'manual',
      });
      setLoading(false);
      return;
    }

    const result = await resetPassword({ ...data, token });

    if (!result.success) {
      error({ message: `${result.error.message}` });
    }

    success({ message: `Password successfuly changed. Now you can log in!` });
    history.push(`/signin`);

    setLoading(false);
  };

  useEffect(() => {
    const init = async () => {
      const result = await resetPasswordValidateToken({ token });
      if (!result.success) {
        error({ message: `${result.error.message}` });
        history.push(`/`);
      }
    };

    init();
  }, []);

  return (
    <>
      <div className="login">
        <div className="login-container">
          <div className="header-login">
            <Image src="assets/images/logo.svg" />
            <p className="logo-claim">Travel happy, not hungry</p>
          </div>

          {/* Sign Up */}
          <div className="form forgot">
            <p className="forgot-title">Create new password</p>

            <Form
              noValidate
              id="reset-password-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              {/* Password */}
              <Controller
                name="password"
                control={control}
                defaultValue={''}
                rules={{ required: true, minLength: 4, maxLength: 20 }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        {...field}
                        isInvalid={!!errors.password}
                        type={showPassword ? 'password' : 'text'}
                        placeholder="Enter your Password"
                      />
                      <Link
                        className="btn btn-link btn-icon password-view"
                        to="#"
                        replace
                        onClick={() => setShowPassword()}
                      >
                        {showPassword ? (
                          <i className="fas fa-eye"></i>
                        ) : (
                          <i className="fas fa-eye-slash"></i>
                        )}
                      </Link>
                      <Form.Control.Feedback type="invalid">
                        {errors?.password?.type === 'required' &&
                          'Password is required'}
                        {errors?.password?.type === 'minLength' &&
                          'Password should have more than 4 characters'}
                        {errors?.password?.type === 'maxLength' &&
                          'Password should have less than 20 characters'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />

              {/* Confirm Password */}
              <Controller
                name="confirmPassword"
                control={control}
                defaultValue={''}
                rules={{
                  required: true,
                  minLength: 4,
                  maxLength: 20,
                }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="confirmPassword">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        {...field}
                        isInvalid={!!errors.confirmPassword}
                        type={showConfirmPassword ? 'password' : 'text'}
                        placeholder="Re-enter your Password"
                      />
                      <Link
                        className="btn btn-link btn-icon password-view"
                        to="#"
                        replace
                        onClick={() => setShowConfirmPassword()}
                      >
                        {showConfirmPassword ? (
                          <i className="fas fa-eye"></i>
                        ) : (
                          <i className="fas fa-eye-slash"></i>
                        )}
                      </Link>
                      <Form.Control.Feedback type="invalid">
                        {errors?.confirmPassword?.type === 'required' &&
                          'Password is required'}
                        {errors?.confirmPassword?.type === 'minLength' &&
                          'Password should have more than 4 characters'}
                        {errors?.confirmPassword?.type === 'maxLength' &&
                          'Password should have less than 20 characters'}
                        {errors?.confirmPassword?.type === 'manual' &&
                          'Passwords should match'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />

              {!loading && (
                <Button variant="primary" type="submit">
                  Change Password
                </Button>
              )}

              {loading && (
                <Button variant="primary" type="submit" disabled={true}>
                  <i className="fas fa-circle-notch fa-spin"></i>
                </Button>
              )}
            </Form>
          </div>
        </div>

        <div className="clouds">
          <Image src="assets/images/clouds.svg" className="img-fluid" />
        </div>
      </div>
    </>
  );
}

export default ResetPasswordPage;
