import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import FormInput from '../formInput';
import DeliveryCard from '../deliveryCard';
import { useNotification } from '../../hooks';

const DeliveryRejectModal = (props) => {
  const [show, setShow] = useState(props.show);
  const [order, setOrder] = useState(props.order);
  const [primaryButton, setPrimaryButton] = useState(props.primaryButton);
  const [secondaryButton, setSecondaryButton] = useState(props.secondaryButton);
  const [reason, setReason] = useState();
  const { warning } = useNotification();

  const onClickSend = () => {
    if (!reason) {
      warning({ message: `Please tell us the issue.` });
    } else if (reason.length <= 10) {
      warning({ message: `Add more detail to the issue.` });
    } else {
      primaryButton.onClick();
    }
  };

  useEffect(() => {
    const { show, order, primaryButton } = props;

    setShow(show);
    if (order) setOrder(order);
    if (primaryButton) setPrimaryButton({ ...primaryButton });
    if (secondaryButton) setSecondaryButton({ ...secondaryButton });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <>
      {/* My Delivery Modal */}
      <Modal
        show={show}
        onHide={secondaryButton.onClick}
        dialogClassName="delivery-modal"
        size="xl"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <i
              className="fas fa-arrow-left"
              onClick={secondaryButton.onClick}
            ></i>
            Write report
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* Delivery Card */}
          <DeliveryCard {...order} />

          {/* Issue Explanation */}
          <div className="comment">
            <FormInput
              label="Explain the issue"
              // controlId={"FlightNumber"}
              placeholder="Tell us why you did't deliver the order"
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Link
            className="btn btn-primary"
            to="#"
            replace
            onClick={onClickSend}
          >
            Send
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
};

DeliveryRejectModal.propTypes = {
  show: PropTypes.bool,
  order: PropTypes.object,
  primaryButton: PropTypes.object,
  secondaryButton: PropTypes.object,
};

DeliveryRejectModal.propDefault = {
  show: false,
};

export default DeliveryRejectModal;
