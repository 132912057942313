import React from 'react';
import PropTypes from 'prop-types';

function AdminOrderItemIconCol(props) {
  const { label, value, iconTrue, iconFalse, iconTrueClass, iconFalseClass } =
    props;

  return (
    <>
      <div className="admin-order-item-icon-col">
        <div className="admin-order-item-icon-container">
          <p className="admin-order-item-icon-name">{label}</p>
          <p className="admin-order-item-icon-value">
            {value ? (
              <i className={`${iconTrue} ${iconTrueClass}`}></i>
            ) : (
              <i className={`${iconFalse} ${iconFalseClass}`}></i>
            )}
          </p>
        </div>
      </div>
    </>
  );
}

AdminOrderItemIconCol.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  iconTrue: PropTypes.string,
  iconFalse: PropTypes.string,
  iconTrueClass: PropTypes.string,
  iconFalseClass: PropTypes.string,
};

export default AdminOrderItemIconCol;
