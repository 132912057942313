import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
// import Select from "react-dropdown-select";

function FormSelect(props) {
  return (
    <>
      <div className="select-element">
        <Form.Group controlId={props.controlId}>
          <Form.Label>{props.label}</Form.Label>
          <Form.Control type="text" {...props} />
        </Form.Group>
      </div>
    </>
  );
}

FormSelect.propTypes = {
  label: PropTypes.string,
  controlId: PropTypes.string,
};

export default FormSelect;
