import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { exportToTop } from '../../helpers/utils';
import { HeaderContainer } from '../../components/headerContainer';
import { FooterContainer } from '../../components/footerContainer';
import { useUser } from '../../hooks';

const PrivacyPolicyPage = () => {
  const { user } = useUser();

  useEffect(() => {
    exportToTop();
  }, []);

  return (
    <>
      <HeaderContainer />
      <div
        className={
          user ? `main container container-fluid py-1` : `main container`
        }
      >
        <div className="page-container privacy-policy my-5 p-2">
          <h1 className="page-title mb-4">Privacy Policy</h1>

          <Row>
            <Col xs={12}>
              <h5 className="text-bold">
                <b>WEBSITE PRIVACY POLICY FOR THE FLIGHT KITCHEN, LLC</b>
              </h5>

              <p>Last updated January 1, 2022</p>

              <p>
                <b>The Flight Kitchen, LLC</b> (“we” or “us” or “our”) respects
                the privacy of our users (“user” or “you”). This Privacy Policy
                explains how we collect, use, disclose, and safeguard your
                information when you visit our website (
                <a href="https://www.theflightkitchen.com">
                  www.theflightkitchen.com
                </a>
                ), including any other media form, or mobile website related or
                connected thereto (collectively, the “Site”). Please read this
                privacy policy carefully. IF YOU DO NOT AGREE WITH THE TERMS OF
                THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE SITE.
              </p>

              <p>
                We reserve the right to make changes to this Privacy Policy at
                any time and for any reason. We will alert you about any changes
                by updating the “Last Updated” date of this Privacy Policy. Any
                changes or modifications will be effective immediately upon
                posting the updated Privacy Policy on the Site, and you waive
                the right to receive specific notice of each such change or
                modification. You are encouraged to periodically review this
                Privacy Policy to stay informed of updates. You will be deemed
                to have been made aware of, will be subject to, and will be
                deemed to have accepted the changes in any revised Privacy
                Policy by your continued use of the Site after the date such
                revised Privacy Policy is posted.
              </p>

              <h6 className="mt-5">
                <b>COLLECTION OF YOUR INFORMATION</b>
              </h6>

              <p>
                We may collect information about you in a variety of ways. The
                information we may collect on the Site includes:
              </p>

              <h6>
                <b>Personal Data</b>
              </h6>

              <p>
                Personally-identifiable information, such as your name, email
                address, and telephone number, and demographic information, such
                as your gender, that you voluntarily give to us when you
                register with the Site. You are under no obligation to provide
                us with personal information of any kind, however your refusal
                to do so may prevent you from using certain features of the
                Site.
              </p>

              <h6>
                <b>Derivative Data</b>
              </h6>

              <p>
                Personally-identifiable information, such as your name, email
                address, and telephone number, and demographic information, such
                as your gender, that you voluntarily give to us when you
                register with the Site. You are under no obligation to provide
                us with personal information of any kind, however your refusal
                to do so may prevent you from using certain features of the
                Site.
              </p>

              <h6>
                <b>Mobile Device Data</b>
              </h6>

              <p>
                Device information, such as your mobile device ID, model, and
                manufacturer, and information about the location of your device,
                if you access the Site from a mobile device.
              </p>

              <h6 className="mt-5">
                <b>USE OF YOUR INFORMATION</b>
              </h6>

              <p>
                Having accurate information about you permits us to provide you
                with a smooth, efficient, and customized experience.
                Specifically, we may use information collected about you via the
                Site to:
              </p>

              <ul>
                <li>Administer promotions.</li>
                <li>
                  Compile anonymous statistical data and analysis for use
                  internally or with third parties.
                </li>
                <li>Email you regarding your account or order.</li>
                <li>
                  Fulfill and manage purchases, orders, payments, and other
                  transactions related to the Site.
                </li>
                <li>Increase the efficiency and operation of the Site.</li>
                <li>
                  Monitor and analyze usage and trends to improve your
                  experience with the Site.
                </li>
                <li>Notify you of updates to the Site.</li>
                <li>Perform other business activities as needed.</li>
                <li>
                  Prevent fraudulent transactions, monitor against theft, and
                  protect against criminal activity.
                </li>
                <li>Process payments and refunds.</li>
                <li>
                  Request feedback and contact you about your use of the Site.
                </li>
                <li>Resolve disputes and troubleshoot problems.</li>
                <li>Respond to product and customer service requests.</li>
                <li>Solicit support for the Site.</li>
              </ul>

              <h6 className="mt-5">
                <b>DISCLOSURE OF YOUR INFORMATION</b>
              </h6>

              <p>
                We may share information we have collected about you in certain
                situations. Your information may be disclosed as follows:
              </p>

              <h6>
                <b>By Law or to Protect Rights</b>
              </h6>

              <p>
                If we believe the release of information about you is necessary
                to respond to legal process, to investigate or remedy potential
                violations of our policies, or to protect the rights, property,
                and safety of others, we may share your information as permitted
                or required by any applicable law, rule, or regulation. This
                includes exchanging information with other entities for fraud
                protection and credit risk reduction.
              </p>

              <h6>
                <b>Third-Party Service Providers</b>
              </h6>

              <p>
                We may share your information with third parties that perform
                services for us or on our behalf, including payment processing,
                data analysis, email delivery, hosting services, customer
                service, and marketing assistance.
              </p>

              <h6>
                <b>Sale or Bankruptcy</b>
              </h6>

              <p>
                If we reorganize or sell all or a portion of our assets, undergo
                a merger, or are acquired by another entity, we may transfer
                your information to the successor entity. If we go out of
                business or enter bankruptcy, your information would be an asset
                transferred or acquired by a third party. You acknowledge that
                such transfers may occur and that the transferee may decline
                honor commitments we made in this Privacy Policy.
              </p>

              <p>
                We are not responsible for the actions of third parties with
                whom you share personal or sensitive data, and we have no
                authority to manage or control third-party solicitations. If you
                no longer wish to receive correspondence, emails or other
                communications from third parties, you are responsible for
                contacting the third party directly.
              </p>

              <h6 className="mt-5">
                <b>TRACKING TECHNOLOGIES</b>
              </h6>

              <h6>
                <b>Cookies and Web Beacons</b>
              </h6>

              <p>
                We may use cookies, web beacons, tracking pixels, and other
                tracking technologies on the Site to help customize the Site and
                improve your experience. When you access the Site, your personal
                information is not collected through the use of tracking
                technology. Most browsers are set to accept cookies by default.
                You can remove or reject cookies, but be aware that such action
                could affect the availability and functionality of the Site. You
                may not decline web beacons. However, they can be rendered
                ineffective by declining all cookies or by modifying your web
                browser’s settings to notify you each time a cookie is tendered,
                permitting you to accept or decline cookies on an individual
                basis.
              </p>

              <h6>
                <b>Internet-Based Advertising</b>
              </h6>

              <p>
                Additionally, we may use third-party software to serve ads on
                the Site, implement email marketing campaigns, and manage other
                interactive marketing initiatives. This third-party software may
                use cookies or similar tracking technology to help manage and
                optimize your online experience with us.
              </p>

              <p>
                You should be aware that getting a new computer, installing a
                new browser, upgrading an existing browser, or erasing or
                otherwise altering your browser’s cookies files may also clear
                certain opt-out cookies, plug-ins, or settings.
              </p>

              <h6 className="mt-5">
                <b>THIRD-PARTY WEBSITES</b>
              </h6>

              <p>
                The Site may contain links to third-party websites and
                applications, including advertisements and external services ,
                that are not affiliated with us. Once you have used these links
                to leave the Site, any information you provide to these third
                parties is not covered by this Privacy Policy, and we cannot
                guarantee the safety and privacy of your information. Before
                visiting and providing any information to any third-party
                website, you should inform yourself of the privacy policies and
                practices (if any) of the third party responsible for that
                website, and should take those steps necessary to, in your
                discretion, protect the privacy of your information. We are not
                responsible for the content or privacy and security practices
                and policies of any third parties, including other sites,
                services, or applications that may be linked to or from the
                Site.
              </p>

              <h6 className="mt-5">
                <b>SECURITY OF YOUR INFORMATION</b>
              </h6>

              <p>
                We use administrative, technical, and physical security measures
                to help protect your personal information. While we have taken
                reasonable steps to secure the personal information you provide
                to us, please be aware that despite our efforts, no security
                measures are perfect or impenetrable, and no method of data
                transmission can be guaranteed against any interception or other
                type of misuse. Any information disclosed online is vulnerable
                to interception and misuse by unauthorized parties. Therefore,
                we cannot guarantee complete security if you provide personal
                information.
              </p>

              <h6 className="mt-5">
                <b>POLICY FOR CHILDREN</b>
              </h6>

              <p>
                We do not knowingly solicit information from or market to
                children under the age of 13. If you become aware of any data we
                have collected from children under age 13, please contact us
                using the contact information provided below.
              </p>

              <h6 className="mt-5">
                <b>CONTROLS FOR DO-NOT-TRACK FEATURES</b>
              </h6>

              <p>
                Most web browsers and some mobile operating systems include a
                Do-Not-Track (“DNT”) feature or setting you can activate to
                signal your privacy preference not to have data about your
                online browsing activities monitored and collected. No uniform
                technology standard for recognizing and implementing DNT signals
                has been finalized. As such, we do not currently respond to DNT
                browser signals or any other mechanism that automatically
                communicates your choice not to be tracked online. If a standard
                for online tracking is adopted that we must follow in the
                future, we will inform you about that practice in a revised
                version of this Privacy Policy.
              </p>

              <h6 className="mt-5">
                <b>OPTIONS REGARDING YOUR INFORMATION</b>
              </h6>

              <h6>
                <b>Emails and Communications</b>
              </h6>

              <p>
                If you no longer wish to receive correspondence, emails, or
                other communications from us, you may opt-out by:
                <ul>
                  <li>
                    Noting your preferences at the time you register your
                    account with the Site.
                  </li>
                  <li>
                    Logging into your account settings and updating your
                    preferences.
                  </li>
                  <li>
                    Contacting us using the contact information provided below.
                  </li>
                </ul>
              </p>

              <h6 className="mt-5">
                <b>CONTACT US</b>
              </h6>

              <p>
                If you have questions or comments about this Privacy Policy,
                please contact us at:
              </p>

              <p>
                The Flight Kitchen, LLC <br />
                2014 Electric Road, #247 <br />
                Roanoke, Virginia 24018 <br />
              </p>

              <p>
                Email address: <br />
                <a href="mailto:customerservice@theflightkitchen.com">
                  customerservice@theflightkitchen.com
                </a>
              </p>
            </Col>
          </Row>
        </div>
      </div>
      <FooterContainer />
    </>
  );
};

export default PrivacyPolicyPage;
