import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import AdminOrderItemCol from '../adminOrderItemCol';
import { getDateFormatted } from '../../helpers/utils';

import StatusBadge from '../statusBadge';
import { statusKey } from '../../constants/status';

const OrderCard = (props) => {
  const {
    hash,
    code,
    orderDate,
    flightDate: flightDateProp,
    deliveryTime,
    airport: ariportObject,
    airportTerminal: terminalObject,
    orderStatus: statusObject,
    cart,
    onClickCancelOrder,
  } = props;

  const { name: airport } = ariportObject;
  const { name: terminal } = terminalObject;
  const { name: statusName, code: statusCode } = statusObject;
  const { products } = cart;
  const orderDateFormatted = getDateFormatted(orderDate);
  const flightDate = getDateFormatted(flightDateProp);

  return (
    <>
      <Card className="order-card">
        <Card.Body>
          {/* Order Header */}
          <div className="order-header d-flex align-items-center">
            <StatusBadge code={statusCode} name={statusName} />
            <span className="flex-grow-1">Order # {code}</span>
            {/* {statusCode === statusKey.SCHEDULED && (
              <Link
                to="#"
                replace
                className="search-clear"
                onClick={() => onClickCancelOrder(hash)}
              >
                <i className="fas fa-times"></i>
              </Link>
            )} */}
          </div>

          {/* Order Info */}
          <div className="order-info-list">
            <div className="d-flex align-items-center">
              {/* Order Date */}
              <AdminOrderItemCol
                label={'Order Date'}
                value={orderDateFormatted}
              />

              {/* Flight Date */}
              <AdminOrderItemCol label={'Flight Date'} value={flightDate} />

              {/* Delivery Time */}
              <AdminOrderItemCol label={'Delivery Time'} value={deliveryTime} />
            </div>
          </div>

          {/* Airport */}
          <div className="airport">
            <i className="fas fa-plane"></i>
            <span>{airport}</span>
          </div>

          {/* Terminal */}
          <div className="terminal">
            <i className="fab fa-simplybuilt"></i>
            <span>{terminal}</span>
          </div>

          {/* Order Item */}
          <div className="order-item">
            {/* Products */}
            {products &&
              products.map((e, k) => (
                <OrderCardItem
                  key={`OrderCardOrderItemProduct_${code}_${e.hash}_${k}`}
                  {...e}
                />
              ))}
          </div>
        </Card.Body>

        {/* Footer */}
        <Card.Footer className="text-muted">
          {statusCode === statusKey.SCHEDULED && (
            <Link
              to="#"
              replace
              className="btn-icon primary-color"
              onClick={() => onClickCancelOrder(hash)}
            >
              Cancel Order
            </Link>
          )}

          {/* <Link to="#" replace className="btn-icon primary-color">
            View Order
          </Link>

          <Link to="#" replace className="btn-icon primary-color">
            Repeat Order
          </Link> */}
        </Card.Footer>
      </Card>
    </>
  );
};

const OrderCardItem = (e) => {
  const hideIngredients =
    e.ingredients.length === 1 && e.ingredients[0].name === 'Generic';

  return (
    <Row className="align-items-center no-gutters order-item-row">
      <Col>
        <div className="d-flex align-items-center">
          {/* Product Image */}
          <div className="order-item-image-container">
            <Image
              src={`${
                e.image
                  ? 'data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=='
                  : 'assets/images/logo-plane.svg'
              }`}
              className="order-item-image"
              style={{
                backgroundImage: `url(${e.image})`,
              }}
            />
          </div>
          {/* Product Name */}
          <span className="order-item-name">
            <span className="name">{e.name}</span>
            <span className="ingredients">
              {!hideIngredients &&
                e.ingredients
                  .filter((e) => e.isIncluded)
                  .map((e) => e.name)
                  .join(', ')}
            </span>
          </span>
        </div>
      </Col>

      <Col className="col-min-width d-none d-md-block">
        <span className="order-item-quantity">{e.cartQuantity}</span>
      </Col>
      <Col className="col-min-width order-item-price d-none d-md-block">
        <span>${e.cartPrice.toFixed(2)}</span>
      </Col>
    </Row>
  );
};

OrderCard.propTypes = {
  hash: PropTypes.string,
  code: PropTypes.string,
  orderDate: PropTypes.string,
  flightDate: PropTypes.string,
  deliveryTime: PropTypes.string,
  airport: PropTypes.object,
  airportTerminal: PropTypes.object,
  orderStatus: PropTypes.object,
  cart: PropTypes.object,
  onClickCancelOrder: PropTypes.func,
};

export default OrderCard;
