import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { Controller, useForm } from 'react-hook-form';
import { toKebabCase } from '../../helpers/utils';
import { getAll, create, update } from '../../api/category';
import FormCheck from '../formCheck';
import { useNotification, useFilter } from '../../hooks';

const AdminCatergoryModal = ({ show, hideModal }) => {
  const [originals, setOriginals] = useState();
  const [categories, setCategories] = useState();

  const {
    handleSubmit,
    control,
    formState: { errors },
    // setValue,
    reset,
    setError,
  } = useForm();

  const { fetchCategories } = useFilter();

  const { error, success } = useNotification();

  const onSubmit = (evt) => {
    const { name } = evt;
    const code = toKebabCase(name);
    const duplicated = categories.find((e) => e.code === code);

    if (duplicated) {
      setError(
        'name',
        { type: 'focus', message: 'errror' },
        { shouldFocus: true }
      );
    } else {
      setCategories([...categories, { code, name, isActive: true }]);
      reset();
    }
  };

  const handleHideModal = () => {
    reset();
    setCategories(originals);
    hideModal();
  };

  const showErrorNotification = (result) => {
    const {
      error: { message },
    } = result;

    error({ message });
  };

  const getAllCategories = async () => {
    const result = await getAll();
    setOriginals(result);
  };

  const createCategory = async (category) => {
    const result = await create(category);
    if (result && result.success) {
      success({ message: `Category ${category.code} created` });
    } else {
      showErrorNotification(result);
    }
  };

  const updateCategory = async (category) => {
    const result = await update(category, category.code);
    if (result && result.success) {
      success({ message: `Category ${category.code} updated` });
    } else {
      showErrorNotification(result);
    }
  };

  const handleClickSave = async () => {
    await Promise.all(
      categories.map(async (c) => {
        const existing = originals.find((o) => o.code === c.code);

        if (!existing) {
          return createCategory(c);
        }

        if (existing && existing.isActive !== c.isActive) {
          return updateCategory(c);
        }

        return Promise.resolve(`OK`);
      })
    );

    await getAllCategories();
    await fetchCategories();
    handleHideModal();
  };

  const handleCategoryClick = (category) => {
    const updated = categories.map((e) =>
      e.code === category.code
        ? { ...category, isActive: !category.isActive }
        : e
    );

    setCategories(updated);
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  useEffect(() => {
    setCategories(originals);
    return () => {
      setCategories([]);
    };
  }, [originals]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleHideModal}
        dialogClassName="admin-modals"
        className="admin-category-modal"
        centered
      >
        {/* Header */}
        <Modal.Header>
          <Modal.Title>
            <span className="close-modal" onClick={handleHideModal}>
              <i className="fas fa-times"></i>
            </span>
          </Modal.Title>
        </Modal.Header>

        {/* Body */}
        <Modal.Body>
          <p className="title">Edit Categories</p>

          <span className="d-block small m-0 mt-5">Categories</span>
          {categories &&
            categories.length > 0 &&
            categories.map((e) => (
              <FormCheck
                key={`AdminCategoryModal_Category_${e.code}`}
                label={e.name}
                isActive={e.isActive}
                onClick={() => handleCategoryClick(e)}
              />
            ))}

          <Form noValidate id="add-category-form">
            <Row>
              <Col xs={12}>
                {/* Category */}
                <Controller
                  name="name"
                  defaultValue=""
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <div className="select-element mt-4">
                      <Form.Group controlId="name">
                        <Form.Control
                          {...field}
                          isInvalid={!!errors.name}
                          placeholder="New Category"
                          autoComplete={`off`}
                        />

                        <Button
                          id={`add-category-button`}
                          className={`d-block`}
                          size={`sm`}
                          type={`submit`}
                          variant={`link`}
                          onClick={handleSubmit(onSubmit)}
                        >
                          Add
                        </Button>

                        <Form.Control.Feedback type="invalid">
                          {!!errors.name &&
                            errors.name.type === 'required' &&
                            'Category is required'}
                          {!!errors.name &&
                            errors.name.type === 'focus' &&
                            'Category already exist'}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  )}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-primary" size="md" onClick={handleHideModal}>
            Close
          </Button>
          <Button variant="primary" size="md" onClick={handleClickSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

AdminCatergoryModal.propTypes = {
  categories: PropTypes.array,
  show: PropTypes.bool,
  hideModal: PropTypes.func,
};

export default AdminCatergoryModal;
