import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useNotification } from '../../hooks';
import { forgotPassword } from '../../api/auth';

const ForgotPasswordPage = () => {
  // const history = useHistory();
  const { error, success } = useNotification();

  const [loading, setLoading] = useState();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    const result = await forgotPassword(data);
    setLoading(false);

    if (result.success) {
      success({
        message: `An email was sent to you inbox with a reset link.`,
      });
      setValue('email', '');
    } else {
      error({ message: `${result.error.message}` });
    }
  };

  return (
    <>
      <div className="login">
        <div className="login-container">
          <div className="header-login">
            <Link to="/">
              <Image src="assets/images/logo.svg" />
            </Link>
            <p className="logo-claim">Travel happy, not hungry</p>
          </div>

          {/* Forgot Password */}
          <div className="form forgot">
            <p className="forgot-title">Forgot password?</p>
            <p className="forgot-text">
              {`Enter the email address associated with your account.`}
            </p>
            <p className="forgot-description">
              {`We will email you a link to reset your password`}
            </p>
            <Form
              noValidate
              id="forgot-password-form"
              // onSubmit={handleSubmit(onSubmit)}
            >
              {/* Email */}
              <Controller
                name="email"
                defaultValue=""
                control={control}
                rules={{
                  required: true,
                  pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                }}
                render={({ field }) => (
                  <div className="select-element">
                    <Form.Group controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        {...field}
                        isInvalid={!!errors.email}
                        type="email"
                        placeholder="Enter your Email"
                      />
                      <Form.Control.Feedback type="invalid">
                        {!!errors.email &&
                          errors.email.type === 'required' &&
                          'Email is required'}
                        {!!errors.email &&
                          errors.email.type === 'pattern' &&
                          'Email is invalid'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              />

              {!loading && (
                <Button
                  id={`forgot-password-form`}
                  variant="primary"
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  Send
                </Button>
              )}

              {loading && (
                <Button variant="primary" type="submit" disabled={true}>
                  <i className="fas fa-circle-notch fa-spin"></i>
                </Button>
              )}
            </Form>
          </div>

          <div className="user-links d-flex justify-content-between">
            <Link className="btn btn-link btn-icon primary-color" to="/signin">
              Sign in
            </Link>
          </div>
        </div>

        <div className="clouds">
          <Image src="assets/images/clouds.svg" className="img-fluid" />
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordPage;
