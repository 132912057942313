import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import AdminOrderItemCol from '../adminOrderItemCol';
import AdminOrderItemIconCol from '../adminOrderItemCol/AdminOrderItemIconCol';
import { getDateFormatted, getTimeFormatted } from '../../helpers/utils';

function AdminOrderItem(props) {
  const {
    code,
    hash,
    orderDate,
    flightDate: deliveryDate,
    deliveryTime,
    airport,
    airportTerminal: terminal,
    isAccepted,
  } = props;

  const { code: airportCode } = airport;

  const { code: TerminalCode } = terminal;

  const history = useHistory();

  const onClickViewOrder = () => {
    history.push(`/admin/order/${hash}`);
  };

  return (
    <>
      <Card className="admin-order-item">
        <Card.Body>
          <div className="d-flex align-items-center">
            {/* Accepted */}
            <div>
              <AdminOrderItemIconCol
                label={''}
                value={isAccepted}
                iconTrue="fas fa-check"
                iconFalse="fas fa-check"
                iconTrueClass="accepted"
                iconFalseClass="not-accepted"
              />
            </div>

            {/* Number */}
            <div>
              <AdminOrderItemCol label={'Order Number'} value={code} />
            </div>

            {/* Date */}
            <div style={{ minWidth: '250px' }}>
              <AdminOrderItemCol
                label={'Order Date'}
                value={getDateFormatted(orderDate)}
              />
            </div>

            {/* Time */}
            <AdminOrderItemCol
              label={'Order Time'}
              value={getTimeFormatted(orderDate)}
            />

            {/* Delivery Date */}
            <div style={{ minWidth: '250px' }}>
              <AdminOrderItemCol
                label={'Delivery Date'}
                value={getDateFormatted(deliveryDate.replace('-', '/'))}
              />
            </div>

            {/* Delivery Time */}
            <AdminOrderItemCol label={'Delivery Time'} value={deliveryTime} />

            {/* Airport */}
            <AdminOrderItemCol label={'Airport'} value={airportCode} />

            {/* Terminal */}
            <AdminOrderItemCol label={'Terminal'} value={TerminalCode} />

            <div className="order-item-container ml-auto">
              <Button className="btn" onClick={onClickViewOrder}>
                View Order
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

AdminOrderItem.propTypes = {
  code: PropTypes.string,
  hash: PropTypes.string,
  orderDate: PropTypes.string,
  orderTime: PropTypes.string,
  flightDate: PropTypes.string,
  deliveryTime: PropTypes.string,
  airport: PropTypes.object,
  airportTerminal: PropTypes.object,
  isAccepted: PropTypes.bool,
};

export default AdminOrderItem;
