import { apiCall } from './base';

export const getFavorites = async () => {
  const result = await apiCall(`/customers/favorites`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
    // data,
  });
  return result.data;
};

export const addFavorite = async (productHash) => {
  const result = await apiCall(`/customers/favorites/${productHash}`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
    // data,
  });
  return result.data;
};

export const deleteFavorite = async (productHash) => {
  const result = await apiCall(`/customers/favorites/${productHash}`, {
    method: 'DELETE',
    withCredentials: true,
    immediately: true,
    // data,
  });
  return result.data;
};

export const getSearch = async () => {
  const result = await apiCall(`/customers/searches`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
  });
  return result.data;
};

export const addSearch = async (searchText) => {
  const result = await apiCall(`/customers/searches`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
    data: { searchText },
  });
  return result.data;
};

export const getCreditCards = async () => {
  const result = await apiCall(`/customers/credit-card`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
  });
  return result.data;
};

export const addCreditCard = async (data) => {
  const result = await apiCall(`/customers/credit-card`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
    data,
  });
  return result;
};

export const delCreditCard = async (id) => {
  const result = await apiCall(`/customers/credit-card/${id}`, {
    method: 'DELETE',
    withCredentials: true,
    immediately: true,
  });
  return result;
};

export const addImage = async (data) => {
  const result = await apiCall(`/customers/image`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
    data,
  });
  return result.data;
};

export const editCustomer = async (data) => {
  const result = await apiCall(`/customers`, {
    method: 'PUT',
    withCredentials: true,
    immediately: true,
    data,
  });
  return result;
};

export const getDefaultAirport = async (data) => {
  const result = await apiCall(`/customers/default-airport`, {
    method: 'GET',
    withCredentials: true,
    immediately: true,
    data,
  });
  return result.data;
};

export const setDefaultAirport = async (data) => {
  const result = await apiCall(`/customers/default-airport`, {
    method: 'POST',
    withCredentials: true,
    immediately: true,
    data,
  });
  return result;
};
