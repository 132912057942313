/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { MobileView, BrowserView } from 'react-device-detect';
import { useFilter } from '../../hooks';

import CateringPanServesTitle from './CateringPanServesTitle';
import CateringDeckContainer from './CateringDeckContainer';

const CateringDeck = ({ products }) => {
  const [subCategories, setSubCaterogies] = useState();
  const { categorySelected: catering, fetchOneCategory } = useFilter();

  useEffect(() => {
    let isSubscribed = true;

    const init = async () => {
      if (isSubscribed) {
        const cateringCategory = await fetchOneCategory(catering);
        setSubCaterogies(cateringCategory?.childrens);
      }
    };

    init();

    return () => {
      isSubscribed = false;
    };
  }, []);

  return (
    <>
      <div className="catering-deck">
        {/* Desktop View */}
        <BrowserView>
          <div className="page-container catering-deck-container desktop">
            <CateringPanServesTitle />
            <CateringDeckContainer
              subCategories={subCategories}
              products={products}
            />
          </div>
        </BrowserView>

        {/* Mobile View */}
        <MobileView>
          <CateringPanServesTitle customClassNames={'mt-3 mb-0'} />
          <CateringDeckContainer
            subCategories={subCategories}
            products={products}
          />
        </MobileView>
      </div>
    </>
  );
};

export default CateringDeck;
