import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { useNotification } from '../../hooks';
import { validate } from '../../api/discount';

const DiscountCode = ({ onSelect }) => {
  const {
    handleSubmit,
    register,
    // control,
    formState: { errors },
    // setValue,
  } = useForm();

  const { success, error } = useNotification();

  const [discountCoupon, setDiscountCoupon] = useState();

  const onSubmit = async (code) => {
    const { discountCode: appliedCode } = code;
    const result = await validate(appliedCode);
    if (result.success) {
      setDiscountCoupon(result.data);
      onSelect(result.data);
      success({ message: 'Discount code applied!' });
    } else {
      const {
        error: { message },
      } = result;
      error({ message });
    }
  };

  return (
    <>
      <Card className="discount-code">
        <Card.Body>
          <h2>Discount Code</h2>
          <p>Enter your coupon code if you have one!</p>

          <Form
            className="discount-form"
            noValidate
            id="discount-code-apply"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Form.Control
              type="text"
              defaultValue={discountCoupon}
              placeholder={`${isMobile ? 'Enter code' : ''}`}
              readOnly={!!discountCoupon}
              {...register('discountCode', { required: true })}
            />
            <Form.Control.Feedback type="invalid">
              {!!errors.discountCode &&
                errors.discountCode.type === 'required' &&
                'Please enter your discount code'}
            </Form.Control.Feedback>

            {/* Apply Button */}
            <Button
              variant={isMobile ? `link` : `outline-primary`}
              size="sm"
              className={isMobile ? `d-block` : `d-sm-flex`}
              disabled={discountCoupon}
              id="discount-code-apply"
              type="submit"
            >
              Apply code
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

DiscountCode.propTypes = {
  discountCode: PropTypes.string,
  onSelect: PropTypes.func,
};

// DiscountCode.propDefault = {
//   discountCode: "",
// };

export default DiscountCode;
