import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

const AdminTableRowSkeleton = ({ cols = 5, rows = 4, width = 150 }) => {
  const myCols = Array.from(Array(cols).keys());
  const myRows = Array.from(Array(rows).keys());

  return (
    <>
      {myRows.map((_, k) => (
        <div className={`d-flex admin-table-row-skeleton`} key={k * 100}>
          {myCols.map((_, j) => (
            <Skeleton key={j * 10} width={width} />
          ))}
        </div>
      ))}
    </>
  );
};

AdminTableRowSkeleton.propTypes = {
  cols: PropTypes.number,
  rows: PropTypes.number,
  width: PropTypes.string,
};

export default AdminTableRowSkeleton;
