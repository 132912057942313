import React from 'react';
import HeaderLanding from '../../components/headerLanding';
import FooterLanding from '../../components/footerLanding';
import ComingSoon from '../../components/comingSoon';

function LandingSoon() {
  return (
    <>
      <HeaderLanding isHome={true}></HeaderLanding>
      <ComingSoon />
      <FooterLanding></FooterLanding>
    </>
  );
}

export default LandingSoon;
