import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import {
  BrowserView,
  // isDesktop, isMobile
} from 'react-device-detect';
import HeaderLanding from '../../components/headerLanding';
import NowSchedule from '../../components/nowSchedule';
import WhatWheDo from '../../components/whatWheDo';
import OurFavoritesLanding from '../../components/ourFavoritesLanding';
import AlreadyMove from '../../components/alreadyMove';
import FooterLanding from '../../components/footerLanding';
import { getTopPicks } from '../../api/product';
// import ProductDeck from '../../components/productDeck';
// import classNames from 'classnames';
import { useUser } from '../../hooks';

function LandingPage() {
  const [topPicks, setTopPicks] = useState();
  const { isUserLoggedIn, user } = useUser();

  // const productDeckClass = classNames({
  //   'page-container': true,
  //   'about-us': true,
  //   'p-2': isDesktop,
  //   'p-0': isMobile,
  // });

  useEffect(() => {
    const init = async () => {
      const result = await getTopPicks();
      setTopPicks(result);
    };
    init();
  }, []);

  return (
    <>
      <HeaderLanding
        isHome={true}
        isUserLoggedIn={isUserLoggedIn}
        user={user}
      ></HeaderLanding>
      <NowSchedule></NowSchedule>
      <WhatWheDo></WhatWheDo>

      <OurFavoritesLanding list={topPicks}></OurFavoritesLanding>

      {/* <div className="our-favorites-landing">
        <div className="our-favorites-landing-container pb-0">
          <h3>OUR MENU</h3>
          <h2>Pick your favorite one</h2>
        </div>
      </div>

      <div className={`main container container-fluid my-0`}>
        <div className={productDeckClass}>
          <ProductDeck onlyFavorites={false} displayOnLanding={true} />
        </div>
      </div> */}

      {/* Browser view */}
      <BrowserView>
        <AlreadyMove></AlreadyMove>
      </BrowserView>

      <FooterLanding></FooterLanding>
    </>
  );
}

export default LandingPage;
