import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useFilter } from '../../hooks';
import { Link, useLocation } from 'react-router-dom';
import { isMobile, isBrowser, MobileView } from 'react-device-detect';
import { Button, Modal, Row, Col } from 'react-bootstrap';

const SearchFilter = () => {
  const { showSearchModal, setShowSearchModal } = useFilter();

  return (
    <>
      {/* Desktop Search */}
      {isBrowser && <Filter />}

      {/* Mobile Search Modal */}
      <MobileView>
        <Modal
          show={showSearchModal}
          onHide={() => setShowSearchModal(false)}
          dialogClassName="profile-modal"
          size="xl"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <i
                className="fas fa-arrow-left"
                onClick={() => setShowSearchModal(false)}
              ></i>
              Search
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              {/* Product Name */}
              <Col xs={12} md={12}>
                <h3>
                  <div className="filter-modal">
                    <Filter />
                  </div>
                </h3>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button
              form="search-form"
              type="submit"
              variant="primary"
              size="md"
              className="btn btn-primary"
            >
              Search
            </Button>
          </Modal.Footer>
        </Modal>
      </MobileView>
    </>
  );
};

const Filter = () => {
  const {
    setSearchText,
    searchText,
    onSeachTextSubmit,
    setShowSearchModal,
    categorySelected,
  } = useFilter();

  const { handleSubmit, setValue, control } = useForm();

  const { pathname } = useLocation();

  const onSubmit = (value) => {
    const { text } = value;
    onSeachTextSubmit(text);
    if (isMobile) setShowSearchModal(false);
  };

  const onClearClick = () => {
    setValue('text', '');
    onSubmit('');
  };

  useEffect(() => {
    setValue('text', '');
    setSearchText('');
  }, [pathname, categorySelected]);

  return (
    <div className="search-filter">
      <div className="search-filter-container">
        <Form noValidate id="search-form" onSubmit={handleSubmit(onSubmit)}>
          {searchText && (
            <Link to="#" replace className="search-clear">
              <i className="fas fa-times" onClick={onClearClick}></i>
            </Link>
          )}

          {!searchText && (
            <Link to="#" replace className="search-icon">
              <i className="fas fa-search" onClick={handleSubmit(onSubmit)}></i>
            </Link>
          )}

          <Controller
            name="text"
            control={control}
            defaultValue={searchText}
            render={({ field }) => (
              <Form.Control
                className="form-control-search"
                placeholder="Search item"
                autoComplete="off"
                {...field}
              />
            )}
          />
          {/* <i className="fas fa-search search-icon"></i> */}
        </Form>

        {/* Search History */}
        {/* <div
      className={`suggest ${searchHistory.length ? 'd-block' : 'd-none'}`}
    >
      {searchHistory &&
        searchHistory.map((e, k) => (
          <div className="suggest-item" key={k}>
            <i className="far fa-clock"></i>
            <span className="matching-key">{e}</span>
          </div>
        ))}
    </div> */}
      </div>
    </div>
  );
};

export default SearchFilter;
