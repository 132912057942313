import React from 'react';
import PropTypes from 'prop-types';
import CateringProduct from './CateringProduct';

const CateringProductList = ({ subCategory, products }) => {
  const filteredProducts = products.filter(
    (e) => e?.category?.code === subCategory?.code
  );

  return (
    <>
      {filteredProducts.map((product) => (
        <CateringProduct
          key={`CateringProduct_${product.hash}`}
          hash={product.hash}
          name={product.name}
          description={product.description}
          price={product.price}
          ingredients={product.ingredients}
        />
      ))}
    </>
  );
};

CateringProductList.propTypes = {
  subCategory: PropTypes.object,
  products: PropTypes.array,
};

export default CateringProductList;
