import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import CardDeck from 'react-bootstrap/CardDeck';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNotification } from '../../hooks';
import Popup from '../../components/popup';
import PopupSaving from '../../components/popupSaving';

import {
  getOrdersFull,
  // getStatus,
  cancelOrder,
} from '../../api/order';
// import { statusKey } from "../../constants/status";

import EmptyOrderPage from '../../assets/images/my-orders.svg';
import EmptyPage from '../../components/emptyPage';
// import CategoryFilter from "../../components/categoryFilter";
// import CategorySkeleton from "../../components/categorySkeleton";
import OrderCard from '../orderCard';
import AdminOrderItemColSkeleton from '../../components/adminOrderItemColSkeleton';

const OrderDeck = () => {
  const { success, error } = useNotification();
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [saving, setSaving] = useState();
  const [popup, setPopup] = useState();
  const history = useHistory();
  // const [status, setStatus] = useState([]);
  // const [statusSelected, setStatusSelected] = useState(statusKey.SCHEDULED);

  // const fetchStatus = async () => {
  //   const status = await getStatus();
  //   setStatus(status);
  // };

  // const onStatusSelected = async (selectedStatus) => {
  //   setLoading(true);
  //   setStatusSelected(selectedStatus);
  //   await fetchOrders(selectedStatus);
  //   setTimeout(() => setLoading(false), 400);
  // };

  const fetchOrders = async (statusId) => {
    const orders = await getOrdersFull(statusId);
    setOrders(orders);
  };

  const onClickViewMenu = () => {
    history.push('/menu');
  };

  const onClickCancelOrder = async (hash) => {
    setPopup({
      show: true,
      content: `Are you sure you want to <b class="text-danger">CANCEL</b> this order?`,
      primaryButton: {
        text: `Yes`,
        onClick: () => handleCancelOrder(hash),
      },
      secondaryButton: {
        text: `No`,
        onClick: () => setPopup({ show: false }),
      },
    });
  };

  const handleCancelOrder = async (hash) => {
    setPopup({ show: false });
    setSaving(true);
    const result = await cancelOrder(hash);
    setTimeout(async () => {
      setSaving(false);
      result.success
        ? success({ message: `Order cancelled succesfully` })
        : error({ message: `${result.error.message} Contact support.` });
      setSaving(false);
      await fetchOrders('ALL');
    }, 4000);
  };

  const emptyPageProps = {
    image: EmptyOrderPage,
    title: "You haven't placed any orders yet.",
    subtitle: 'When you do, their status will appear here.',
    primaryButtonText: 'View Menu',
    primaryButtonOnClick: onClickViewMenu,
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // await fetchStatus();
      // setStatusSelected(statusSelected);
      // await fetchOrders(statusSelected);
      await fetchOrders('ALL');
      setTimeout(() => setLoading(false), 400);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Popup */}
      {popup && <Popup {...popup} />}

      {/* Save Loading */}
      {saving && (
        <PopupSaving show={saving} content={`We're cancelling your order...`} />
      )}

      {/* Status Filter */}
      {/* <div className="d-flex align-items-center justify-content-between filter-bar">
        {loading && !status.length ? (
          <CategorySkeleton />
        ) : (
          <CategoryFilter
            list={status}
            selected={statusSelected}
            onSelected={onStatusSelected}
          />
        )}
      </div> */}

      <CardDeck className="order-deck">
        {/* Loading */}
        {loading && (
          <>
            <OrderCardSkeleton />
            <OrderCardSkeleton />
            <OrderCardSkeleton />
            <OrderCardSkeleton />
            <OrderCardSkeleton />
          </>
        )}

        {/* Orders */}
        {!loading &&
          orders.length > 0 &&
          orders.map((e) => (
            <OrderCard
              key={`OrderDeckOrderCard_${e.code}`}
              {...e}
              onClickCancelOrder={onClickCancelOrder}
            />
          ))}

        {/* Empty Page */}
        {!loading && orders.length === 0 && <EmptyPage {...emptyPageProps} />}
      </CardDeck>
    </>
  );
};

const OrderCardSkeleton = () => (
  <Card className="order-card">
    <Card.Body>
      {/* Order Header */}
      <div className="order-header d-flex align-items-center">
        <div className="">
          <Skeleton width={80} />
        </div>
        <span className="flex-grow-1">
          <Skeleton width={70} />
        </span>
        <Link to="#" replace className="search-clear">
          <i className="fas fa-times"></i>
        </Link>
      </div>

      {/* Order Info */}
      <div className="order-info-list">
        <div className="d-flex align-items-center">
          {/* Order Date */}
          <AdminOrderItemColSkeleton width={70} />

          {/* Flight Date */}
          <AdminOrderItemColSkeleton width={70} />

          {/* Delivery Time */}
          <AdminOrderItemColSkeleton width={70} />
        </div>
      </div>

      {/* Airport */}
      <div className="airport">
        {/* <i className="fas fa-plane"></i> */}
        <span>
          <Skeleton />
        </span>
      </div>

      {/* Terminal */}
      <div className="terminal">
        {/* <i className="fab fa-simplybuilt"></i> */}
        <span>
          <Skeleton />
        </span>
      </div>

      {/* Order Item */}
      <div className="order-item">
        <OrderCardItemSkeleton />
        <OrderCardItemSkeleton />
        <OrderCardItemSkeleton />
      </div>
    </Card.Body>

    {/* Footer */}
    <Card.Footer className="text-muted">
      <Link to="#" replace className="btn-icon primary-color">
        <Skeleton width={80} />
      </Link>
      <Link to="#" replace className="btn-icon primary-color">
        <Skeleton width={80} />
      </Link>
    </Card.Footer>
  </Card>
);

const OrderCardItemSkeleton = () => (
  <>
    <Row className="align-items-center no-gutters admin-order-detail-item">
      <Col>
        <div className="d-flex align-items-center my-2">
          <div className="order-item-image-container">
            <Skeleton circle={true} height={50} width={50} />
          </div>

          <span className="admin-order-detail-item-name">
            <span className="name">
              <Skeleton height={18} width={210} />
              <Skeleton
                height={18}
                width={30}
                className="ml-4 d-none d-md-inline-block"
              />
              <Skeleton
                height={18}
                width={30}
                className="ml-4 d-none d-md-inline-block"
              />
            </span>
            <span className="ingredients">
              <Skeleton height={14} width={120} />
            </span>
          </span>
        </div>
      </Col>
    </Row>
  </>
);

export default OrderDeck;
