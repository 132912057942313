import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { MobileView, BrowserView } from 'react-device-detect';
import QuantitySelector from '../quantitySelector';
import IngredientSelector from '../ingredientSelector';
import { useCart, useNotification, useUser } from '../../hooks';
import { useHistory } from 'react-router-dom';

const CateringProduct = ({ hash, name, description, price, ingredients }) => {
  const { addItem } = useCart();
  const { isUserLoggedIn } = useUser();
  const history = useHistory();
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const { warning: warningMessage } = useNotification();

  const handleAddToCart = () => {
    const addItemParams = {
      product: { hash, name, description, price, ingredients },
      selectedQuantity,
      selectedIngredients,
    };

    const isGeneric =
      ingredients.length === 1 && ingredients[0].code === 'generic';

    if (
      !isGeneric &&
      Array.isArray(selectedIngredients) &&
      selectedIngredients.length === 0
    ) {
      return warningMessage({ message: 'Need to select your choice' });
    }

    if (isUserLoggedIn) {
      addItem(addItemParams);
    } else {
      history.push({
        pathname: '/signin',
        state: addItemParams,
      });
    }
  };

  const handleIngredientChange = (ingredients) => {
    setSelectedIngredients(ingredients);
  };

  const handleQuantityChange = (quantity) => {
    setSelectedQuantity(quantity);
  };

  return (
    <>
      {/* Desktop View */}
      <BrowserView>
        <div>
          <Row className="align-items-center cart-item">
            <Col>
              <div className="align-items-center">
                {/* Product Name */}
                <span className="cart-item-name">
                  <span className="name">{name}</span>
                  <span className="ingredients">{description}</span>
                </span>

                {ingredients.length === 1 &&
                ingredients[0].code === 'generic' ? (
                  ''
                ) : (
                  <div className="cateringIngredients">
                    <IngredientSelector
                      value={ingredients}
                      selected={selectedIngredients}
                      onChange={handleIngredientChange}
                    />
                  </div>
                )}
              </div>
            </Col>

            <Col className="col-min-width">
              <QuantitySelector
                value={1}
                onChange={handleQuantityChange}
                className="d-inline"
              ></QuantitySelector>
            </Col>

            <Col className="col-min-width">
              <span>${price.toFixed(2)}</span>
            </Col>

            <Col className="col-2">
              <Button className="btn m-0" onClick={handleAddToCart}>
                Add to cart
              </Button>
            </Col>
          </Row>
        </div>
      </BrowserView>

      {/* Mobile View */}
      <MobileView>
        <Card className="product-card">
          <Card.Body>
            <Card.Title>
              <Row className="no-gutters">
                <Col xs={8} md={8}>
                  <span>{name}</span>
                </Col>
                <Col xs={4} md={4} className="d-block d-sm-none">
                  <p className="item-price">${price.toFixed(2)}</p>
                </Col>
              </Row>
            </Card.Title>

            <Card.Text>{description}</Card.Text>

            {ingredients.length === 1 && ingredients[0].code === 'generic' ? (
              ''
            ) : (
              <div className="cateringIngredients">
                <IngredientSelector
                  value={ingredients}
                  selected={selectedIngredients}
                  onChange={handleIngredientChange}
                  isVertical={false}
                  isSmall={true}
                />
              </div>
            )}
          </Card.Body>

          <Card.Footer>
            <Row className="no-gutters">
              <Col className="col-4 my-auto">
                <QuantitySelector
                  value={1}
                  onChange={handleQuantityChange}
                  className="d-inline"
                ></QuantitySelector>
              </Col>

              <Col className="text-right">
                <Button className="btn m-0" onClick={handleAddToCart}>
                  Add to cart
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </MobileView>
    </>
  );
};

CateringProduct.propTypes = {
  hash: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  ingredients: PropTypes.array,
};

export default CateringProduct;
