import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

const PaymentDetails = (params) => {
  let { subtotal, taxes, taxRate, discount, total } = params;
  subtotal = subtotal?.toFixed(2);
  taxes = taxes?.toFixed(2);
  discount = discount?.toFixed(2);
  total = total?.toFixed(2);

  return (
    <>
      <Card className="payment-details">
        <Card.Body>
          <h2>Payment Details</h2>
          <ListGroup variant="flush">
            {/* Subtotal */}
            <ListGroup.Item>
              <span>Subtotal</span>
              <span className="float-right">{`$${subtotal}`}</span>
            </ListGroup.Item>

            {/* Taxes */}
            <ListGroup.Item>
              <span>Taxes {taxRate && `(${taxRate}%)`}</span>
              <span className="float-right">{`$${taxes}`}</span>
            </ListGroup.Item>

            {/* Discount */}
            <ListGroup.Item>
              <span>Discount</span>
              <span className="float-right">{`-$${discount}`}</span>
            </ListGroup.Item>

            {/* Total */}
            <ListGroup.Item className="payment-total">
              <span>Total</span>
              <span className="float-right">{`$${total}`}</span>
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
    </>
  );
};

PaymentDetails.propTypes = {
  params: PropTypes.object,
};

export default PaymentDetails;
