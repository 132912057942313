import React, { useMemo } from 'react';
import { useToggle } from '../hooks';

export const MenuContext = React.createContext();

export const MenuProvider = (props) => {
  const [sideNavOpened, setSideNavOpened] = useToggle();

  const options = useMemo(
    () => ({
      sideNavOpened,
      setSideNavOpened,
    }),
    [sideNavOpened, setSideNavOpened]
  );

  return (
    <MenuContext.Provider
      value={options}
      {...props}
      displayName="MenuContext"
    />
  );
};
