import React from 'react';
import PropTypes from 'prop-types';

function AdminOrderItemCol(props) {
  const { label, value } = props;

  return (
    <>
      <div className="admin-order-item-col">
        <div className="admin-order-item-container">
          <p className="admin-order-item-name">{label}</p>
          <p className="admin-order-item-value">{value}</p>
        </div>
      </div>
    </>
  );
}

AdminOrderItemCol.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AdminOrderItemCol;
