import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormSelect from '../formSelect';
import FormInput from '../formInput';
import NoIngredientSelectionAlert from '../../components/noIngredientSelectionAlert';
import { getDefaultAirport } from '../../api/customer';

const Pickup = (props) => {
  const { airports, onSelect } = props;

  const [airport, setAirport] = useState();
  const [terminal, setTerminal] = useState();
  const [flight, setFlight] = useState();
  const [flightDate, setFlightDate] = useState();
  const [flightTime, setFlightTime] = useState();
  const [deliveryTime, setDeliveryTime] = useState();
  const [airportProps, setAirportProps] = useState([]);
  const [terminalProps, setTerminalProps] = useState([]);
  const [deliveryTimeProps, setDeliveryTimeProps] = useState([]);
  const [flightDateProps, setFlightDateProps] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const [notes, setNotes] = useState();

  function addHours(numOfHours, date = new Date()) {
    const dateCopy = new Date(date.getTime());
    dateCopy.setTime(dateCopy.getTime() + numOfHours * 60 * 60 * 1000);
    return dateCopy;
  }

  function isSameDate(a, b) {
    if (
      a.getDate() === b.getDate() &&
      a.getMonth() === b.getMonth() &&
      a.getFullYear() === b.getFullYear()
    ) {
      return true;
    }
    return false;
  }

  const formatDate = (date) => {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    const yyyy = date.getFullYear();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return `${mm}-${dd}-${yyyy}`;
  };

  const getNextDates = (nextDays, closing) => {
    nextDays = nextDays ?? 32;

    const today = new Date();
    const todayPlus12hours = addHours(12, today);
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    let initialDate;

    isSameDate(today, todayPlus12hours) && todayPlus12hours.getHours() < closing
      ? (initialDate = today)
      : (initialDate = tomorrow);

    let result = [];
    for (let i = 0; i < nextDays; i++) {
      const date = new Date(initialDate.getTime());
      date.setDate(date.getDate() + i);
      result.push({ id: formatDate(date), text: formatDate(date) });
    }
    return result;
  };

  // const flightDateProps = {
  //   label: 'Flight Date',
  //   options: getNextDates(),
  // };

  const getNextTimes = (start, end, interval) => {
    const today = new Date();
    const todayPlus12hours = addHours(12, today);
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    let initialHour;

    if (
      isSameDate(today, todayPlus12hours) &&
      todayPlus12hours.getHours() < end
    ) {
      initialHour = today.getHours();
    } else {
      initialHour = start;
    }

    const x = interval || 30; //minutes interval
    let times = []; // time array
    // const today = new Date();
    // let tt = today.getHours() * 60 + 60; // start time
    let tt = initialHour * 60 || 0; // start time
    const ap = ['AM', 'PM']; // AM-PM

    //loop to increment the time and push results in array
    for (let i = 0; tt < (end || 24) * 60; i++) {
      const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      const mm = tt % 60; // getting minutes of the hour in 0-55 format
      const hours = ('0' + (hh % 12)).slice(-2);
      const timeData =
        (hours === '00' ? '12' : hours) +
        ':' +
        ('0' + mm).slice(-2) +
        ' ' +
        ap[Math.floor(hh / 12)];

      times[i] = { id: timeData, text: timeData };
      tt = tt + x;
    }

    return times;
  };

  const flightTimeProps = {
    label: 'Flight Time (optional)',
    options: getNextTimes(),
  };

  // const deliveryTimeProps = {
  //   label: 'Delivery Time',
  //   options: getNextTimes(),
  // };

  const updateAirportSelect = () => {
    const airportProps = {
      label: 'Airport',
      options: airports?.map((e) => ({ id: e.id, text: e.name })),
    };
    setAirportProps(airportProps);
  };

  const updateTerminalSelect = () => {
    const a = airports.filter((a) => a.id === +airport)[0];
    const options = a?.terminals.map((t) => ({ id: t.id, text: t.name }));

    const terminalProps = {
      label: 'Terminal',
      options,
    };
    setTerminalProps(terminalProps);
  };

  const updateDeliveryTimeSelect = () => {
    // TODO: Move working hours and interval to the database
    if (airport) {
      const options = {
        label: 'Delivery Time',
        options:
          parseInt(airport) === 1
            ? getNextTimes(7, 18, 60)
            : getNextTimes(7.5, 17, 60),
      };
      setDeliveryTimeProps(options);
    } else {
      setDeliveryTimeProps({
        label: 'Delivery Time',
        options: [],
      });
    }
  };

  const updateFlightDateSelect = () => {
    if (airport) {
      const options = {
        label: 'Flight Date',
        options:
          parseInt(airport) === 1 ? getNextDates(32, 20) : getNextDates(32, 19),
      };

      setFlightDateProps(options);
    } else {
      setFlightDateProps({
        label: 'Flight Date',
        options: [],
      });
    }
  };

  useEffect(() => {
    updateTerminalSelect();
    updateFlightDateSelect();
    updateDeliveryTimeSelect();
    // setTerminal(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airport]);

  useEffect(() => {
    onSelect({
      airport,
      terminal,
      flightDate,
      flightTime,
      deliveryTime,
      flight,
      notes,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airport, terminal, flightDate, flightTime, deliveryTime, flight, notes]);

  useEffect(() => {
    updateAirportSelect();
    updateTerminalSelect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airports]);

  useEffect(() => {
    if (airports.length > 0 && airport && terminal) {
      const a = airports.find((a) => a.id === +airport);
      const t = a.terminals.find((t) => t.id === +terminal);
      !t || !t.allowsProductIngredients
        ? setShowWarning(true)
        : setShowWarning(false);
    } else {
      setShowWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airport, terminal]);

  useEffect(() => {
    const init = async () => {
      const {
        airport: defaultAirport,
        airportTerminal: defaultAirportTerminal,
      } = await getDefaultAirport();

      if (defaultAirport) setAirport(defaultAirport);
      if (defaultAirportTerminal) setTerminal(defaultAirportTerminal);
    };
    init();
  }, []);

  return (
    <>
      <Card className="pickup">
        <Card.Body>
          <h2>Pickup</h2>
          <div className="pickup-list">
            <Row>
              {/* Airport */}
              <Col xs={12} sm={6}>
                <FormSelect
                  {...airportProps}
                  value={airport}
                  onChange={(val) => {
                    setAirport(val);
                    setTerminal('');
                  }}
                />
              </Col>

              {/* Terminal */}
              <Col xs={12} sm={6}>
                <FormSelect
                  {...terminalProps}
                  value={terminal}
                  onChange={(val) => setTerminal(val)}
                />
              </Col>
            </Row>

            {/* No Ingredient Selection Alert */}
            {showWarning && (
              <Row>
                <Col xs={12}>
                  <NoIngredientSelectionAlert />
                </Col>
              </Row>
            )}

            <Row>
              {/* Flight Date */}
              <Col xs={12} sm={6}>
                <FormSelect
                  {...flightDateProps}
                  onChange={(val) => setFlightDate(val)}
                />
              </Col>

              {/* Delivery Time */}
              <Col xs={12} sm={6}>
                <FormSelect
                  {...deliveryTimeProps}
                  onChange={(val) => setDeliveryTime(val)}
                />
              </Col>

              {/* Flight Time */}
              <Col xs={6} sm={3} style={{ display: 'none' }}>
                <FormSelect
                  {...flightTimeProps}
                  onChange={(val) => setFlightTime(val)}
                />
              </Col>

              {/* Flight number */}
              <Col xs={6} sm={3} style={{ display: 'none' }}>
                <FormInput
                  label="Flight (optional)"
                  // controlId={"FlightNumber"}
                  placeholder="eg. BA2490"
                  onChange={(evt) => setFlight(evt.target.value)}
                />
              </Col>
            </Row>

            <Row>
              {/* Notes */}
              <Col xs={12} sm={6}>
                <FormInput
                  label="Notes"
                  // controlId={"FlightNumber"}
                  placeholder="eg. Any comment?"
                  onChange={(evt) => setNotes(evt.target.value)}
                />
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

Pickup.propTypes = {
  airports: PropTypes.array,
  onSelect: PropTypes.func,
};

export default Pickup;
