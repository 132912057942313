import React, { useState, useEffect } from 'react';
import { MobileView, BrowserView } from 'react-device-detect';
import {
  getOrders,
  getStatus,
  deliverOrder,
  notDeliverOrder,
} from '../../api/order';
import EmptyPageImage from '../../assets/images/my-delivery.svg';
import { statusKey } from '../../constants/status';
import { exportToTop } from '../../helpers/utils';
import { useNotification } from '../../hooks';
import CategoryFilter from '../../components/categoryFilter';
import CategorySkeleton from '../../components/categorySkeleton';
import DeliveryCard from '../../components/deliveryCard';
import DeliveryCardSkeleton from '../../components/deliveryCardSkeleton';
import EmptyPage from '../../components/emptyPage';
import Popup from '../../components/popup';
import PopupSaving from '../../components/popupSaving';
import DeliveryRejectModal from '../../components/deliveryRejectModal';
import { getAirports } from '../../api/airport';

const DeliveryPage = () => {
  const { success, error } = useNotification();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState([]);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [statusSelected, setStatusSelected] = useState(statusKey.PREPARED);
  const [saving, setSaving] = useState();
  const [popup, setPopup] = useState();
  const [rejectModal, setRejectModal] = useState();

  const [airports, setAirports] = useState([]);
  const [airportSelected, setAirportSelected] = useState('all');

  const emptyPageProps = {
    image: EmptyPageImage,
    title: `You have no pending deliveries at the moment`,
    // subtitle: "When you do, their status will appear here.",
    // primaryButtonText: "View Menu",
    // primaryButtonOnClick: onClickViewMenu,
  };

  const fetchAirports = async () => {
    const airports = await getAirports();
    setAirports(airports);
  };

  const fetchOrders = async (statusId) => {
    const orders = await getOrders(statusId, '', 'ASC');
    setOrders(orders);
    setFilteredOrders(orders);
  };

  const fetchStatus = async () => {
    const status = await getStatus();
    setStatus(
      status.sort((a, b) =>
        a.order > b.order ? 1 : b.order > a.order ? -1 : 0
      )
    );
  };

  const onStatusSelected = async (selectedStatus) => {
    setLoading(true);
    setStatusSelected(selectedStatus);
    await fetchOrders(selectedStatus);
    setTimeout(() => setLoading(false), 400);
  };

  const onAirportSelected = async (selected) => {
    setLoading(true);
    setAirportSelected(selected);
    filterOrders(selected);
    setTimeout(() => setLoading(false), 400);
  };

  const filterOrders = (selected) => {
    if (selected === 'all') {
      setFilteredOrders(orders);
    } else {
      let filtered = orders.filter((e) => e.airport.code === selected);
      setFilteredOrders(filtered);
    }
  };

  const handleAcceptOrder = async (hash) => {
    setPopup({ show: false });
    setSaving(true);
    const result = await deliverOrder(hash);
    setTimeout(async () => {
      setSaving(false);
      result.success
        ? success({ message: `Order delivered succesfully` })
        : error({ message: `${result.error.message} Contact support.` });
      setSaving(false);
      await fetchOrders(statusSelected);
    }, 4000);
  };

  const handleRejectOrder = async (hash) => {
    setRejectModal({ show: false });
    setSaving(true);
    const result = await notDeliverOrder(hash);
    setTimeout(async () => {
      setSaving(false);
      result.success
        ? success({ message: `Order not delivered succesfully` })
        : error({ message: `${result.error.message} Contact support.` });
      setSaving(false);
      await fetchOrders(statusSelected);
    }, 4000);
  };

  const onClickAccept = async (hash) => {
    setPopup({
      show: true,
      content: `Are you sure you want to <b class="text-success">ACCEPT</b> this order?`,
      primaryButton: {
        text: `Yes`,
        onClick: () => handleAcceptOrder(hash),
      },
      secondaryButton: {
        text: `No`,
        onClick: () => setPopup({ show: false }),
      },
    });
  };

  const onClickReject = async (order) => {
    setRejectModal({
      show: true,
      order,
      primaryButton: {
        text: `Yes`,
        onClick: () => handleRejectOrder(order.hash),
      },
      secondaryButton: {
        text: `No`,
        onClick: () => setRejectModal({ show: false }),
      },
    });
  };

  useEffect(() => {
    exportToTop();

    const fetchData = async () => {
      setLoading(true);
      await fetchStatus();
      await fetchAirports();
      // setStatusSelected(statusSelected);
      // await fetchOrders(statusSelected);
      await fetchOrders('PREPARED');
      setTimeout(() => setLoading(false), 400);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MobileView>
        {/* Reject Modal */}
        {rejectModal && <DeliveryRejectModal {...rejectModal} />}

        {/* Confirmation Popup */}
        {popup && <Popup {...popup} />}

        {/* Savinng Popup */}
        {saving && (
          <PopupSaving
            show={saving}
            content={`We're processing your order...`}
          />
        )}

        {/* Status Filter */}
        <div className="d-flex align-items-center justify-content-between filter-bar">
          {loading && !status.length ? (
            <>
              <CategorySkeleton />
            </>
          ) : (
            <CategoryFilter
              list={status}
              selected={statusSelected}
              onSelected={onStatusSelected}
            />
          )}
        </div>

        {/* Airport & Date Filter */}
        <div className="d-flex align-items-center justify-content-between filter-bar">
          {loading && !airports.length ? (
            <>
              <CategorySkeleton />
            </>
          ) : (
            <CategoryFilter
              list={airports}
              selected={airportSelected}
              onSelected={onAirportSelected}
              showAll={true}
              showAllText="All Airports"
            />
          )}
        </div>

        <div className="page-container">
          {/* Loading */}
          {loading && (
            <>
              <DeliveryCardSkeleton />
              <DeliveryCardSkeleton />
              <DeliveryCardSkeleton />
              <DeliveryCardSkeleton />
              <DeliveryCardSkeleton />
            </>
          )}

          {/* Delivery List */}
          {!loading &&
            filteredOrders.length > 0 &&
            filteredOrders.map((e) => (
              <DeliveryCard
                key={`DeliveryDeckDeliveryCard_${e.hash}`}
                {...e}
                onClickAccept={onClickAccept}
                onClickReject={onClickReject}
              />
            ))}

          {/* Empty Page */}
          {!loading && filteredOrders.length === 0 && (
            <EmptyPage {...emptyPageProps} />
          )}
        </div>
      </MobileView>

      <BrowserView>
        <EmptyPage
          title="Oops! Delivery page is not allowed from desktop"
          subtitle="Please try to use this page from your mobile."
        />
      </BrowserView>
    </>
  );
};

export default DeliveryPage;
