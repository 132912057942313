import React, { useEffect } from 'react';
import { isBrowser, isTablet } from 'react-device-detect';
import FaqList from '../../components/faqList';
import { HeaderContainer } from '../../components/headerContainer';
import { FooterContainer } from '../../components/footerContainer';
import { exportToTop } from '../../helpers/utils';
import { useUser } from '../../hooks';

const FaqPage = () => {
  const { user } = useUser();

  useEffect(() => {
    exportToTop();
  }, []);

  return (
    <>
      <HeaderContainer />
      <div
        className={
          user ? `main container container-fluid py-1` : `main container`
        }
      >
        <div className="page-container my-5 p-2">
          {(isBrowser || isTablet || !user) && (
            <h1 className="page-title mb-4">FAQ</h1>
          )}
          <FaqList />
        </div>
      </div>
      <FooterContainer />
    </>
  );
};

export default FaqPage;
