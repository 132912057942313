import React, { useEffect } from 'react';
import { exportToTop } from '../../helpers/utils';
import OrderDeck from '../../components/orderDeck';

const OrderPage = () => {
  useEffect(() => {
    exportToTop();
  }, []);

  return (
    <>
      <div className="page-container order-page">
        <h1 className="page-title">My Orders</h1>
        <OrderDeck />
      </div>
    </>
  );
};

export default OrderPage;
