import React, { useState, useMemo } from 'react';
import { useNotification } from '../hooks';
// import { isMobile } from "react-device-detect";
import { getCart, setItem, removeItem } from '../api/cart';

export const CartContext = React.createContext();

export const CartProvider = (props) => {
  const [productModal, setProductModal] = useState(false);
  // const [showItemAdded, setShowItemAdded] = useState(false);
  const [productDetail, setProductDetail] = useState({});
  const [cart, setCart] = useState({});

  const { success } = useNotification();

  const cartItems = (cart?.products && cart?.products?.length) || 0;

  const options = useMemo(() => {
    const showProductModal = () => {
      setProductModal(true);
    };

    const hideProductModal = () => {
      setProductModal(false);
    };

    const fetchCart = async () => {
      try {
        const cart = await getCart();
        setCart(cart);
      } catch (error) {
        console.log(`error`, error);
      }
    };

    const addItem = async ({
      product,
      selectedQuantity,
      selectedIngredients,
      hideToast,
    }) => {
      try {
        const { hash, price } = product;

        const ingredients = product?.ingredients?.map((e) => {
          e.isIncluded = selectedIngredients.includes(e.code);
          return e;
        });

        const actualQuantity = getProductQuantity(hash, ingredients);

        const params = {
          quantity: actualQuantity + selectedQuantity,
          price,
          ingredients,
        };

        const cart = await setItem(hash, params);
        setCart(cart);

        if (!hideToast) success({ message: 'Item added to cart.' });

        // This show the add-item layer (big blue one)
        // which for the moment will not be used.
        //
        // if (isMobile) {
        //   setShowItemAdded(true);
        //   setTimeout(() => {
        //     setShowItemAdded(false);
        //   }, 2000);
        // } else {
        //   if (!hideToast) success({ message: "Item added to cart." });
        // }
      } catch (error) {
        console.log(`error`, error);
      }
    };

    const editItem = async ({
      product,
      selectedQuantity,
      selectedIngredients,
      hideToast,
    }) => {
      try {
        const { hash, price } = product;

        const ingredients = product?.ingredients?.map((e) => {
          e.isIncluded = selectedIngredients.includes(e.code);
          return e;
        });

        const params = {
          quantity: selectedQuantity,
          price,
          ingredients,
        };

        const cart = await setItem(hash, params);
        setCart(cart);

        if (!hideToast) success({ message: 'Item added to cart.' });
      } catch (error) {
        console.log(`error`, error);
      }
    };

    const deleteItem = async ({ product }) => {
      try {
        const { hash } = product;
        const cart = await removeItem(hash);
        setCart(cart);
      } catch (error) {
        console.log(`error`, error);
      }
    };

    const getPaymentDetails = (discountCoupon) => {
      const calculateSubtotal = () => {
        const reducer = (accumulator, product) =>
          accumulator + product.cartQuantity * product.cartPrice;

        const result = cart?.products?.reduce(reducer, 0);
        return result;
      };

      const calculateDiscount = () => {
        if (!discountCoupon) return 0;
        return (subtotal * discountCoupon.percentage) / 100;
      };

      const calculateTaxes = () => {
        return ((subtotal - discount) * 7.25) / 100;
      };

      const subtotal = calculateSubtotal();
      const discount = calculateDiscount();
      const taxes = calculateTaxes();
      const total = subtotal + taxes - discount;

      return {
        subtotal,
        taxes,
        taxRate: 7.25,
        discount,
        total,
      };
    };

    const getIncludedIngredients = (ingredients) =>
      ingredients.filter((e) => e.isIncluded).map((e) => e.code);

    const areSameIngredients = (ingredientListA, ingredientListB) =>
      Array.isArray(ingredientListA) &&
      Array.isArray(ingredientListB) &&
      ingredientListA.length === ingredientListB.length &&
      ingredientListA.every((val, index) => val === ingredientListB[index]);

    const getProductQuantity = (hash, ingredients) => {
      const selectedIngredients = getIncludedIngredients(ingredients);

      const product = cart?.products?.find((p) => {
        const cartProductIngredients = getIncludedIngredients(p.ingredients);

        const sameIngredients = areSameIngredients(
          selectedIngredients,
          cartProductIngredients
        );

        return p.hash === hash && sameIngredients;
      });

      return product?.cartQuantity || 0;
    };

    return {
      // Modal
      productModal,
      showProductModal,
      // showItemAdded,
      hideProductModal,
      // Product Detail
      productDetail,
      setProductDetail,
      // Cart
      cart,
      cartItems,
      addItem,
      editItem,
      deleteItem,
      fetchCart,
      getProductQuantity,
      // Payment Details
      getPaymentDetails,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cart,
    cartItems,
    productDetail,
    productModal,
    // showItemAdded,
    success,
  ]);

  return (
    <CartContext.Provider
      value={options}
      {...props}
      displayName="CartContext"
    />
  );
};
