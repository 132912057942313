import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Image, Dropdown } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useUser, useMenu, useCart, useFilter } from '../../hooks';
import { roleKey } from '../../constants/role';

const Header = () => {
  const { user, role, logout } = useUser();
  const { menu } = role;
  const { pathname } = useLocation();
  const activeKey = pathname.substring(1);
  const { setSideNavOpened } = useMenu();
  const { cartItems, fetchCart } = useCart();
  const { setShowSearchModal, searchText } = useFilter();

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const [isUp, setIsUp] = useState(true);

  const history = useHistory();

  const isCustomer = role.code.toUpperCase() === roleKey.CUSTOMER;

  const getPageName = () => {
    switch (pathname) {
      case '/checkout':
        return 'Checkout';
      case '/faq':
        return 'FAQs';
      case '/contact-us':
        return 'Contact Us';
      case '/about-us':
        return 'About Us';
      case '/where-we-operate':
        return 'We Operate In';
      case '/delivery':
        return 'My Deliveries';
      default:
        break;
    }

    return menu.find((e) => e.code === activeKey)?.name;
  };

  const onClickCheckoutBack = () => {
    history.push('/cart');
  };

  useEffect(() => {
    setScrollTop(0);
    setLastScrollTop(0);
    setIsUp(true);
    if (isCustomer) {
      fetchCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY);
      scrollTop < lastScrollTop
        ? setIsUp(true)
        : scrollTop > 110
        ? setIsUp(false)
        : false;
      setLastScrollTop(scrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop, scrollTop]);

  return (
    <>
      <header className={`header ${isUp ? 'scrolled-up' : 'scrolled-down'}`}>
        <Navbar
          id="mySideNav"
          className="header-main"
          collapseOnSelect
          expand="lg"
          bg="secondary"
          variant="dark"
        >
          <Button variant="link" className="header-button">
            {pathname === '/checkout' ? (
              <i
                className="fas fa-arrow-left"
                onClick={onClickCheckoutBack}
              ></i>
            ) : (
              <i className="fas fa-bars" onClick={setSideNavOpened}></i>
            )}
          </Button>
          <span className="mr-auto page-name">{getPageName()}</span>

          <Navbar.Brand href="#/">
            <Image className="d-none d-lg-block" src="assets/images/logo.svg" />
          </Navbar.Brand>

          {/* Menu Mobile */}
          {isCustomer && (
            <ul className="nav justify-content-end mobile-menu">
              <li className="nav-item">
                <a className="nav-link btn btn-icon">
                  <i
                    className={`fas fa-search ${
                      searchText ? 'viking-color' : ''
                    }`}
                    onClick={() => {
                      setShowSearchModal(true);
                    }}
                  ></i>
                </a>
              </li>

              {/* <li className="nav-item">
              <a className="nav-link btn btn-icon">
                <i className="fas fa-bell"></i>
              </a>
            </li> */}

              <li className="nav-item">
                <a className="nav-link profile" href="#/profile">
                  <div className="profile-picture">
                    <Image
                      src={
                        user.image
                          ? user.image
                          : 'assets/images/user-avatar-mobile.svg'
                      }
                      roundedCircle
                      className="user-avatar"
                    />
                  </div>
                </a>
              </li>
            </ul>
          )}

          {/* Menu Desktop */}
          <Navbar.Collapse id="basic-navbar-nav" className="menu-desktop">
            <Nav className="mx-md-auto" activeKey={activeKey}>
              {menu &&
                menu
                  .filter((e) => e.orderDesktop && e.orderDesktop < 100)
                  .sort((a, b) => a.orderDesktop - b.orderDesktop)
                  .map((e, k) => (
                    <Nav.Link href={`#/${e.code}`} eventKey={e.code} key={k}>
                      {e.name}
                    </Nav.Link>
                  ))}
            </Nav>

            <div className="mr-0">
              {isCustomer && (
                <div className="shopping-cart-notification">
                  <Button variant="link" href="#/cart" className="btn-header">
                    <i className="fas fa-shopping-cart"></i>
                  </Button>

                  <div
                    className={`badge-notification ${
                      cartItems ? 'd-block' : 'd-none'
                    }`}
                  >
                    <span>{cartItems}</span>
                  </div>
                </div>
              )}

              {/* <div className="shopping-cart-notification notification-icon">
                <Button
                  variant="link"
                  href="#/notification"
                  className="btn-header"
                >
                  <i className="fas fa-bell"></i>
                </Button>

                <div className="badge-notification">
                  <span>5</span>
                </div>
              </div> */}

              <Dropdown className="d-inline dropdown-margin">
                <Dropdown.Toggle variant="link" id="dropdown-basic">
                  <Image
                    src={
                      user.image
                        ? user.image
                        : 'assets/images/user-avatar-desktop.svg'
                    }
                    roundedCircle
                    className="user-avatar"
                  />
                  {`${user.firstName} ${user.lastName}`}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {menu &&
                    menu
                      .filter((e) => e.orderDesktop && e.orderDesktop > 100)
                      .sort((a, b) => a.orderDesktop - b.orderDesktop)
                      .map((e, k) => (
                        <Dropdown.Item
                          href={`#/${e.code}`}
                          eventKey={e.code}
                          key={k}
                        >
                          {e.name}
                        </Dropdown.Item>
                      ))}
                  <Dropdown.Item
                    href="#/login"
                    eventKey="logout"
                    key="logut"
                    onClick={logout}
                  >
                    Log out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </header>
    </>
  );
};

export default Header;
