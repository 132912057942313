import { useContext } from 'react';
import { NotificationContext } from '../contexts/notification';

const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context)
    throw new Error(
      'useNotification hook must be used under the notification context'
    );

  return context;
};

export default useNotification;
