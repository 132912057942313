import React from 'react';

const FlightPage = () => {
  return (
    <>
      <div className="page-container flight">
        <h1>Flights Page</h1>
        {/* <div className="ticket">
          <div className="ticket_body" id="ticket_body">
            <div className="ticket_section">
              <div className="divCheck">
                <input id="chkDeuda0" type="checkbox" name="iDeuda" />
                <label htmlFor="chkDeuda0"></label>
              </div>
              <div className="divTexto">
                <span className="deuda" id="spanDeuda">
                  Cuota 06 2021
                </span>
              </div>
              <div clas="divMonto">
                <span className="deudaTotal">
                  $ <span id="spanDeuda0">15.979</span>
                </span>
              </div>
              <div className="deudaVencidadDetalle">
                <span className="deudaVto">Vto 15/07/2021</span>
              </div>
            </div>
          </div>
          <div className="ticket_divider">
            <div className="ticket_notch"></div>
            <div className="ticket_notch ticket_notch--right"></div>
          </div>
          <div className="ticket_footer" id="ticket_footer">
            <label id="totalPagar">Total a pagar</label>
            <label id="montoValor">
              ${" "}
              <span className="monto" id="deudaTotalPagar">
                15.979,00
              </span>
            </label>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default FlightPage;
