import React from 'react';
import { Nav, Image, Button } from 'react-bootstrap';
import { useMenu, useUser } from '../../hooks';

const SideMenu = () => {
  const { sideNavOpened, setSideNavOpened } = useMenu();
  const { user, role, logout } = useUser();
  const { menu } = role;

  const handleLogout = () => {
    setSideNavOpened();
    logout();
  };

  return (
    <>
      {/* Mobile */}
      <div className={`sidenav-container ${sideNavOpened ? 'open' : ''}`}>
        <div className="sidenav-overlay" onClick={setSideNavOpened}></div>
        <div id="mySidenav" className="sidenav">
          <div className="profile">
            <div className="profile-picture">
              <Image
                src={
                  user.image
                    ? user.image
                    : 'assets/images/user-avatar-mobile.svg'
                }
                roundedCircle
                className="user-avatar"
              />
            </div>
            <div className="profile-data">
              <span className="profile-name">{`${user.firstName} ${user.lastName}`}</span>
              <span>{`${user.email}`}</span>
            </div>

            <Button
              variant="link"
              className="closebtn d-lg-none"
              onClick={setSideNavOpened}
            >
              <i className="fas fa-bars"></i>
            </Button>
          </div>

          <Nav className="flex-column menu">
            {menu &&
              menu
                .sort((a, b) => a.orderMobile - b.orderMobile)
                .map((e, k) => (
                  <Nav.Link
                    key={k}
                    href={`#/${e.code}`}
                    eventKey={e.code}
                    onClick={setSideNavOpened}
                  >
                    <i className={e.icon}></i>
                    {e.name}
                  </Nav.Link>
                ))}

            {/* Logout */}
            <Nav.Link eventKey="logout" onClick={handleLogout}>
              <i className="fas fa-sign-out-alt"></i>
              <span>Log out</span>
            </Nav.Link>
          </Nav>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
