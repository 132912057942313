import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import AdminProductForm from '../adminProductForm';

const AdminProductModal = ({
  hash,
  name,
  description,
  price,
  grams,
  calories,
  category,
  categories,
  image,
  ingredients,
  show,
  hideModal,
  isLoading,
  actions: { create, edit },
}) => {
  const handleProductForm = (product) => {
    const isEdit = !!name;
    isEdit ? edit(product) : create(product);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={hideModal}
        dialogClassName="admin-modals"
        centered
      >
        {/* Header */}
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="close-modal" onClick={hideModal}>
              <i className="fas fa-times"></i>
            </span>
          </Modal.Title>
        </Modal.Header>

        {/* Body */}
        <Modal.Body>
          <Container>
            <p className="title">Edit Menu item</p>

            {/* Admin Product Form */}
            <AdminProductForm
              hash={hash}
              name={name}
              description={description}
              price={price}
              grams={grams}
              calories={calories}
              category={category}
              categories={categories}
              image={image}
              ingredients={ingredients}
              onSubmit={handleProductForm}
            />
          </Container>
        </Modal.Body>

        {/* Footer */}
        <Modal.Footer>
          <Button variant="outline-primary" size="md" onClick={hideModal}>
            Close
          </Button>
          <Button
            form="product-form"
            type="submit"
            variant="primary"
            size="md"
            disabled={isLoading}
          >
            {isLoading ? 'Saving ...' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

AdminProductModal.propTypes = {
  hash: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  grams: PropTypes.number,
  calories: PropTypes.number,
  category: PropTypes.object,
  categories: PropTypes.array,
  image: PropTypes.string,
  ingredients: PropTypes.array,
  show: PropTypes.bool,
  hideModal: PropTypes.func,
  actions: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default AdminProductModal;
