import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import { useCart } from '../../hooks';
import { getCreditCards } from '../../api/customer';
import { getAirports } from '../../api/airport';
import { placeOrder } from '../../api/order';

import CheckoutItem from '../../components/checkoutItem';
import DiscountCode from '../../components/discountCode';
import PaymentDetails from '../../components/paymentDetails';
import Pickup from '../../components/pickup';
import CreditCardDetail from '../../components/creditCardDetail';
import CreditCardList from '../../components/creditCardList';
import PopupSaving from '../../components/popupSaving';
import { useNotification } from '../../hooks';

const CheckoutPage = () => {
  const { cart, cartItems, getPaymentDetails } = useCart();
  const history = useHistory();
  const [paymentDetails, setPaymentDetails] = useState({});
  const [creditCardModal, setCreditCardModal] = useState(false);
  const [creditCards, setCreditCards] = useState([]);
  const [creditCardSelected, setCreditCardSelected] = useState();
  const [pickup, setPickup] = useState({});
  const [error, setError] = useState('');
  const [airports, setAirports] = useState([]);
  const [saving, setSaving] = useState();
  const { error: errorMessage, warning } = useNotification();
  const [discountCoupon, setDiscountCoupon] = useState();

  const fetchCreditCards = async () => {
    const list = await getCreditCards();
    setCreditCards(list);
  };

  const fetchAirports = async () => {
    const list = await getAirports();
    setAirports(list);
  };

  const showCreditCardModal = () => {
    setCreditCardModal(true);
  };

  const hideCreditCardModal = () => {
    fetchCreditCards();
    setCreditCardModal(false);
  };

  const onCreditCardSelected = (val) => {
    setCreditCardSelected(val);
  };

  const handleDiscountCodeSelect = (val) => {
    setDiscountCoupon(val);
    const details = getPaymentDetails(val);
    setPaymentDetails(details);
  };

  const handlePlaceOrder = async () => {
    const order = {
      cart,
      creditCardSelected,
      pickup,
      discountCoupon,
    };

    // checkCart();
    if (!cartItems) history.push('/menu');
    if (!cart) history.push('/menu');

    // checkPickup();
    if (!pickup.airport) return setError('No airport selected');
    if (!pickup.terminal) return setError('No terminal selected');
    if (!pickup.flightDate) return setError('No flight date selected');
    if (!pickup.deliveryTime) return setError('No delivery time selected');

    if (
      (parseInt(pickup.terminal) === 8 || parseInt(pickup.terminal) == 9) &&
      !pickup.notes
    ) {
      return setError('Tell us where to deliver your order');
    }

    if (paymentDetails.subtotal < 10)
      return setError('Order minimum amount is $10');

    // checkPayment();
    if (!creditCardSelected) return setError('No credit card selected');

    // call place order endpoint
    try {
      setSaving(true);
      const result = await placeOrder({ ...order });

      setTimeout(() => {
        setSaving(false);
        if (result && result.data && result.data.hash) {
          history.push(`/order/placed/${result.data.hash}`);
        } else {
          // history.push(`/menu`);
          errorMessage({ message: result?.error?.message });
        }
      }, 4000);
    } catch (error) {
      console.log(`error`, error);
    }
  };

  useEffect(() => {
    if (cart && !cartItems) history.push('/menu');

    const details = getPaymentDetails(discountCoupon);
    setPaymentDetails(details);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  useEffect(() => {
    if (error) warning({ message: error });
    setError('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    Promise.all([fetchCreditCards(), fetchAirports()]);
  }, []);

  return (
    <>
      {/* Credit Card Modal */}
      <CreditCardDetail show={creditCardModal} onHide={hideCreditCardModal} />

      {/* Save Loading */}
      {saving && (
        <PopupSaving
          show={saving}
          content={`We're preparing everything for your purchase...`}
        />
      )}

      {/* Checkout Page */}
      <div className="page-container checkout">
        {/* Desktop View */}
        <div>
          {/* Title and Buttons */}
          <Row className="row-cols-2 d-none d-sm-flex">
            <Col>
              <h1>Checkout</h1>
              <h2>You currently have {cartItems} item(s) in your cart.</h2>
            </Col>
            <Col className="buttons-container">
              <Link className="btn btn-md btn-outline-primary" to="cart">
                Modify order
              </Link>

              <Link
                className="btn btn-md btn-primary"
                to="#"
                replace
                onClick={handlePlaceOrder}
              >
                Place order
              </Link>
            </Col>
          </Row>

          {/* Checkout List */}
          <Card className="checkout-card-list d-none d-sm-block">
            <div className="checkout-list">
              {/* Checkout Item */}
              <div className="card-body">
                {/* Cart Item */}
                {cart?.products?.length &&
                  cart?.products?.map((e, k) => (
                    <CheckoutItem
                      key={`CheckoutCartItem_${k}`}
                      product={e}
                    ></CheckoutItem>
                  ))}
              </div>
            </div>
          </Card>

          {/* Pickup */}
          <Row>
            <Col>
              <Pickup airports={airports} onSelect={(val) => setPickup(val)} />
            </Col>
          </Row>

          {/* Payment - Credit Cards*/}
          <Row>
            <Col>
              <Card className="payment">
                <Card.Body>
                  <h2>Payment</h2>
                  {/* Payment List */}
                  <CreditCardList
                    creditCards={creditCards}
                    onSelect={onCreditCardSelected}
                  />

                  {/* Add New Card */}
                  <div className="payment-list">
                    <ToggleButtonGroup name="CreditCardAddList" type="checkbox">
                      <ToggleButton id={`CreditCardAdd`} value={99}>
                        <Card
                          className="payment-item"
                          onClick={showCreditCardModal}
                        >
                          <div className="payment-container-description">
                            <p className="payment-name">Add New Card</p>
                            <div className="payment-description d-flex justify-content-between align-items-center">
                              <Image
                                className="payment-item-image"
                                src="assets/icons/new-card.svg"
                              />
                            </div>
                          </div>
                          <div className="payment-check">
                            <i className="far fa-square"></i>
                          </div>
                        </Card>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            {/* Discount Code */}
            <Col xs={12} sm={6}>
              <DiscountCode onSelect={handleDiscountCodeSelect} />
            </Col>

            {/* Payment Details */}
            <Col xs={12} sm={6}>
              <PaymentDetails {...paymentDetails} />
            </Col>
          </Row>
        </div>

        {/* Mobile view */}
        <div className="d-sm-none d-block buttom-mobile-container">
          <div className="buttom-mobile-box">
            <Link
              className="btn btn-primary"
              to="#"
              replace
              onClick={handlePlaceOrder}
            >
              Place Order
            </Link>
          </div>
        </div>

        {/* Desktop view */}
        {/* Buttons at the Bottom */}
        <Row className="row-cols-2 d-none d-sm-flex mt-5">
          <Col>
            {/* <h1>Checkout</h1>
            <h2>You currently have {cartItems} item(s) in your cart.</h2> */}
          </Col>
          <Col className="buttons-container">
            <Link className="btn btn-md btn-outline-primary" to="cart">
              Modify order
            </Link>

            <Link
              className="btn btn-md btn-primary"
              to="#"
              replace
              onClick={handlePlaceOrder}
            >
              Place order
            </Link>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CheckoutPage;
