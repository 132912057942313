import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';

const CreditCardList = (props) => {
  const { creditCards, onSelect } = props;
  const [value, setValue] = useState([]);

  const handleChange = (val) => {
    const selected = creditCards.filter((e) => e.id === val);
    onSelect(selected[0]);
    setValue(val);
  };

  return (
    <>
      <div className="payment-list">
        <ToggleButtonGroup
          name="CreditCardList"
          type="radio"
          value={value}
          onChange={handleChange}
          className="d-flex flex-wrap"
        >
          {Array.isArray(creditCards) &&
            creditCards.length > 0 &&
            creditCards.map((e, k) => (
              <ToggleButton id={`CreditCard_${e.id}`} value={e.id} key={k}>
                <Card className="payment-item">
                  <div className="payment-container-description">
                    <p className="payment-name">{e.brand}</p>
                    <div className="payment-description d-flex align-items-center">
                      <Image
                        className="payment-item-image"
                        src={`assets/icons/${e.brand}.svg`}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = `assets/icons/no-credit-card.svg`;
                        }}
                      />
                      <span className="payment-item-number ml-5">{`****${e.last4}`}</span>
                    </div>
                  </div>
                  <div className="payment-check">
                    <i
                      className={`${
                        e.id === value ? 'fas fa-check-square' : 'far fa-square'
                      }`}
                    ></i>
                  </div>
                </Card>
              </ToggleButton>
            ))}
        </ToggleButtonGroup>
      </div>
    </>
  );
};

CreditCardList.propTypes = {
  creditCards: PropTypes.array,
  onSelect: PropTypes.func,
};

export default CreditCardList;
