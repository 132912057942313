import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const AdminProductItem = (params) => {
  const {
    hash,
    name,
    description,
    price,
    image,
    grams,
    calories,
    category,
    ingredients,
  } = params;

  const { actions: { remove, showForm } = {} } = params;

  const handleEdit = () => {
    showForm({
      hash,
      name,
      description,
      price,
      image,
      grams,
      calories,
      category,
      ingredients,
    });
  };

  const handleDelete = () => {
    remove(hash, name);
  };

  const [now] = useState(Date.now());

  return (
    <>
      <Card className="admin-product-item">
        <Card.Img
          variant="top"
          // src="data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
          className="card-image"
          style={{ backgroundImage: `url(${image}?${now})` }}
          onClick={handleEdit}
        />

        {/* Body */}
        <Card.Body>
          <Card.Title>{name}</Card.Title>
          <Card.Text>{description}</Card.Text>
        </Card.Body>

        <Card.Footer className="text-muted">
          <Container fluid>
            <Row className="no-gutters">
              <Col xs={12}>
                <p className="item-price">${price.toFixed(2)}</p>
              </Col>

              <Col xs={12} size="sm" className="text-right">
                <Button
                  variant="btn"
                  className="btn-outline-primary btn-sm"
                  onClick={handleDelete}
                >
                  Delete
                </Button>
                <Button
                  variant="btn"
                  className="btn-primary btn-sm"
                  onClick={handleEdit}
                >
                  Edit
                </Button>
              </Col>
            </Row>
          </Container>
        </Card.Footer>
      </Card>
    </>
  );
};

AdminProductItem.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  image: PropTypes.string,
  actions: PropTypes.object,
};

export default AdminProductItem;
