import React, { useEffect } from 'react';
import { exportToTop } from '../../helpers/utils';
import ProductDeck from '../../components/productDeck';
import OnboardingModal from '../../components/onboardingModal';

const MenuPage = () => {
  useEffect(() => {
    exportToTop();
  }, []);

  return (
    <>
      <ProductDeck
        onlyFavorites={false}
        displayOnLanding={false}
        excludeCatering={true}
      />
      <OnboardingModal />
    </>
  );
};

export default MenuPage;
