import React from 'react';
import { Button } from 'react-bootstrap';
import { MobileView, BrowserView } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../hooks';

const NowSchedule = () => {
  const history = useHistory();
  const { isUserLoggedIn } = useUser();

  const onGetStartedClick = () =>
    history.push(isUserLoggedIn ? `menu` : `/public-menu`);

  return (
    <>
      <div className="now-schedule">
        <div className="now-schedule-container">
          <div className="now-schedule-description">
            <h2>
              <span>You’ve&nbsp;</span>
              scheduled your travel.
              <span className="text-bold">Now schedule your meal.</span>
            </h2>
            <p>
              Select a meal or snacks from our menu and we will have it ready
              for you to pick up at the airport.
            </p>

            {/* Mobile view */}
            <div className="text-center">
              <MobileView>
                <Button size={`lg`} onClick={onGetStartedClick}>
                  Get Started
                </Button>
              </MobileView>
            </div>

            <BrowserView>
              <Button className={`d-block`} onClick={onGetStartedClick}>
                Get Started
              </Button>
            </BrowserView>
          </div>
        </div>
      </div>
    </>
  );
};

export default NowSchedule;
