import React from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Skeleton from 'react-loading-skeleton';

const PaymentDetailsSkeleton = () => {
  return (
    <>
      <Card className="payment-details">
        <Card.Body>
          <h2>Payment Details</h2>
          <ListGroup variant="flush">
            {/* Subtotal */}
            <ListGroup.Item>
              <span>
                <Skeleton width={80} />
              </span>
              <span className="float-right">
                <Skeleton width={50} />
              </span>
            </ListGroup.Item>

            {/* Taxes */}
            <ListGroup.Item>
              <span>
                <Skeleton width={80} />
              </span>
              <span className="float-right">
                <Skeleton width={50} />
              </span>
            </ListGroup.Item>

            {/* Discount */}
            <ListGroup.Item>
              <span>
                <Skeleton width={80} />
              </span>
              <span className="float-right">
                <Skeleton width={50} />
              </span>
            </ListGroup.Item>

            {/* Total */}
            <ListGroup.Item className="payment-total">
              <span>
                <Skeleton width={100} />
              </span>
              <span className="float-right">
                <Skeleton width={70} />
              </span>
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
    </>
  );
};

export default PaymentDetailsSkeleton;
