import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import { BrowserView, MobileView } from 'react-device-detect';

import CreditCardForm from '../creditCardForm';
import CreditCardAuthorizeForm from '../../components/creditCardAuthorizeForm';
import authorize from '../../services/authorize';
import { PAYMENT_GATEWAY } from '../../config';
import { paymentGateway } from '../../constants/paymentGateway';
import { useNotification } from '../../hooks';
import { addCreditCard } from '../../api/customer';

const CreditCardDetail = (props) => {
  const { show, onHide } = props;
  const { error, success } = useNotification();

  const successCallback = async (res) => {
    try {
      const { opaqueData } = res;

      const result = await addCreditCard(opaqueData);

      if (result?.success) {
        success({ message: 'Card added successfully' });
        onHide();
      } else {
        error({ message: result?.error?.message });
      }
    } catch (error) {
      console.log('successCallback Error', error);
    }
  };

  const errorCallback = (errors) => {
    error({
      message: errors?.error?.message
        ? errors?.error?.message
        : 'Error with Credit Card',
    });
  };

  const onSubmit = async (data) => {
    authorize.sendPayment(data, successCallback, errorCallback);
  };

  return (
    <>
      {/* Desktop Modal */}
      <BrowserView>
        <Modal
          {...props}
          show={show}
          size="xl"
          dialogClassName="credit-card-detail desktop"
          key={'CreditCardDetail_Desktop'}
          centered
        >
          <Modal.Header>
            <Modal.Title>
              <span>Add New Credit Card</span>
              <span className="back" onClick={onHide}>
                <i className="fas fa-times"></i>
              </span>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="show-grid">
            <Container>
              <Row>
                <Col xs={12} md={12}>
                  {PAYMENT_GATEWAY === paymentGateway.STRIPE && (
                    <CreditCardForm onHide={onHide} />
                  )}

                  {PAYMENT_GATEWAY === paymentGateway.AUTHORIZE && (
                    <CreditCardAuthorizeForm
                      onHide={onHide}
                      onSubmit={onSubmit}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </Modal.Body>

          {/* Footer */}
          <Modal.Footer>
            <Button
              form="authorize-credit-card-form"
              type="submit"
              variant="primary"
              size="md"
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </BrowserView>

      {/* Mobile Modal */}
      <MobileView>
        <Modal
          {...props}
          size="lg"
          dialogClassName="credit-card-detail mobile m-0"
          key={'CreditCardDetail_Mobile'}
          centered
        >
          <Modal.Header>
            <Modal.Title>
              <span className="back" onClick={onHide}>
                <i className="fas fa-arrow-left"></i>
              </span>
              <span>Add New Credit Card</span>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="show-grid">
            <Container>
              {PAYMENT_GATEWAY === paymentGateway.STRIPE && (
                <CreditCardForm onHide={onHide} />
              )}

              {PAYMENT_GATEWAY === paymentGateway.AUTHORIZE && (
                <CreditCardAuthorizeForm onHide={onHide} onSubmit={onSubmit} />
              )}
            </Container>
          </Modal.Body>

          {/* Footer */}
          <Modal.Footer>
            <Button
              form="authorize-credit-card-form"
              type="submit"
              variant="primary"
              size="md"
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </MobileView>
    </>
  );
};

CreditCardDetail.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default CreditCardDetail;
