import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Image } from 'react-bootstrap';
import { MobileView, BrowserView } from 'react-device-detect';

const FooterLanding = () => {
  return (
    <>
      {/* Footer Desktop */}
      <footer className="footer-landing">
        <div className="upper-part">
          <div></div>
          <div className="logo">
            <Image className="" src="assets/images/logo-dark.svg" />
          </div>

          <div className="social-media align-self-end">
            {/* Facebook */}
            <Button
              variant="link"
              href="https://www.facebook.com/The-Flight-Kitchen-106511295109359"
            >
              <i className="fab fa-facebook"></i>
            </Button>

            {/* Twitter */}
            {/* <Button variant="link" href="#/cart">
              <i className="fab fa-twitter"></i>
            </Button> */}

            {/* Instagram */}
            {/* <Button variant="link" href="#/cart">
              <i className="fab fa-instagram"></i>
            </Button> */}
          </div>
        </div>

        {/* Mobile view */}
        <MobileView className="links-container">
          <div className="links">
            <ul>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/where-we-operate">Where we operate</Link>
              </li>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </div>
        </MobileView>

        <div className="lower-part desktop d-flex justify-content-between align-items-center">
          <div className="copyright">
            <span>Copyright {new Date().getFullYear()}</span>
          </div>

          {/* Mobile view */}
          <BrowserView>
            <div className="links">
              <ul>
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/where-we-operate">Where we operate</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>
            </div>
          </BrowserView>

          <div className="container-links-right d-flex align-items-center">
            <div className="privacy-policy">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
            <div className="terms-and-conditions">
              <Link to="/terms-and-conditions">Terms and Conditions</Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterLanding;
