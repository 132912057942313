import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

function AdminOrderItemColSkeleton(props) {
  const { width = 160 } = props;

  return (
    <>
      <div className="admin-order-item-col">
        <div className="admin-order-item-container">
          <p className="admin-order-item-name">
            <Skeleton width={width * 0.6} />
          </p>
          <p className="admin-order-item-value">
            <Skeleton width={width} />
          </p>
        </div>
      </div>
    </>
  );
}

AdminOrderItemColSkeleton.propTypes = {
  width: PropTypes.number,
};

export default AdminOrderItemColSkeleton;
