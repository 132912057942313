import React, { useState, useEffect, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { Controller, useForm } from 'react-hook-form';
import { useUser } from '../../hooks';
import PageTitle from '../../components/pageTitle';
import { addImage, editCustomer } from '../../api/customer';
import { useNotification } from '../../hooks';

const PersonalInformationPage = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const { user, setUser } = useUser();
  const { error, success } = useNotification();
  const [fileUploaded, setFileUploaded] = useState();
  const [loading, setLoading] = useState(false);

  const pageTitleProps = {
    title: 'Edit personal info',
    primaryButtonText: 'Save',
    primaryButtonClick: () => handleSubmit(onSubmit)(),
    primaryButtonLoading: loading,
  };

  useEffect(() => {
    setValue('image', fileUploaded);
  }, [fileUploaded]);

  const onSubmit = async (updatedUser) => {
    setLoading(true);

    const result = await editCustomer(updatedUser);

    if (result && result.success) {
      if (fileUploaded) {
        const formData = new FormData();
        formData.append('image', fileUploaded);
        const image = await addImage(formData);
        const { data } = result;
        setUser({
          ...user,
          ...data,
          image: `${image}?${new Date().getTime()}`,
        });
      } else {
        const { data } = result;
        setUser({
          ...user,
          ...data,
        });
      }

      success({ message: `Personal Info updated` });
    } else {
      console.log(`result`, result);
      error({ message: result.message });
    }

    setLoading(false);
  };

  const hiddenFileInput = useRef();

  const handleImageClick = () => hiddenFileInput.current.click();

  const handleImageChange = (e) => setFileUploaded(e.target.files[0]);

  return (
    <>
      {!isMobileOnly && <PageTitle {...pageTitleProps} />}

      <Form
        noValidate
        id="personal-information-form"
        className="personal-information-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* Product Image */}

        {/* Hidden Button */}
        <Controller
          id="image"
          name="image"
          control={control}
          defaultValue={user.image || ''}
          render={() => (
            <div className="select-element">
              <Form.Group
              //controlId="image"
              >
                <Form.File
                  accept="image/*"
                  isInvalid={!!errors.image}
                  className={`d-none`}
                  ref={hiddenFileInput}
                  onChange={handleImageChange}
                />

                <div className="profile-item-image-container">
                  <Link to="#" replace onClick={handleImageClick}>
                    {!fileUploaded && (
                      <Image
                        src="data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
                        className="profile-item-image"
                        style={{
                          backgroundImage: `${
                            user.image
                              ? `url(${user.image})`
                              : `url('assets/images/user-avatar-mobile.svg')`
                          }`,
                        }}
                      />
                    )}

                    {fileUploaded && (
                      <Image
                        src="data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=="
                        className="profile-item-image"
                        style={{
                          backgroundImage: `${`url(${URL.createObjectURL(
                            fileUploaded
                          )})`}`,
                        }}
                      />
                    )}
                    <div className="camera-bkg">
                      <i className="fas fa-camera"></i>
                    </div>
                  </Link>
                </div>
              </Form.Group>
            </div>
          )}
        />

        {/* First Name */}
        <Row xs={1} sm={2}>
          <Col>
            <Controller
              name="firstName"
              control={control}
              defaultValue={user.firstName || ''}
              rules={{ required: true }}
              render={({ field }) => (
                <div className="select-element">
                  <Form.Group
                  //controlId="firstName"
                  >
                    <Form.Label>First Name</Form.Label>
                    <Form.Control {...field} isInvalid={!!errors.firstName} />
                    <Form.Control.Feedback type="invalid">
                      {!!errors.firstName &&
                        errors.firstName.type === 'required' &&
                        'First Name is required'}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              )}
            />
          </Col>
        </Row>

        {/* Last Name */}
        <Row xs={1} sm={2}>
          <Col>
            <Controller
              name="lastName"
              control={control}
              defaultValue={user.lastName || ''}
              rules={{ required: true }}
              render={({ field }) => (
                <div className="select-element">
                  <Form.Group
                  //controlId="lastName"
                  >
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control {...field} isInvalid={!!errors.lastName} />
                    <Form.Control.Feedback type="invalid">
                      {!!errors.lastName &&
                        errors.lastName.type === 'required' &&
                        'Last Name is required'}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              )}
            />
          </Col>
        </Row>

        {/* Birth Date */}
        <Row xs={1} sm={2}>
          <Col>
            <Controller
              name="birthDate"
              control={control}
              defaultValue={user.birthDate || ''}
              rules={{ required: false }}
              render={({ field }) => (
                <div className="select-element">
                  <Form.Group
                  //controlId="birthDate"
                  >
                    <Form.Label>Birth Date</Form.Label>
                    <Form.Control
                      {...field}
                      type="date"
                      isInvalid={!!errors.birthDate}
                    />
                    <Form.Control.Feedback type="invalid">
                      {!!errors.birthDate &&
                        errors.birthDate.type === 'required' &&
                        'Birth Date is required'}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              )}
            />
          </Col>
        </Row>

        {/* Email */}
        <Row xs={1} sm={2}>
          <Col xs={12}>
            <Controller
              name="email"
              control={control}
              defaultValue={user.email || ''}
              rules={{
                required: true,
                pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
              }}
              render={({ field }) => (
                <div className="select-element">
                  <Form.Group
                  //controlId="email"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      {...field}
                      isInvalid={!!errors.email}
                      type="email"
                    />
                    <Form.Control.Feedback type="invalid">
                      {!!errors.email &&
                        errors.email.type === 'required' &&
                        'Email is required'}
                      {!!errors.email &&
                        errors.email.type === 'pattern' &&
                        'Email is invalid'}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              )}
            />
          </Col>
        </Row>

        {/* Phone Number */}
        <Row xs={1} sm={2}>
          <Col>
            <Controller
              name="phone"
              control={control}
              defaultValue={user.phone || ''}
              rules={{
                required: false,
                maxLength: 10,
              }}
              render={({ field }) => (
                <div className="select-element">
                  <Form.Group
                  //controlId="phone"
                  >
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      {...field}
                      type="phone"
                      maxLength="10"
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {!!errors.phone &&
                        errors.phone.type === 'required' &&
                        'Phone is required'}
                      {!!errors.phone &&
                        errors.phone.type === 'maxLength' &&
                        'Phone is invalid'}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              )}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default PersonalInformationPage;
