import React from 'react';
import PropTypes from 'prop-types';
import { Button, Image, Row, Col, Card } from 'react-bootstrap';
import QuantitySelector from '../quantitySelector';
import { useCart } from '../../hooks';
import { MobileView, BrowserView } from 'react-device-detect';

const CartItem = (props) => {
  const { product } = props;
  const {
    image,
    name,
    cartQuantity: quantity,
    cartPrice: price,
    ingredients,
  } = product || {};

  const { editItem, deleteItem } = useCart();

  const selectedIngredients = ingredients
    .filter((e) => e.isIncluded)
    .map((e) => e.code);

  const handleEdit = (qty) => {
    editItem({
      product: { ...product, price },
      selectedQuantity: qty,
      selectedIngredients,
      hideToast: true,
    });
  };

  const handleDelete = () => {
    deleteItem({ product });
  };

  return (
    <>
      {/* Desktop view */}
      <BrowserView>
        <div>
          <Row className="align-items-center cart-item">
            <Col>
              <div className="d-flex align-items-center">
                {/* Product Image */}
                <div className="cart-item-image-container">
                  <Image
                    src={`${
                      image
                        ? 'data:image/png;base64,R0lGODlhFAAUAIAAAP///wAAACH5BAEAAAAALAAAAAAUABQAAAIRhI+py+0Po5y02ouz3rz7rxUAOw=='
                        : 'assets/images/logo-plane.svg'
                    }`}
                    className="cart-item-image"
                    style={{ backgroundImage: `url(${image})` }}
                  />
                </div>
                {/* Product Name */}
                <span className="cart-item-name">
                  <span className="name">{name}</span>
                  <span className="ingredients">
                    {ingredients
                      .filter((e) => e.isIncluded)
                      .map((e) => e.name)
                      .join(', ')}
                  </span>
                </span>
              </div>
            </Col>
            <Col className="col-min-width">
              <QuantitySelector
                value={quantity}
                onChange={handleEdit}
                className="d-inline"
              ></QuantitySelector>
            </Col>
            <Col className="col-min-width">
              <span>${price.toFixed(2)}</span>
            </Col>
            <Col className="col-min-width">
              <span>${(quantity * price).toFixed(2)}</span>
            </Col>
            <Col className="col-min-width">
              <Button
                variant="link"
                className="btn-danger"
                onClick={handleDelete}
              >
                <i className="fas fa-minus-circle danger"></i>
              </Button>
            </Col>
          </Row>
        </div>
      </BrowserView>

      {/* Mobile view */}
      <MobileView>
        <div>
          <Card className="cart-item">
            <div className="d-flex align-content-center">
              <Button
                variant="link"
                className="btn-danger"
                onClick={handleDelete}
              >
                <i className="fas fa-minus-circle danger"></i>
              </Button>

              <div className="flex-grow-1">
                <span className="cart-item-name">
                  <span className="name">{name}</span>
                  <span className="ingredients">
                    {ingredients
                      .filter((e) => e.isIncluded)
                      .map((e) => e.name)
                      .join(', ')}
                  </span>
                </span>
                <span className="d-block mt-1">${price.toFixed(2)}</span>
              </div>

              <QuantitySelector
                value={quantity}
                onChange={handleEdit}
                className="cart-item-quantity align-self-center"
              ></QuantitySelector>
            </div>
          </Card>
        </div>
      </MobileView>
    </>
  );
};

CartItem.propTypes = {
  product: PropTypes.object,
};

export default CartItem;
