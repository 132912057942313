import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { isBrowser, isMobileOnly } from 'react-device-detect';
// import { Link } from 'react-router-dom';
import DefaultAirportPage from '../defaultAirport';
import PaymentMethodsPage from '../paymentMethods';
import PersonalInformationPage from '../personalInformation';
import { capitalize } from '../../helpers/utils';

const ProfilePage = () => {
  const [key, setKey] = useState('personal-information');
  const [show, setShow] = useState();
  const [addCardShow, setAddCardShow] = useState();

  return (
    <>
      <div className="page-container profile-page">
        {/* Desktop View */}
        {isBrowser && <h1>My Profile</h1>}

        <Tab.Container
          id="my-profile"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Row className="profile-container">
            <Col sm={4}>
              <Card className="profile-menu">
                <Nav className="flex-column" onSelect={() => setShow(true)}>
                  {/* Personal Information */}
                  <Nav.Item>
                    <Nav.Link
                      eventKey="personal-information"
                      className="d-flex align-items-center justify-content-between"
                    >
                      <span>Personal Information</span>
                      <i className="fas fa-chevron-right"></i>
                    </Nav.Link>
                  </Nav.Item>

                  {/* Payment Methods */}
                  <Nav.Item>
                    <Nav.Link
                      eventKey="payment-methods"
                      className="d-flex align-items-center justify-content-between"
                    >
                      <span>Payment Methods</span>
                      <i className="fas fa-chevron-right"></i>
                    </Nav.Link>
                  </Nav.Item>

                  {/* Default Airport */}
                  <Nav.Item>
                    <Nav.Link
                      eventKey="default-airport"
                      className="d-flex align-items-center justify-content-between"
                    >
                      <span>Default Airport</span>
                      <i className="fas fa-chevron-right"></i>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card>
            </Col>

            {!isMobileOnly && (
              <Col sm={8}>
                <Card className="profile-content">
                  <Tab.Content>
                    {/* Personal Information */}
                    <Tab.Pane eventKey="personal-information">
                      <PersonalInformationPage />
                    </Tab.Pane>

                    {/* Payment Methods */}
                    <Tab.Pane eventKey="payment-methods">
                      <PaymentMethodsPage
                        addCardShow={addCardShow}
                        addCardOnHide={() => setAddCardShow(false)}
                        addCardOnShow={() => setAddCardShow(true)}
                      />
                    </Tab.Pane>

                    {/* Default Airport */}
                    <Tab.Pane eventKey="default-airport">
                      <DefaultAirportPage />
                    </Tab.Pane>
                  </Tab.Content>
                </Card>
              </Col>
            )}
          </Row>

          {/* Mobile View */}
          {isMobileOnly && (
            <Modal
              show={show}
              onHide={() => setShow(false)}
              dialogClassName="profile-modal"
              size="xl"
              centered
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  <i
                    className="fas fa-arrow-left"
                    onClick={() => setShow(false)}
                  ></i>
                  {key.split('-').map(capitalize).join(' ')}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Tab.Content>
                  {/* Personal Information */}
                  <Tab.Pane eventKey="personal-information">
                    <PersonalInformationPage />
                  </Tab.Pane>

                  {/* Payment Methods */}
                  <Tab.Pane eventKey="payment-methods">
                    <PaymentMethodsPage
                      addCardShow={addCardShow}
                      addCardOnHide={() => setAddCardShow(false)}
                      addCardOnShow={() => setAddCardShow(true)}
                    />
                  </Tab.Pane>

                  {/* Default Airport */}
                  <Tab.Pane eventKey="default-airport">
                    <DefaultAirportPage />
                  </Tab.Pane>
                </Tab.Content>
              </Modal.Body>

              <Modal.Footer>
                {(key === 'personal-information' ||
                  key === 'default-airport') && (
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    form={`${key}-form`}
                    onClick={() => setShow(false)}
                  >
                    Save
                  </Button>
                )}

                {key === 'payment-methods' && (
                  <Button
                    className="btn btn-primary"
                    // type="submit"
                    // form={`${key}-form`}
                    onClick={() => setAddCardShow(true)}
                  >
                    Add Card
                  </Button>
                )}
              </Modal.Footer>
            </Modal>
          )}
        </Tab.Container>
      </div>
    </>
  );
};

export default ProfilePage;
