import React from 'react';
import PropTypes from 'prop-types';
import ReactStars from 'react-rating-stars-component';

/**
 * Component is a wrapper of:
 *  https://github.com/ertanhasani/react-stars#readme
 */

const RatingStars = (props) => {
  const { value, readOnly, onChange } = props;
  const config = {
    classNames: 'rating-stars',
    size: 20,
    count: 5,
    color: '#dddddd',
    activeColor: '#ffc144',
    value: value,
    edit: !readOnly,
    a11y: false,
    isHalf: false,
    emptyIcon: <i className="fa fa-star" />,
    filledIcon: <i className="fa fa-star" />,
    onChange: (newValue) => {
      onChange(newValue);
    },
  };

  return <ReactStars {...config} />;
};

RatingStars.propTypes = {
  value: PropTypes.number,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

RatingStars.defaultProps = {
  value: 0,
  readOnly: true,
  onChange: () => {},
};

export default RatingStars;
