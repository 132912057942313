import React from 'react';

// eslint-disable-next-line react/prop-types
const CustomToast = ({ appearance, children }) => (
  <div className={`custom-toast d-flex align-items-center ${appearance}`}>
    {/* Success & Info & Default */}
    {(!appearance || appearance === 'info' || appearance === 'success') && (
      <i className="fas fa-check-circle"></i>
    )}

    {/* Warning */}
    {appearance === 'warning' && <i className="fas fa-exclamation-circle"></i>}

    {/* Error */}
    {appearance === 'error' && <i className="fas fa-check-circle"></i>}

    <p className="mr-auto">{children}</p>

    <a className="btn-icon">
      <i className="fas fa-times"></i>
    </a>
  </div>
);

export default CustomToast;
