import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';

const PopupSaving = (props) => {
  const [show, setShow] = useState(props.show);
  const [content, setContent] = useState(props.content);

  const handleClose = () => setShow(false);

  useEffect(() => {
    const { show, content } = props;

    setShow(show);
    if (content) setContent(content);
  }, [props]);

  return (
    <>
      <div className="popup-saving">
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          backdropClassName="popup-backdrop"
          dialogClassName="popup-saving"
          centered
        >
          {/* Body */}
          <Modal.Body>
            <Container>
              <p>{ReactHtmlParser(content)}</p>
            </Container>

            <div className="image-container">
              <img src="assets/images/saving.svg" />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

PopupSaving.propTypes = {
  show: PropTypes.bool,
  content: PropTypes.string,
};

PopupSaving.propDefault = {
  show: false,
  content: '',
};

export default PopupSaving;
