import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { exportToTop } from '../../helpers/utils';
import { isBrowser, isMobile, isTablet } from 'react-device-detect';
import EmptyPage from '../../components/emptyPage';
import DefaultAirportImage from '../../assets/images/my-flights.svg';
import { HeaderContainer } from '../../components/headerContainer';
import { FooterContainer } from '../../components/footerContainer';
import { useUser } from '../../hooks';

const AboutUsPage = () => {
  const { user } = useUser();

  useEffect(() => {
    exportToTop();
  }, []);

  return (
    <>
      <HeaderContainer />
      <div
        className={
          user ? `main container container-fluid py-1` : `main container`
        }
      >
        <div className="page-container about-us my-5 p-2">
          {(isBrowser || !user) && (
            <h1 className="page-title mb-4">About Us</h1>
          )}

          <Row>
            {/* Left Panel */}
            <Col xs={12} md={6}>
              <div className="texting">
                <div>
                  <p>
                    <b>The Flight Kitchen</b> is a catering company that
                    specializes in providing on demand food and beverage to the
                    the aviation community. <br />
                    Whether you are a pilot on a quick-turn corporate flight or
                    a passenger heading on vacation, The Flight Kitchen offers
                    an easy, reliable source for your meals or snacks on the
                    way.
                  </p>

                  {isMobile && !isTablet && (
                    <EmptyPage image={DefaultAirportImage} />
                  )}

                  <p>
                    Founded in 2018, we currently operate in the Charlotte, NC,
                    market area with plans to expand to multiple locations in
                    the near future.
                  </p>
                </div>
              </div>
            </Col>

            {/* Right Panel */}
            {(isBrowser || isTablet) && (
              <Col xs={12} md={6}>
                <EmptyPage image={DefaultAirportImage} />
              </Col>
            )}
          </Row>
        </div>
      </div>
      <FooterContainer />
    </>
  );
};

export default AboutUsPage;
