import React, { useState, useEffect } from 'react';
import { MobileView, BrowserView } from 'react-device-detect';
import { Link } from 'react-router-dom';
import CardDeck from 'react-bootstrap/CardDeck';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useFilter } from '../../hooks';
import AdminProductItem from '../../components/adminProductItem';
import AdminCategoryModal from '../../components/adminCategoryModal';
import AdminProductModal from '../../components/adminProductModal';
import CategoryFilter from '../../components/categoryFilter';
import CategorySkeleton from '../../components/categorySkeleton';
import EmptyPage from '../../components/emptyPage';
import ProductCardSkeleton from '../../components/productCardSkeleton';
import { useNotification } from '../../hooks';
import { create, update, getAll, setImage, remove } from '../../api/product';

const AdminProduct = () => {
  const [loading, setLoading] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [productDetail, setProductDetail] = useState();
  const { error, success } = useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const emptyProps = {
    title: 'No Products.',
    subtitle: "You haven't added products so far.",
  };

  const {
    categories,
    categorySelected,
    onCategorySelected,
    setCategorySelected,
    fetchCategories,
  } = useFilter();

  const handleShowForm = (params) => {
    setProductDetail({ ...params });
    setShowProductModal(true);
  };

  const handleCreate = async (product) => {
    const {
      name,
      description,
      price,
      grams,
      calories,
      category,
      ingredients,
      image,
    } = product;

    const result = await create({
      name,
      description,
      price,
      grams,
      calories,
      category,
      ingredients: ingredients && ingredients.map((e) => e.code),
    });

    if (result && result.success) {
      const { hash } = result.data;

      if (image) {
        const formData = new FormData();
        formData.append('image', image);
        await setImage(hash, formData);
      }

      success({ message: `Product ${name} created` });
      setShowProductModal(false);
      await fetchProducts();
    } else {
      showErrorNotification(result);
    }
  };

  const handleEdit = async (product) => {
    setIsLoading(true);

    const {
      hash,
      name,
      description,
      price,
      grams,
      calories,
      category,
      ingredients,
      image,
    } = product;
    // const result = await update({ ...product }, hash);

    const result = await update(
      {
        name,
        description,
        price,
        grams,
        calories,
        category,
        ingredients: ingredients && ingredients.map((e) => e.code),
      },
      hash
    );

    if (result && result.success) {
      if (image) {
        const formData = new FormData();
        formData.append('image', image);
        await setImage(hash, formData);
      }
      setIsLoading(false);

      await fetchProducts();
      setShowProductModal(false);
    } else {
      showErrorNotification(result);
      setIsLoading(false);
    }
  };

  const handleRemove = async (hash, name) => {
    const result = await remove(hash);
    if (result && result.success) {
      success({ message: `Product ${name} deleted` });
      await fetchProducts();
    } else {
      showErrorNotification(result);
    }
  };

  const showErrorNotification = (result) => {
    const {
      error: { message },
    } = result;

    error({ message });
  };

  const actions = {
    create: handleCreate,
    edit: handleEdit,
    remove: handleRemove,
    showForm: handleShowForm,
  };

  const fetchProducts = async () => {
    setLoading(true);
    const result = await getAll();
    setProducts(result);
    setTimeout(() => setLoading(false), 1000);
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await fetchCategories();
      await fetchProducts();
    };

    getData();
  }, []);

  useEffect(() => {
    if (categories.length > 0 && categorySelected === 'all') {
      setCategorySelected(categories[0].code);
    }
  }, [categories]);

  const handleCategorySelect = (val) => {
    setLoading(true);
    onCategorySelected(val);
    setTimeout(() => setLoading(false), 1000);
  };

  return (
    <>
      {/* Desktop View */}
      <BrowserView>
        <div className="page-container admin-product">
          {/* Title and Buttons */}
          <Row>
            <Col>
              <h1>Menu</h1>
            </Col>
          </Row>

          <div className="admin-product-list">
            {/* Filters */}
            <div className="d-flex align-items-center justify-content-between filter-bar">
              {loading && !categories.length ? (
                <CategorySkeleton />
              ) : (
                <CategoryFilter
                  list={categories}
                  selected={categorySelected}
                  onSelected={handleCategorySelect}
                  showAll={false}
                />
              )}

              <div className="buttons-container">
                <Link
                  className="btn btn-md btn-outline-primary"
                  to="#"
                  onClick={() => setShowCategoryModal(true)}
                  replace
                >
                  Edit Categories
                </Link>

                <Link
                  className="btn btn-md btn-primary"
                  to="#"
                  onClick={() => handleShowForm({})}
                  replace
                >
                  Add new item
                </Link>
              </div>
            </div>

            {/* Card Deck */}
            <CardDeck>
              {/* Skeleton */}
              {loading &&
                [0, 1, 2, 3, 4, 5].map((e, k) => (
                  <ProductCardSkeleton
                    key={`AdminProduct_ProductCardSkeleton_${k}`}
                  />
                ))}

              {/* Products */}
              {!loading &&
                products &&
                products.length > 0 &&
                products.map(
                  (e) =>
                    (e.category.code === categorySelected ||
                      (categorySelected === 'catering' &&
                        e.category.parentId)) && (
                      <AdminProductItem
                        key={`AdminProduct_Product_${e.hash}`}
                        actions={actions}
                        {...e}
                      />
                    )
                )}
            </CardDeck>

            {/* Emtpy */}
            {!loading &&
              products &&
              products
                .map(
                  (e) =>
                    e.category.code === categorySelected ||
                    (categorySelected === 'catering' && e.category.parentId)
                )
                .every((e) => !e) && <EmptyPage {...emptyProps} />}
          </div>

          {/* Add Category Form */}
          {categories && showCategoryModal && (
            <AdminCategoryModal
              categories={categories}
              show={showCategoryModal}
              hideModal={() => setShowCategoryModal(false)}
            />
          )}

          {/* Add Product Form */}
          {productDetail && showProductModal && (
            <AdminProductModal
              {...productDetail}
              show={showProductModal}
              categories={categories}
              actions={actions}
              hideModal={() => setShowProductModal(false)}
              isLoading={isLoading}
            />
          )}
        </div>
      </BrowserView>

      {/* Mobile View */}
      <MobileView>
        <EmptyPage
          title="Oops! Admin pages are not allowed from mobile"
          subtitle="Please try to use this page from your desktop computer."
        />
      </MobileView>
    </>
  );
};

export default AdminProduct;
