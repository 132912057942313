import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
const FindFilter = ({ text, placeholder, onSelected }) => {
  const { setValue, control, handleSubmit } = useForm();

  const onSubmit = (value) => {
    const { text } = value;
    onSelected(text);
  };

  const onClearClick = () => {
    setValue('text', '');
    onSubmit('');
  };

  useEffect(() => {
    setValue('text', text);
  }, [text]);

  return (
    <div className="search-filter">
      <div className="search-filter-container">
        <Form noValidate id="find-filter" onSubmit={handleSubmit(onSubmit)}>
          {text && (
            <Link to="#" replace className="search-clear">
              <i className="fas fa-times" onClick={onClearClick}></i>
            </Link>
          )}

          {!text && (
            <Link to="#" replace className="search-icon">
              <i className="fas fa-search" onClick={handleSubmit(onSubmit)}></i>
            </Link>
          )}

          <Controller
            name="text"
            control={control}
            render={({ field }) => (
              <Form.Control
                className="form-control-search"
                placeholder={placeholder}
                autoComplete="off"
                {...field}
              />
            )}
          />
        </Form>
      </div>
    </div>
  );
};

export default FindFilter;
