import React, { useState, useEffect } from 'react';
import { MobileView, BrowserView } from 'react-device-detect';
import AdminUserItem from '../../components/adminUserItem';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdminTableRowSkeleton from '../../components/adminTableRowSkeleton';
import AdminUserModal from '../../components/adminUserModal';
import EmptyPage from '../../components/emptyPage';
import { useNotification } from '../../hooks';
import {
  create,
  // update,
  getAll as getAllUsers,
  remove,
} from '../../api/user';
import { getAll as getAllRoles } from '../../api/role';
import { roleKey } from '../../constants/role';

const AdminUser = () => {
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState();
  const [showModal, setShowModal] = useState();
  const [roles, setRoles] = useState([]);
  const { error, success } = useNotification();

  const emptyProps = {
    title: 'Users.',
    subtitle: "You haven't added users so far.",
  };

  const showErrorNotification = (result) => {
    const {
      error: { message },
    } = result;

    error({ message });
  };

  const handleCreate = async (user) => {
    const { firstName, lastName, email, role } = user;
    const result = await create({ firstName, lastName, email, roleId: role });
    if (result && result.success) {
      success({ message: `User ${email} created` });
      setShowModal(false);
      await fetchUsers();
    } else {
      showErrorNotification(result);
    }
  };

  const handleEdit = async () => {
    //
  };

  const handleRemove = async (hash, email) => {
    const result = await remove(hash);
    if (result && result.success) {
      success({ message: `User ${email} deleted` });
      await fetchUsers();
    } else {
      showErrorNotification(result);
    }
  };

  const handleShowForm = async (params) => {
    setUserDetail({ ...params, roles });
    setShowModal(true);
  };

  const actions = {
    create: handleCreate,
    edit: handleEdit,
    remove: handleRemove,
    showForm: handleShowForm,
  };

  const fetchUsers = async () => {
    setLoading(true);
    const result = await getAllUsers();
    setUsers(result);
    setTimeout(() => setLoading(false), 500);
  };

  const fetchRoles = async () => {
    let result = await getAllRoles();
    result = result.filter(
      (e) =>
        e.code.toUpperCase() === roleKey.KITCHEN ||
        e.code.toUpperCase() === roleKey.DELIVERY
    );
    setRoles(result);
  };

  useEffect(() => {
    Promise.all([fetchRoles(), fetchUsers()]);
  }, []);

  return (
    <>
      <BrowserView>
        <div className="page-container admin-user">
          {/* Title and Buttons */}
          <Row>
            <Col>
              <h1>Users</h1>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col sm={{ span: 3, offset: 9 }} className="text-right">
              <Link
                className="btn btn-md btn-primary"
                to="#"
                replace
                onClick={() => handleShowForm({})}
              >
                Add new user
              </Link>
            </Col>
          </Row>

          {/* Add Form */}
          {userDetail && (
            <AdminUserModal
              {...userDetail}
              show={showModal}
              actions={actions}
              hideModal={() => setShowModal(false)}
            />
          )}

          {/* List */}
          <div className="admin-user-list">
            {/* Loading */}
            {loading && (
              <>
                <AdminUserListHeader />
                <AdminTableRowSkeleton cols={3} width={`80%`} />
              </>
            )}
            {/* Items */}
            {!loading && users && users.length > 0 && <AdminUserListHeader />}

            {!loading &&
              users &&
              users.length > 0 &&
              users.map((e) => (
                <AdminUserItem
                  key={`AdminUser_User_${e.hash}`}
                  actions={actions}
                  {...e}
                  onClick={() => {
                    setUserDetail({ ...e });
                  }}
                />
              ))}

            {/* Empty */}
            {!loading && users && !users.length && (
              <EmptyPage {...emptyProps} />
            )}
          </div>
        </div>
      </BrowserView>
      {/* Mobile View */}
      <MobileView>
        <EmptyPage
          // image={}
          title="Oops! Admin pages are not allowed from mobile"
          subtitle="Please try to use this page from your desktop computer."
        />
      </MobileView>
    </>
  );
};

const AdminUserListHeader = () => (
  <div className="d-flex list-title">
    <span>Name</span>
    <span>Email</span>
    <span>Role</span>
    <span className="actions">Edit</span>
    <span className="actions">Delete</span>
  </div>
);

export default AdminUser;
