import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MobileView, BrowserView } from 'react-device-detect';

import { getOrders, getStatus } from '../../api/order';
import { statusKey } from '../../constants/status';

import CategoryFilter from '../../components/categoryFilter';
import AdminOrderItem from '../../components/adminOrderItem';
import EmptyPage from '../../components/emptyPage';
import EmptyOrderPage from '../../assets/images/my-orders.svg';
import CategorySkeleton from '../../components/categorySkeleton';
import AdminOrderItemSkeleton from '../../components/adminOrderItemSkeleton';
import FindFilter from '../../components/findFilter';

const AdminOrderPage = () => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState([]);
  const [statusSelected, setStatusSelected] = useState(statusKey.SCHEDULED);
  const [filterText, setFilterText] = useState('');

  const fetchOrders = async (statusId, filter) => {
    const orders = await getOrders(statusId, filter, 'ASC');
    setOrders(orders);
  };

  const fetchStatus = async () => {
    const status = await getStatus();
    setStatus(
      status?.sort((a, b) =>
        a.order > b.order ? 1 : b.order > a.order ? -1 : 0
      )
    );
  };

  const onStatusSelected = async (selectedStatus) => {
    setLoading(true);
    setStatusSelected(selectedStatus);
    setFilterText('');
    await fetchOrders(selectedStatus);
    setTimeout(() => setLoading(false), 400);
  };

  const onFilterSelected = async (text) => {
    setLoading(true);
    setFilterText(text);
    await fetchOrders(statusSelected, text);
    setTimeout(() => setLoading(false), 400);
  };

  const emptyPageProps = {
    image: EmptyOrderPage,
    title: 'No orders found',
    subtitle: 'Click the button below to check again!',
    primaryButtonText: 'Refresh',
    primaryButtonOnClick: fetchOrders,
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchStatus();
      await fetchOrders(statusSelected);
      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Desktop View */}
      <BrowserView>
        <div className="page-container admin-order">
          {/* Order List */}
          <Row className="row">
            <Col>
              <h1>Orders</h1>
            </Col>
          </Row>

          {/* Status Filter */}
          <div className="d-flex align-items-center justify-content-between filter-bar">
            {loading && !status.length ? (
              <CategorySkeleton />
            ) : (
              <CategoryFilter
                list={status}
                selected={statusSelected}
                onSelected={onStatusSelected}
              />
            )}

            <FindFilter
              text={filterText}
              placeholder="Filter Orders"
              onSelected={onFilterSelected}
            />
          </div>

          {/* Order List */}
          <div className="admin-order-list">
            {!loading ? (
              /* Show List */
              orders && orders.length ? (
                orders.map((e, k) => <AdminOrderItem key={k} {...e} />)
              ) : (
                /* Show Empty Page */
                <EmptyPage {...emptyPageProps} />
              )
            ) : (
              <>
                <AdminOrderItemSkeleton />
                <AdminOrderItemSkeleton />
                <AdminOrderItemSkeleton />
                <AdminOrderItemSkeleton />
              </>
            )}
          </div>
        </div>
      </BrowserView>

      {/* Mobile View */}
      <MobileView>
        <EmptyPage
          // image={}
          title="Oops! Admin pages are not allowed from mobile"
          subtitle="Please try to use this page from your desktop computer."
        />
      </MobileView>
    </>
  );
};

export default AdminOrderPage;
