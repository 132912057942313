import React, { useEffect } from 'react';
import { exportToTop } from '../../helpers/utils';
import ProductDeck from '../../components/productDeck';

const FavoritePage = () => {
  useEffect(() => {
    exportToTop();
  }, []);

  return (
    <>
      <div className="page-container favorite-page">
        <h1 className="page-title">My Favorites</h1>
      </div>
      <ProductDeck onlyFavorites={true} />
    </>
  );
};

export default FavoritePage;
