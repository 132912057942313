import React from 'react';
import { Button, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../hooks';

const AlreadyMove = () => {
  const history = useHistory();
  const { isUserLoggedIn } = useUser();

  const onGetStartedClick = () =>
    history.push(isUserLoggedIn ? `menu` : `/public-menu`);

  return (
    <>
      <div className="already-move">
        <div className="already-move-container">
          <div className="already-move-description">
            <h2>Already on the move?</h2>
            <p>
              Check out our mobile site and place your order 12 hours or more in
              advance of your travel.
            </p>
            <Button
              className={`d-block`}
              onClick={onGetStartedClick}
              variant={'default'}
            >
              Get Started
            </Button>
          </div>
          <div className="mobile-image-container">
            <Image src="assets/images/mobile.png" />
          </div>
        </div>
      </div>
    </>
  );
};

export default AlreadyMove;
