import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import QuantitySelector from '../../components/quantitySelector';
import Rating from '../../components/rating';
import IngredientSelector from '../../components/ingredientSelector';
import { useCart, useUser } from '../../hooks';
import { isBrowser, isMobile } from 'react-device-detect';

const ProductCardDetail = (props) => {
  const { quantity, product, onHide } = props;
  const {
    calories,
    // grams,
    description,
    image,
    name,
    price,
    rating,
    ingredients,
  } = product || {};

  const { addItem } = useCart();
  const { isUserLoggedIn } = useUser();
  const history = useHistory();

  const [selectedQuantity, setSelectedQuantity] = useState(quantity);
  const [selectedIngredients, setSelectedIngredients] = useState([]);

  const handleAddToCart = () => {
    const addItemParams = {
      product,
      selectedQuantity,
      selectedIngredients,
    };

    if (isUserLoggedIn) {
      addItem(addItemParams);
    } else {
      history.push({
        pathname: '/signin',
        state: addItemParams,
      });
    }

    if (isBrowser || isMobile) {
      onHide();
    }
  };

  const handleQuantityChange = (quantity) => {
    setSelectedQuantity(quantity);
  };

  const handleIngredientChange = (ingredients) => {
    setSelectedIngredients(ingredients);
  };

  return (
    <>
      {/* Desktop Modal */}
      <BrowserView>
        <Modal
          {...props}
          size="xl"
          dialogClassName="product-card-detail desktop"
          key={name}
          centered
        >
          <Modal.Header style={{ backgroundImage: `url(${image})` }}>
            <Modal.Title>
              <span className="back" onClick={onHide}>
                <i className="fas fa-times"></i>
              </span>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="show-grid">
            <Container>
              <Row>
                {/* Product Name */}
                <Col xs={12} md={12}>
                  <h3>
                    <b>{name}</b>
                  </h3>
                </Col>

                {/* Product Description */}
                <Col xs={12} md={12}>
                  <h5>
                    <i>{description}</i>
                  </h5>
                </Col>

                {/* Choose Ingredients */}
                {ingredients.length === 1 &&
                ingredients[0].code === 'generic' ? (
                  ''
                ) : (
                  <Col xs={12} md={12} className="mt-3">
                    <h5>Choose one</h5>
                  </Col>
                )}
              </Row>

              {/* Ingredient List */}
              {ingredients.length === 1 && ingredients[0].code === 'generic' ? (
                ''
              ) : (
                <Row className="mt-3 mb-5 pb-4 mx-0">
                  {/* <Col xs={12} md={12} className="p-0"> */}
                  <IngredientSelector
                    value={ingredients}
                    selected={selectedIngredients}
                    onChange={handleIngredientChange}
                  />
                  {/* </Col> */}
                </Row>
              )}

              <Row className="mt-5">
                {/* Rating */}
                <Col xs={6} md={3} className="pt-3">
                  {false && <Rating value={rating} readOnly={true} />}
                </Col>

                {/* Product Info */}
                <Col xs={6} md={3} className="pt-3">
                  <h5 className="font-weight-bold">
                    {/* <span>{grams} gr</span> */}
                    {/* <span> | </span> */}
                    <span>{calories} cal</span>
                  </h5>
                </Col>

                {/* Quantity */}
                <Col xs={6} md={3} className="text-center">
                  <h5 className="font-weight-bold">Quantity</h5>
                  <QuantitySelector
                    value={quantity}
                    onChange={handleQuantityChange}
                  />
                </Col>

                {/* Total */}
                <Col xs={6} md={3} className="text-right">
                  <h5 className="font-weight-bold">Total</h5>
                  <h5>${(price * selectedQuantity).toFixed(2)}</h5>
                </Col>
              </Row>

              {/* Estimated calories per serving */}
              <Row>
                <Col>
                  <small className="text-muted">
                    * Estimated calories per serving
                  </small>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn m-0" onClick={handleAddToCart}>
              Add to cart
            </Button>
          </Modal.Footer>
        </Modal>
      </BrowserView>

      {/* Mobile Modal */}
      <MobileView>
        <Modal
          {...props}
          size="lg"
          dialogClassName="product-card-detail mobile m-0"
          scrollable={true}
          centered
        >
          <Modal.Header style={{ backgroundImage: `url(${image})` }}>
            <Modal.Title>
              <span className="back" onClick={onHide}>
                <i className="fas fa-arrow-left"></i>
              </span>
              <span>{name}</span>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="show-grid">
            <Container>
              <Row>
                {/* Desription */}
                <Col xs={12} md={12}>
                  <p className="m-0">
                    <h5>
                      <i>{description}</i>
                    </h5>
                  </p>
                </Col>
              </Row>

              <Row>
                {/* Choose Ingredients */}
                {ingredients.length === 1 &&
                ingredients[0].code === 'generic' ? (
                  ''
                ) : (
                  <Col xs={12} md={12} className="mt-4">
                    <p className="m-0">Choose one</p>
                  </Col>
                )}
              </Row>

              {/* Ingredient List */}
              {ingredients.length === 1 && ingredients[0].code === 'generic' ? (
                ''
              ) : (
                <Row>
                  <Col xs={12} md={12}>
                    <IngredientSelector
                      value={ingredients}
                      onChange={handleIngredientChange}
                    />
                  </Col>
                </Row>
              )}
            </Container>

            <Container className="p-2">
              <Row className="no-gutters mt-4">
                {/* Rating */}
                <Col xs={6} md={3}>
                  {false && <Rating value={rating} readOnly={true} />}
                </Col>

                {/* Product Info */}
                <Col xs={6} md={3} className="pt-1">
                  <p className="font-weight-bold text-right mb-0">
                    {/* <span>{grams} gr</span> */}
                    {/* <span> | </span> */}
                    <span>{calories} cal</span>
                  </p>
                </Col>

                {/* Estimated calories per serving */}
                <Col className="text-right">
                  <small className="text-muted">
                    * Estimated calories per serving
                  </small>
                </Col>
              </Row>

              <Row className="mt-5">
                {/* Quantity */}
                <Col xs={6} md={3} className="text-left">
                  <h5 className="font-weight-bold">Quantity</h5>
                  <QuantitySelector
                    value={quantity}
                    onChange={handleQuantityChange}
                  />
                </Col>

                {/* Total */}
                <Col xs={6} md={3} className="text-right">
                  <h5 className="font-weight-bold">Total</h5>
                  <h5>${(price * selectedQuantity).toFixed(2)}</h5>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn m-0" onClick={handleAddToCart}>
              Add to cart
            </Button>
          </Modal.Footer>
        </Modal>
      </MobileView>
    </>
  );
};

ProductCardDetail.propTypes = {
  product: PropTypes.object,
  quantity: PropTypes.number,
  onHide: PropTypes.func,
};

export default ProductCardDetail;
