/* eslint-disable react/prop-types */
import React from 'react';
import Row from 'react-bootstrap/Row';
import CateringCategory from './CateringCategory';
import CateringProductList from './CateringProductList';

const CateringDeckContainer = ({ subCategories = [], products }) => {
  return (
    <Row className="row-cols-1">
      {subCategories?.map((subCategory) => {
        return (
          <React.Fragment key={`CateringCategory_${subCategory.code}`}>
            <CateringCategory
              name={subCategory.name}
              description={subCategory.description}
            />

            <CateringProductList
              subCategory={subCategory}
              products={products}
            />
          </React.Fragment>
        );
      })}
    </Row>
  );
};

export default CateringDeckContainer;
