import { useContext } from 'react';
import { FilterContext } from '../contexts/filter';

const useFilter = () => {
  const context = useContext(FilterContext);
  if (!context)
    throw new Error('useFilter hook must be used under the user context');

  return context;
};

export default useFilter;
