import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { useUser } from '../../hooks';
import { onboardingDone } from '../../api/user';
import { roleKey } from '../../constants/role';

const OnboardingModal = () => {
  const {
    setOnboarding,
    user: { hash, isOnboardingDone },
    role,
  } = useUser();

  const isCustomer = role.code.toUpperCase() === roleKey.CUSTOMER;

  const [show, setShow] = useState(!isOnboardingDone && isCustomer);
  const [index, setIndex] = useState(0);

  const hideModal = async () => {
    await onboardingDone(hash);
    setOnboarding();
    setShow(false);
  };

  const handleSelect = (selectedIndex) => setIndex(selectedIndex);

  const handleNext = () => setIndex(index + 1);

  return (
    <>
      <Modal dialogClassName="onboarding-modal" centered show={show}>
        <Modal.Header>
          <Modal.Title>
            <span className="back" onClick={hideModal}>
              <i className="fas fa-times"></i>
            </span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="modal-logo d-block d-sm-none">
            <Image src="assets/images/logo.svg" />
          </div>

          <div className="onboarding-carousel">
            <Carousel
              controls={false}
              wrap={false}
              touch={true}
              interval={null}
              activeIndex={index}
              onSelect={handleSelect}
            >
              {/* Onboarding Step 1 */}
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="assets/images/onboarding-step1.svg"
                  alt=""
                />
                <Carousel.Caption>
                  <h3>
                    Explore our menu and select a meal from our delicious and
                    fresh options.
                  </h3>
                </Carousel.Caption>
              </Carousel.Item>

              {/* Onboarding Step 2 */}
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="assets/images/onboarding-step2.svg"
                  alt=""
                />
                <Carousel.Caption>
                  <h3>
                    Load your cart, choose your airport and select a payment
                    method.
                  </h3>
                </Carousel.Caption>
              </Carousel.Item>

              {/* Onboarding Step 3 */}
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="assets/images/onboarding-step3.svg"
                  alt=""
                />
                <Carousel.Caption>
                  <h3>
                    Load your cart, choose your airport and select a payment
                    method.
                  </h3>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>

          {/* Skip and Next Button */}
          <div
            className={`onboarding-button-container justify-content-between align-items-center ${
              index === 2 ? 'd-none' : 'd-flex'
            }`}
          >
            <Link
              className="btn btn-link btn-icon primary-color"
              to="#"
              replace
              onClick={hideModal}
            >
              SKIP
            </Link>

            <Button variant="primary" size="md" onClick={handleNext}>
              Next
            </Button>
          </div>

          {/* Get Started Button */}
          <div
            className={`onboarding-button-container justify-content-between align-items-center ${
              index === 2 ? 'text-center' : 'd-none'
            }`}
          >
            <Button variant="primary" size="md" onClick={hideModal}>
              Get Started
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

OnboardingModal.propTypes = {
  show: PropTypes.bool,
  hideModal: PropTypes.func,
};

export default OnboardingModal;
