import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const AdminUserItem = (params) => {
  const { hash, firstName, lastName, email, role } = params;
  const { actions: { remove, showForm } = {} } = params;

  const handleEdit = () => {
    showForm({ hash, firstName, lastName, email, role });
  };

  const handleDelete = () => {
    remove(hash, email);
  };

  return (
    <>
      <div className="d-flex admin-user-items">
        {/* Full Name */}
        <span className="text-capitalize">{`${firstName.toLowerCase()} ${lastName.toLowerCase()}`}</span>

        {/* Email */}
        <span>{email}</span>

        {/* Role */}
        <span>{role.name}</span>

        {/* Actions - Edit */}
        <span className="actions">
          <Link to="#" replace className="search-clear" onClick={handleEdit}>
            <i className="fas fa-edit"></i>
          </Link>
        </span>

        {/* Actions - Delete */}
        <span className="actions">
          <Link to="#" replace className="search-clear" onClick={handleDelete}>
            <i className="fas fa-trash-alt"></i>
          </Link>
        </span>
      </div>
    </>
  );
};

AdminUserItem.propTypes = {
  hash: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  role: PropTypes.object,
};

export default AdminUserItem;
